import { useRef, useState, useEffect, useContext } from 'react'
import Headers from './Headers'
import { useNavigate, redirect } from 'react-router-dom'
import Axios from '../api/axios'
import { NumericFormat  } from 'react-number-format';
//import { MDBContainer } from 'mdbreact'
import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter
} from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import Select from 'react-select'
import { DailyTotalProfits } from './Views/Graphs/DailyTotalProfits.js'
import { DailySalesTrends } from './Views/Graphs/DailySalesTrends.js'
import { ConstructionSharp } from '@mui/icons-material'

const Home = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  /*********The Counter To Refresh The Data Info Views Starts Here*****************/
  let [count, setCount] = useState(0)
  let counts = 0
  let countz = 0
  let delay = 360000
  setInterval(() => {
    setCount(count + 1)
  }, delay)
  /*********The Counter To Refresh The Data Info Views Ends Here**********************/

  let [totalSalesReport, setTotalSalesReport] = useState('')
  let [daySalesReport, setDaySalesReport] = useState('')
  let [totalInventoryReport, setTotalInventoryReport] = useState('')
  let [totalInventoryReportTotal, setTotalInventoryReportFirst] = useState('')
  let [totalProfitReport, setTotalProfitReport] = useState('')
  let [totalProfitReportDay, setTotalProfitReportDay] = useState('')

  let [costTotalBusinessValue, setCostTotalBusinessValue] = useState('')
  let [sellingPriceTotalBusinessValue, setSellingPriceTotalBusinessValue] =
    useState('')

  const [toDate, setToDate] = useState('')
  const [fromDate, setFromDate] = useState('')
  var [businessNameSuperAdmin, setBusinessNameSuperAdmin] = useState('')
  var [businessBranchNameSuperAdmin, setBusinessBranchNameSuperAdmin] =
    useState('')
  var [
    businessBranchUserRolesSuperAdmin,
    setBusinessBranchUserRolesSuperAdmin
  ] = useState('')
  var [
    businessBranchUserNamesSuperAdmin,
    setBusinessBranchUserNamesSuperAdmin
  ] = useState('')

  const [businessNameSuperAdminDropDown, setBusinessNameSuperAdminDropDown] =
    useState([])
  const [
    businessBranchNameSuperAdminDropDown,
    setBusinessBranchNameSuperAdminDropDown
  ] = useState([])
  const [
    businessBranchUserRolesSuperAdminDropDown,
    setBusinessBranchUserRolesSuperAdminDropDown
  ] = useState([])
  const [
    businessBranchUserNamesSuperAdminDropDown,
    setBusinessBranchUserNamesSuperAdminDropDown
  ] = useState([])

  /*if (businessBranchNameSuperAdmin === 'Business owner') {
    setBusinessBranchUserNamesSuperAdminDropDown([])
    //setBusinessBranchUserRolesSuperAdminDropDown('')
    //businessBranchNameSuperAdminDropDown = ''
    //businessBranchUserNamesSuperAdminDropDown = ''
  }*/

  const [businessBranchName, setBusinessBranchName] = useState('')
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  useEffect(() => {
    setToDate('')
      },[fromDate])

  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const monthToday = `${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const firstUserSystemSetupID = localStorage.getItem('systemSetupID')
  let secondUserSystemSetupID = JSON.parse(firstUserSystemSetupID)

  const firstUserRole = localStorage.getItem('userRole')
  let secondUserRole = JSON.parse(firstUserRole)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)
  //alert(secondUserPosition)
  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  useEffect(() => {
    var formDataBusinessNamesSuperAdmin = new FormData(this)
    formDataBusinessNamesSuperAdmin.append('apiType', 'getBusinessNameDropDown')
    //formDataBusinessNamesSuperAdmin.append('businessNameID', secondUserSystemSetupID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/superAdminTraversing.php`,
      data: formDataBusinessNamesSuperAdmin,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //alert(response.data)
      setBusinessNameSuperAdminDropDown(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [])

  useEffect(() => {
    var formDataBusinessBranchNamesSuperAdmin = new FormData(this)
    formDataBusinessBranchNamesSuperAdmin.append(
      'apiType',
      'getBusinessBranchNameDropDown'
    )
    formDataBusinessBranchNamesSuperAdmin.append(
      'businessNameID',
      businessNameSuperAdmin
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/superAdminTraversing.php`,
      data: formDataBusinessBranchNamesSuperAdmin,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //alert(response.data)
      setBusinessBranchNameSuperAdminDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [businessNameSuperAdmin])

  useEffect(() => {
    var formDataBusinessBranchUserRolesSuperAdmin = new FormData(this)
    //if (businessBranchNameSuperAdmin != 'Business Owner') {
    formDataBusinessBranchUserRolesSuperAdmin.append(
      'apiType',
      'getBusinessBranchUserRolesDropDown'
    )
    /* } else if (businessBranchNameSuperAdmin === 'Business Owner') {
      formDataBusinessBranchUserRolesSuperAdmin.append(
        'apiType',
        'getBusinessLogin'
      )
    }*/
    formDataBusinessBranchUserRolesSuperAdmin.append(
      'businessNameID',
      businessNameSuperAdmin
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/superAdminTraversing.php`,
      data: formDataBusinessBranchUserRolesSuperAdmin,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      //alert(res.data)
      //if (businessBranchNameSuperAdmin != 'Business Owner') {
      setBusinessBranchUserRolesSuperAdminDropDown(res.data)
      /*}
      if (businessBranchNameSuperAdmin === 'Business Owner') {
        //setBusinessBranchUserNamesSuperAdminDropDown([])
        //alert(businessBranchUserNamesSuperAdminDropDown)
        if (res.data != 'Super Administrator') {
          alert(res.data.userID)
          localStorage.setItem('userID', JSON.stringify(res.data.userID))
          localStorage.setItem(
            'systemSetupID',
            JSON.stringify(res.data.systemSetupID)
          )
          localStorage.setItem(
            'systemSetupBranchID',
            JSON.stringify(res.data.systemSetupBranchID)
          )
          localStorage.setItem(
            'userPositionProvisional',
            JSON.stringify('Provisional Super Administrator')
          )
          localStorage.setItem(
            'userPosition',
            JSON.stringify(res.data.userPosition)
          )
          localStorage.setItem('userRole', JSON.stringify(res.data.userRole))
          localStorage.setItem('login', JSON.stringify('User'))
          localStorage.setItem('transactionID', JSON.stringify('0'))
          localStorage.setItem('POSTransactionID', JSON.stringify(''))
          localStorage.setItem('transactionIDPrintReceipt', JSON.stringify('0'))
          localStorage.setItem(
            'userOrganisation',
            JSON.stringify(res.data.userOrganisation)
          )
          localStorage.setItem('userName', JSON.stringify(res.data.userName))

          localStorage.setItem(
            'organisation_address',
            JSON.stringify(res.data.organisation_address)
          )
          localStorage.setItem(
            'organisation_phone',
            JSON.stringify(res.data.organisation_phone)
          )
          localStorage.setItem(
            'organisation_email',
            JSON.stringify(res.data.organisation_email)
          )
          localStorage.setItem('loggedIn', JSON.stringify('Yes'))
          localStorage.setItem(
            'businessCategory',
            JSON.stringify(res.data.businessCategory)
          )
          navigate('/Home')
        } else if (res.data === 'Super Administrator') {
          localStorage.setItem('userID', JSON.stringify('0'))
          localStorage.setItem('systemSetupID', JSON.stringify('0'))
          localStorage.setItem('systemSetupBranchID', JSON.stringify('0'))
          localStorage.setItem(
            'userPositionProvisional',
            JSON.stringify('Provisional Super Administrator')
          )
          localStorage.setItem(
            'userPosition',
            JSON.stringify('Super Administrator')
          )
          localStorage.setItem(
            'userRole',
            JSON.stringify('Super Administrator')
          )
          localStorage.setItem('login', JSON.stringify('User'))
          localStorage.setItem('transactionID', JSON.stringify('0'))
          localStorage.setItem('POSTransactionID', JSON.stringify(''))
          localStorage.setItem('transactionIDPrintReceipt', JSON.stringify('0'))
          localStorage.setItem(
            'userOrganisation',
            JSON.stringify('Kanch Innovative Technologies Limited')
          )
          localStorage.setItem(
            'userName',
            JSON.stringify('Super Administrator')
          )

          localStorage.setItem(
            'organisation_address',
            JSON.stringify('Lusaka-Zambia')
          )
          localStorage.setItem(
            'organisation_phone',
            JSON.stringify('+260979171188')
          )
          localStorage.setItem(
            'organisation_email',
            JSON.stringify('kanchytechnologies@gmail.com')
          )
          localStorage.setItem('loggedIn', JSON.stringify('Yes'))
          navigate('/Home')
        }
      }*/
      //alert(res.data)
    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [businessNameSuperAdmin, businessBranchNameSuperAdmin])

  useEffect(() => {
    var formDataBusinessBranchUserNamesSuperAdmin = new FormData(this)
    formDataBusinessBranchUserNamesSuperAdmin.append(
      'apiType',
      'getBusinessBranchUserNamesDropDown'
    )
    formDataBusinessBranchUserNamesSuperAdmin.append(
      'businessBranchNameID',
      businessBranchNameSuperAdmin
    )
    formDataBusinessBranchUserNamesSuperAdmin.append(
      'role',
      businessBranchUserRolesSuperAdmin
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/superAdminTraversing.php`,
      data: formDataBusinessBranchUserNamesSuperAdmin,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessBranchUserNamesSuperAdminDropDown(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [
    businessNameSuperAdmin,
    businessBranchNameSuperAdmin,
    businessBranchUserRolesSuperAdmin
  ])

  const handleSubmit = async e => {
    //alert('Am here')
    //if (businessBranchUserNamesSuperAdmin != '') {
    //useEffect(() => {
    //alert(businessBranchUserNamesSuperAdmin)
    var formDataLoginSuperAdmin = new FormData(this)
    if (businessBranchNameSuperAdmin === 'Business Owner') {
      formDataLoginSuperAdmin.append('apiType', 'getBusinessLogin')
    } else if (businessBranchNameSuperAdmin != 'Business Owner') {
      formDataLoginSuperAdmin.append('apiType', 'getUserLogin')
    }
    formDataLoginSuperAdmin.append('businessNameID', businessNameSuperAdmin)
    //formDataLoginSuperAdmin.append('apiType', 'getUserLogin')
    formDataLoginSuperAdmin.append('usersID', businessBranchUserNamesSuperAdmin)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/superAdminTraversing.php`,
      data: formDataLoginSuperAdmin,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      //setBusinessBranchUserNamesSuperAdminDropDown(res.data)
      //alert(res.data)
      if (res.data != 'Super Administrator') {
        //Super Administrator
        //alert(res.data.userID)
        localStorage.setItem('userID', JSON.stringify(res.data.userID))
        localStorage.setItem(
          'systemSetupID',
          JSON.stringify(res.data.systemSetupID)
        )
        localStorage.setItem(
          'systemSetupBranchID',
          JSON.stringify(res.data.systemSetupBranchID)
        )
        localStorage.setItem(
          'systemSetupBranchIDStoreName',
          JSON.stringify(res.data.systemSetupBranchIDStoreName)
        )
        localStorage.setItem(
          'userPositionProvisional',
          JSON.stringify('Provisional Super Administrator')
        )
        localStorage.setItem(
          'userPosition',
          JSON.stringify(res.data.userPosition)
        )
        localStorage.setItem('userRole', JSON.stringify(res.data.userRole))
        localStorage.setItem('login', JSON.stringify('User'))
        localStorage.setItem('transactionID', JSON.stringify('0'))
        localStorage.setItem('POSTransactionID', JSON.stringify(''))
        localStorage.setItem('transactionIDPrintReceipt', JSON.stringify('0'))
        localStorage.setItem(
          'userOrganisation',
          JSON.stringify(res.data.userOrganisation)
        )
        localStorage.setItem('userName', JSON.stringify(res.data.userName))

        localStorage.setItem(
          'organisation_address',
          JSON.stringify(res.data.organisation_address)
        )
        localStorage.setItem(
          'organisation_phone',
          JSON.stringify(res.data.organisation_phone)
        )
        localStorage.setItem(
          'organisation_email',
          JSON.stringify(res.data.organisation_email)
        )
        localStorage.setItem('loggedIn', JSON.stringify('Yes'))
        sessionStorage.setItem('loggedIn', JSON.stringify('Yes'))
        localStorage.setItem(
          'businessCategory',
          JSON.stringify(res.data.businessCategory)
        )
        navigate('/Hme')
      } else if (res.data === 'Super Administrator') {
        localStorage.setItem('userID', JSON.stringify('0'))
        localStorage.setItem('systemSetupID', JSON.stringify('0'))
        localStorage.setItem('systemSetupBranchID', JSON.stringify('0'))
        localStorage.setItem(
          'systemSetupBranchIDStoreName',
          JSON.stringify('0')
        )
        localStorage.setItem(
          'userPositionProvisional',
          JSON.stringify('Provisional Super Administrator')
        )
        localStorage.setItem(
          'userPosition',
          JSON.stringify('Super Administrator')
        )
        localStorage.setItem('userRole', JSON.stringify('Super Administrator'))
        localStorage.setItem('login', JSON.stringify('User'))
        localStorage.setItem('transactionID', JSON.stringify('0'))
        localStorage.setItem('POSTransactionID', JSON.stringify(''))
        localStorage.setItem('transactionIDPrintReceipt', JSON.stringify('0'))
        localStorage.setItem(
          'userOrganisation',
          JSON.stringify('Kanch Innovative Technologies Limited')
        )
        localStorage.setItem('userName', JSON.stringify('Super Administrator'))

        localStorage.setItem(
          'organisation_address',
          JSON.stringify('Lusaka-Zambia')
        )
        localStorage.setItem(
          'organisation_phone',
          JSON.stringify('+260979171188')
        )
        localStorage.setItem(
          'organisation_email',
          JSON.stringify('kanchytechnologies@gmail.com')
        )
        localStorage.setItem('loggedIn', JSON.stringify('Yes'))
        sessionStorage.setItem('loggedIn', JSON.stringify('Yes'))
        navigate('/Hme')
      }
    })
    //.catch(err => alert(err)) //"ERROR : Kindly Contact Your Administrator"));
    /*}, [
    businessBranchUserNamesSuperAdmin,
    businessNameSuperAdmin,
    businessBranchNameSuperAdmin
  ])*/
  }
  useEffect(() => {
    var formDataBranchNames = new FormData(this)
    formDataBranchNames.append('apiType', 'getBusinessBranchNameDropDown')
    formDataBranchNames.append('businessNameID', secondUserSystemSetupID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataBranchNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessBranchNameDropDown(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [])

  countz = counts
  useEffect(() => {
    if (businessBranchName != '') {
      secondUserSystemSetupBranchID = businessBranchName
      secondUserPosition = 'Manager'
    }
    localStorage.setItem(
      'businessOwnerSelectedBranchID',
      JSON.stringify(secondUserSystemSetupBranchID)
    )
    
    /*alert(
      'secondUserSystemSetupID = ' +
        secondUserSystemSetupID +
        'dateToday = ' +
        dateToday +
        'monthToday = ' +
        monthToday +
        'secondUserPosition = ' +
        secondUserPosition +
        'secondUserSystemSetupBranchID = ' +
        secondUserSystemSetupBranchID +
        'secondUserID' +
        secondUserID
    )*/

    setHandleSubmitBtn('Loading')
    if(secondUserPosition === 'Super Administrator'){
      setHandleSubmitBtn('');
      return
    }
    var formDataFirstRow = new FormData(this)
    formDataFirstRow.append('systemSetupID', secondUserSystemSetupID)
    formDataFirstRow.append('reportType', 'firstRow')
    formDataFirstRow.append('dateToday', dateToday)
    formDataFirstRow.append('monthToday', monthToday)
    formDataFirstRow.append('userPosition', secondUserPosition)
    formDataFirstRow.append(
      'systemSetupBranchID',
      secondUserSystemSetupBranchID
    )
    formDataFirstRow.append('userID', secondUserID)    
    formDataFirstRow.append('startDate', fromDate)
    formDataFirstRow.append('endDate', toDate)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataFirstRow,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        setHandleSubmitBtn('')
        setTotalSalesReport(response.data.totalSalesReport)
        setDaySalesReport(response.data.daySalesReport)
        setTotalInventoryReport(response.data.totalInventoryReport)
        setTotalInventoryReportFirst(response.data.totalInventoryReportInitial)
        setTotalProfitReport(response.data.totalProfitReport)
        setTotalProfitReportDay(response.data.totalProfitReportDay)
        setCostTotalBusinessValue(response.data.costTotalBusinessValue)
        setSellingPriceTotalBusinessValue(
          response.data.sellingPriceTotalBusinessValue
        )
       //alert(response.data)
        /*alert(
          'Start Date = ' +
            response.data.totalSalesReport +
            ' end Date = ' +
            response.data.daySalesReport
        )*/
      })
      .catch(err => alert('Error: Check Your Internet Connection'))
  }, [businessBranchNameSuperAdmin, businessBranchName, fromDate, toDate])

  /*useEffect(() => {
    if (businessBranchName != '') {
      secondUserSystemSetupBranchID = businessBranchName
      secondUserPosition = 'Manager'
    }
    localStorage.setItem(
      'businessOwnerSelectedBranchID',
      JSON.stringify(secondUserSystemSetupBranchID)
    )

    var formDataFirstRowInitial = new FormData(this)
    formDataFirstRowInitial.append('systemSetupID', secondUserSystemSetupID)
    formDataFirstRowInitial.append('reportType', 'firstRow')
    formDataFirstRowInitial.append('dateToday', dateToday)
    formDataFirstRowInitial.append('monthToday', monthToday)
    formDataFirstRowInitial.append('userPosition', secondUserPosition)
    formDataFirstRowInitial.append(
      'systemSetupBranchID',
      secondUserSystemSetupBranchID
    )
    formDataFirstRowInitial.append('userID', secondUserID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataFirstRowInitial,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setTotalSalesReportInitial(response.data.totalSalesReport)
      setDaySalesReportInitial(response.data.daySalesReport)
      setTotalInventoryReportInitial(response.data.totalInventoryReport)
      setTotalInventoryReportFirstInitial(
        response.data.totalInventoryReportInitial
      )
      setTotalProfitReportInitial(response.data.totalProfitReport)
      setTotalProfitReportDayInitial(response.data.totalProfitReportDay)
      setCostTotalBusinessValueInitial(response.data.costTotalBusinessValue)
      setSellingPriceTotalBusinessValueInitial(
        response.data.sellingPriceTotalBusinessValue
      )

      //alert(response.data)
    })
  }, [count])*/

  let flexibleDiv = ''
  let flexibleDivNotOwner = ''
  if (
    secondUserPosition === 'Business Owner' ||
    secondUserPosition === 'Auditor'
  ) {
    flexibleDiv = 'col-xl-6 col-lg-12 mb-4'
  } else {
    flexibleDiv = 'col-xl-12 col-lg-12 mb-4'
  }

  if (
    secondUserPosition === 'Business Owner' ||
    secondUserPosition === 'Auditor'
  ) {
    flexibleDivNotOwner = 'col-md-4 col-12 text-left pl-4 pr-4'
  } else {
    flexibleDivNotOwner = 'col-md-6 col-12 text-left pl-4 pr-4'
  }

  let secondUserPositionProvisional = localStorage.getItem(
    'userPositionProvisional'
  )
  secondUserPositionProvisional = JSON.parse(secondUserPositionProvisional)

  let disabledSwitch = 'Yes'
  /*if (businessBranchUserNamesSuperAdmin === '') {
    disabledSwitch = 'Yes'
  }*/
  if (
    businessBranchNameSuperAdmin === 'Business Owner' &&
    businessBranchUserNamesSuperAdmin === ''
  ) {
    disabledSwitch = 'No'
  } else if (
    businessBranchNameSuperAdmin != 'Business Owner' &&
    businessBranchUserNamesSuperAdmin != ''
  ) {
    disabledSwitch = 'No'
  } else if (
    businessBranchNameSuperAdmin === 'Business Owner' &&
    businessBranchUserNamesSuperAdmin != ''
  ) {
    disabledSwitch = 'No'
  }
  //alert(disabledSwitch)

  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)




  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES SHOP INFORMATION MANAGEMENT SYSTEM-
        Dashboard - {systemSetupBranchIDStoreName}
      </title>
      <>
        <Headers />
        <br />
      </>

      {/*<!-- Preloader --> */}
      <div hidden = {handleSubmitBtn === ''}>
      <div id="overlayer"></div>
    <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    </div>

      <div>
        <body class='col-12'>
          {/* Main layout */}
          <main>
            <div class='container-fluid'>
              {/*<section class='section mt-5'>
               
                <div class='row'>
                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <h6 class='form-header white-text bg-info'>
                          COMPARE PRODUCT SALES
                        </h6>
                       
                        <div class='row'>
                          <div class='col-md-6 col-12 text-left pl-4'>
                            <label>Choose Products</label>
                            <Select
                              class='form-control'
                              options={posProductDropDown}
                              //isClearable={true}
                              isSearchable={true}
                              isMulti={true}
                              required
                              //value={itemID} // this doesn't let me click options
                              //maxMenuHeight={150}
                              onChange={option =>
                                HandelChangeItemDropDown(option)
                              } // this returns (option) => option.phaseText) as a string
                            />
                          </div>
                          <div class='col-md-3 col-12 text-left pl-4'>
                            <label>From Date</label>
                            <input
                              type='date'
                              class='form-control'
                              value={fromDate}
                              onChange={e => setFromDate(e.target.value)}
                            />
                          </div>
                          <div class='col-md-3 col-12 text-left pl-4'>
                            <label>To Date</label>
                            <input
                              type='date'
                              class='form-control'
                              value={toDate}
                              onChange={e => setToDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div
                          class='row col-12'
                          hidden={posProductDropDown === ''}
                        >
                          <Bar data={compareProductsPrices} />
                          
                        </div>
                      </div>
                    </div>
                  </div>
               
                </div>
                
              </section>*/}
              {/* Section: Button icon */}
              <section>
                {/* Grid row */}

                {/* Grid row */}
                <div
                  class='row'
                  hidden={
                    secondUserPosition != 'Super Administrator' &&
                    secondUserPositionProvisional !=
                      'Provisional Super Administrator'
                  }
                >
                  {/* Grid column */}
                  <div class='col-xl-12 col-md-12 mb-4'>
                    {/* Card */}
                    <div class='card'>
                      <div class='row pb-4 pl-4 pr-4'>
                        <div class='col-md-3 col-12 font-small grey-text'>
                          <label>
                            <i
                              class='fa fa-search green-text floating md-5 mr-2'
                              aria-hidden='true'
                            ></i>
                            Business Name <i class='red-text'></i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            id='businessBranchName'
                            onChange={e =>
                              setBusinessNameSuperAdmin(e.target.value)
                            }
                            autoComplete='off'
                          >
                            <option value=''>...Choose .........</option>
                            {businessNameSuperAdminDropDown.map(val => {
                              return (
                                <option value={val.system_setupID}>
                                  {val.organisation}{' '}
                                </option>
                              )
                            })}
                          </select>{' '}
                        </div>

                        <div class='col-md-3 col-12 font-small grey-text'>
                          <label>
                            <i
                              class='fa fa-search green-text floating md-5 mr-2'
                              aria-hidden='true'
                            ></i>
                            Business Branch Name <i class='red-text'></i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            id='businessBranchName'
                            onChange={e =>
                              setBusinessBranchNameSuperAdmin(e.target.value)
                            }
                            autoComplete='off'
                            disabled={businessNameSuperAdmin === ''}
                          >
                            <option value=''>...Choose .........</option>
                            <option>Business Owner</option>
                            {businessBranchNameSuperAdminDropDown.map(val => {
                              return (
                                <option value={val.system_setup_branchID}>
                                  {val.branch_name}{' '}
                                </option>
                              )
                            })}
                          </select>{' '}
                        </div>

                        <div class='col-md-2 col-12 font-small grey-text'>
                          <label>
                            <i
                              class='fa fa-search green-text floating md-5 mr-2'
                              aria-hidden='true'
                            ></i>
                            User Roles <i class='red-text'></i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            id='businessBranchName'
                            onChange={e =>
                              setBusinessBranchUserRolesSuperAdmin(
                                e.target.value
                              )
                            }
                            autoComplete='off'
                            disabled={
                              businessNameSuperAdmin === '' ||
                              businessBranchNameSuperAdmin === '' ||
                              businessBranchNameSuperAdmin === 'Business Owner'
                            }
                          >
                            <option value=''>...Choose .........</option>
                            {businessBranchUserRolesSuperAdminDropDown.map(
                              val => {
                                return (
                                  <option value={val.role}>{val.role} </option>
                                )
                              }
                            )}
                          </select>{' '}
                        </div>

                        <div class='col-md-3 col-12 font-small grey-text'>
                          <label>
                            <i
                              class='fa fa-search green-text floating md-5 mr-2'
                              aria-hidden='true'
                            ></i>
                            Business Branch User Names <i class='red-text'></i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            id='businessBranchName'
                            onChange={e =>
                              setBusinessBranchUserNamesSuperAdmin(
                                e.target.value
                              )
                            }
                            autoComplete='off'
                            disabled={
                              businessNameSuperAdmin === '' ||
                              businessBranchNameSuperAdmin === '' ||
                              businessBranchNameSuperAdmin ===
                                'Business Owner' ||
                              businessBranchUserRolesSuperAdmin === ''
                            }
                          >
                            <option value=''>...Choose .........</option>
                            {businessBranchUserNamesSuperAdminDropDown.map(
                              val => {
                                return (
                                  <option value={val.usersID}>
                                    {val.Last_name + ' ' + val.first_name}
                                  </option>
                                )
                              }
                            )}
                          </select>{' '}
                        </div>

                        <div class='col-md-1 col-12'>
                          <label></label>
                          <input
                            type='button'
                            value='SWITCH'
                            class='form-control bg-primary mt-3 white-text left-text'
                            onClick={handleSubmit}
                            disabled={disabledSwitch === 'Yes'}
                          />
                        </div>

                        {/*<div class='col-md-6 col-12'>
                          <label>From Date</label>
                          <input
                            disabled
                            type='date'
                            class='form-control'
                            value={fromDate}
                            onChange={e => setFromDate(e.target.value)}
                          />
                        </div>
                        <div class='col-md-6 col-12'>
                          <label>To Date</label>
                          <input
                            disabled
                            type='date'
                            class='form-control'
                            value={toDate}
                            onChange={e => setToDate(e.target.value)}
                          />
                          </div> */}
                      </div>
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>

                {/* Grid row */}
                <div class='row'>
                  {/* Grid column */}
                  <div class='col-xl-12 col-md-12 mb-4'>
                    {/* Card */}
                    <div class='card'>
                      <div class='row pb-4 pl-4 pr-4'>
                        <div
                          class='col-md-4 col-12 font-small grey-text'
                          hidden={
                            secondUserPosition != 'Business Owner' &&
                            secondUserPosition != 'Auditor'
                          }
                        >
                          <label>
                            <i
                              class='fa fa-search green-text floating md-5 mr-2'
                              aria-hidden='true'
                            ></i>
                            Business Branch Name <i class='red-text'></i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            id='businessBranchName'
                            onChange={e =>
                              setBusinessBranchName(e.target.value)
                            }
                            autoComplete='off'
                          >
                            <option value=''>
                              ...All Outlets .........
                            </option>
                            {businessBranchNameDropDown.map(val => {
                              return (
                                <option value={val.system_setup_branchID}>
                                  {val.branch_name}{' '}
                                </option>
                              )
                            })}
                          </select>{' '}
                        </div>

                        <div class={flexibleDivNotOwner}>
                          <label>From Date</label>
                          <input
                            type='date'
                            class='form-control'
                            value={fromDate}
                            onChange={e => setFromDate(e.target.value)}
                          />
                        </div>
                        <div class={flexibleDivNotOwner}>
                          <label>To Date</label>
                          <input
                            type='date'
                            class='form-control'
                            value={toDate}
                            onChange={e => setToDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>

                {secondUserPosition === 'Business Owner' ||
                secondUserPosition === 'Auditor' ? (
                  <>
                    <div class='row'>
                      {/* Grid column */}
                      <div class='col-xl-6 col-md-12 mb-4'>
                        {/* Card */}
                        <div class='card'>
                          {/* Card Data */}
                          <div class='row mt-1'>
                            <div class='col-md-3 col-3 text-left pl-4'>
                              <a
                                type='button'
                                class='btn-floating primary-color ml-4'
                              >
                                <i class='fa fa-money' aria-hidden='true'></i>
                              </a>
                            </div>

                            <div class='col-md-9 col-9 text-right pr-5 mt-2'>
                              <div class='font-small grey-text'>
                                Day Sales : 
                                <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                                  <NumericFormat value={daySalesReport} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                                </span>
                              </div>
                              <div class='mt-1 font-small grey-text'>
                                Total Sales : 
                                <span class='ml-4 font-weight-bold black-text'>
                                  
                                  <NumericFormat value={totalSalesReport} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* Card Data */}

                          {/*Card content */}
                          <div class='row my-1 text-left'>
                            <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                              <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                                Sales Report
                              </p>
                            </div>
                          </div>
                          {/* Card content */}
                        </div>
                        {/* Card */}
                      </div>
                      {/* Grid column */}

                      {/* Grid column */}
                      <div class='col-xl-6 col-md-12 mb-4'>
                        {/* Card */}
                        <div class='card'>
                          {/* Card Data */}
                          <div class='row mt-1'>
                            <div class='col-md-3 col-3 text-left pl-4'>
                              <a
                                type='button'
                                class='btn-floating light-blue lighten-1 ml-4'
                              >
                                <i class='fa fa-money' aria-hidden='true'></i>
                              </a>
                            </div>

                            <div class='col-md-9 col-9 text-right pr-5 mt-2'>
                              <div class='font-small grey-text'>
                                Total Day Profit :
                                <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                                  <NumericFormat value={totalProfitReportDay} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                                </span>
                              </div>
                              <div class='mt-1 font-small grey-text'>
                                Total Profit :
                                <span class='ml-4 font-weight-bold black-text'>
                                  <NumericFormat value={totalProfitReport} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* Card Data */}

                          {/*Card content */}
                          <div class='row my-1 text-left'>
                            <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                              <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                                Profit Report
                              </p>
                            </div>
                          </div>
                          {/* Card content */}
                        </div>
                        {/* Card */}
                      </div>
                      {/* Grid column */}
                    </div>

                    <div class='row'>
                      {/* Grid column */}
                      <div class='col-xl-6 col-md-12 mb-4'>
                        {/* Card */}
                        <div class='card'>
                          {/* Card Data */}
                          <div class='row mt-1'>
                            <div class='col-md-3 col-3 text-left pl-4'>
                              <a
                                type='button'
                                class='btn-floating warning-color ml-4'
                              >
                                <i
                                  class='fa fa-database'
                                  aria-hidden='true'
                                ></i>
                              </a>
                            </div>

                            <div class='col-md-9 col-9 text-right pr-5 mt-2'>
                              <div class='font-small grey-text'>
                                Current Stock
                                <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                                  <NumericFormat value={totalInventoryReport} displayType={'text'} thousandSeparator={true} prefix={''} />
                                </span>
                              </div>
                              <div class='mt-1 font-small grey-text'>
                                Total Inventory
                                <span class='ml-4 font-weight-bold black-text'>
                                  <NumericFormat value={totalInventoryReportTotal} displayType={'text'} thousandSeparator={true} prefix={''} />
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* Card Data */}

                          {/*Card content */}
                          <div class='row my-1 text-left'>
                            <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                              <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                                Inventory Report
                              </p>
                            </div>
                          </div>
                          {/* Card content */}
                        </div>
                        {/* Card */}
                      </div>
                      {/* Grid column */}

                      {/* Grid column */}
                      <div class='col-xl-6 col-md-12 mb-4'>
                        {/* Card */}
                        <div class='card'>
                          {/* Card Data */}
                          <div class='row mt-1'>
                            <div class='col-md-2 col-2 text-left pl-4'>
                              <a
                                type='button'
                                class='btn-floating red accent-2 ml-4'
                              >
                                <i class='fa fa-money' aria-hidden='true'></i>
                              </a>
                            </div>

                            <div class='col-md-10 col-10 text-right pr-5 mt-2'>
                              <div class='font-small grey-text'>
                                Cost Price Store Value :
                                <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                                  <NumericFormat value={costTotalBusinessValue} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                                </span>
                              </div>
                              <div class='mt-1 font-small grey-text'>
                                Selling Price Store Value :
                                <span class='ml-4 font-weight-bold black-text'>
                                  <NumericFormat value={sellingPriceTotalBusinessValue} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* Card Data */}

                          {/*Card content */}
                          <div class='row my-1 text-left'>
                            <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                              <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                                Total Business Value
                              </p>
                            </div>
                          </div>
                          {/* Card content */}
                        </div>
                        {/* Card */}
                      </div>
                      {/* Grid column */}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {secondUserPosition === 'Manager' ? (
                  <>
                    <div class='row'>
                      {/* Grid column */}
                      <div class='col-xl-6 col-md-12 mb-4'>
                        {/* Card */}
                        <div class='card'>
                          {/* Card Data */}
                          <div class='row mt-1'>
                            <div class='col-md-3 col-3 text-left pl-4'>
                              <a
                                type='button'
                                class='btn-floating primary-color ml-4'
                              >
                                <i class='fa fa-money' aria-hidden='true'></i>
                              </a>
                            </div>

                            <div class='col-md-9 col-9 text-right pr-5 mt-2'>
                              <div class='font-small grey-text'>
                                Day Sales :
                                <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                                  <NumericFormat value={daySalesReport} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                                </span>
                              </div>
                              <div class='mt-1 font-small grey-text'>
                                Total Sales :
                                <span class='ml-4 font-weight-bold black-text'>
                                  <NumericFormat value={totalSalesReport} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* Card Data */}

                          {/*Card content */}
                          <div class='row my-1 text-left'>
                            <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                              <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                                Sales Report
                              </p>
                            </div>
                          </div>
                          {/* Card content */}
                        </div>
                        {/* Card */}
                      </div>
                      {/* Grid column */}

                      {/* Grid column */}
                      <div class='col-xl-6 col-md-12 mb-4'>
                        {/* Card */}
                        <div class='card'>
                          {/* Card Data */}
                          <div class='row mt-1'>
                            <div class='col-md-3 col-3 text-left pl-4'>
                              <a
                                type='button'
                                class='btn-floating red accent-2 ml-4'
                              >
                                <i
                                  class='fa fa-database'
                                  aria-hidden='true'
                                ></i>
                              </a>
                            </div>

                            <div class='col-md-9 col-9 text-right pr-5 mt-2'>
                              <div class='font-small grey-text'>
                                Current Stock
                                <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                                  <NumericFormat value={totalInventoryReport} displayType={'text'} thousandSeparator={true} prefix={''} />
                                </span>
                              </div>
                              <div class='mt-1 font-small grey-text'>
                                Total Inventory
                                <span class='ml-4 font-weight-bold black-text'>
                                  <NumericFormat value={totalInventoryReportTotal} displayType={'text'} thousandSeparator={true} prefix={''} />
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* Card Data */}

                          {/*Card content */}
                          <div class='row my-1 text-left'>
                            <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                              <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                                Inventory Report
                              </p>
                            </div>
                          </div>
                          {/* Card content */}
                        </div>
                        {/* Card */}
                      </div>
                      {/* Grid column */}
                    </div>
                    {/* Grid row */}
                  </>
                ) : (
                  <></>
                )}

                {secondUserPosition === 'Cashier' ? (
                  <>
                    <div class='row'>
                      {/* Grid column */}
                      <div class='col-xl-12 col-md-12 mb-4'>
                        {/* Card */}
                        <div class='card'>
                          {/* Card Data */}
                          <div class='row mt-1'>
                            <div class='col-md-3 col-3 text-left pl-4'>
                              <a
                                type='button'
                                class='btn-floating primary-color ml-4'
                              >
                                <i class='fa fa-money' aria-hidden='true'></i>
                              </a>
                            </div>

                            <div class='col-md-9 col-9 text-right pr-5 mt-2'>
                              <div class='font-small grey-text'>
                                Day Sales :
                                <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                                  <NumericFormat value={daySalesReport} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                                </span>
                              </div>
                              <div class='mt-1 font-small grey-text'>
                                Total Sales :
                                <span class='ml-4 font-weight-bold black-text'>
                                  <NumericFormat value={totalSalesReport} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* Card Data */}

                          {/*Card content */}
                          <div class='row my-1 text-left'>
                            <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                              <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                                Sales Report
                              </p>
                            </div>
                          </div>
                          {/* Card content */}
                        </div>
                        {/* Card */}
                      </div>
                      {/* Grid column */}
                    </div>
                    {/* Grid row */}
                  </>
                ) : (
                  <></>
                )}

                {/* Grid row */}
              </section>
              {/*Section: Button icon */}

              {/* Section: Analytical panel */}
              <div class='row mt-5'>
                <div class={flexibleDiv}>
                  {/* Card */}
                  <div class='card'>
                    <div class='card-body'>
                      <h6 class='form-header white-text bg-info'>
                        DAILY SALES TRENDS
                      </h6>

                      <div class='view view-cascade gradient-card-header'>
                        {/*<div class='view view-cascade gradient-card-header indigo'>
                            <canvas id='lineChart-main' height='175'></canvas>
                              Bar,  Bubble,  Doughnut,  Line,  Pie,  PolarArea,  Radar,  Scatter
                            */}

                        {/*<Bar data={data} />*/}
                        <DailySalesTrends />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Card */}

                {/* Section: data tables */}
                {secondUserPosition === 'Business Owner' ||
                secondUserPosition === 'Auditor' ? (
                  <>
                    <div class='col-lg-6 col-md-12'>
                      <div class='card mb-4'>
                        <div class='card-body'>
                          <h6 class='form-header white-text bg-info'>
                            DAILY TOTAL PROFIT
                          </h6>
                          <DailyTotalProfits />
                          {/*<Line data={dataProfits} />*/}
                        </div>
                      </div>
                    </div>
                    {/* Grid column */}
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/* Section: data tables */}
              {/* Section: Cards color */}
              <section class='mt-2'>
                {/* Grid row 
                <div class='row'>
                  {/* Grid column 
                  <div class='col-xl-3 col-md-6 mb-4'>
                    {/* Panel 
                    <div class='card'>
                      <div class='card-header white-text primary-color'>
                        Orders
                      </div>

                      <h6 class='ml-4 mt-5 font-weight-bold'>
                        <i
                          class='fas fa-arrow-up blue-text mr-3'
                          aria-hidden='true'
                        ></i>
                        2000
                      </h6>
                      {/* Card Data 

                      {/* Card content 
                      <div class='card-body'>
                        <div class='progress'>
                          <div
                            class='progress-bar blue'
                            role='progressbar'
                            aria-valuenow='25'
                            aria-valuemin='0'
                            aria-valuemax='100'
                          ></div>
                        </div>
                        {/* Text 
                        <p class='font-small grey-text'>
                          Better than last week (25%)
                        </p>
                      </div>
                      {/* Card content 
                    </div>
                    {/* Panel 
                  </div>
                  {/* Grid column 

                  {/* Grid column 
                  <div class='col-xl-3 col-md-6 mb-4'>
                    {/* Panel 
                    <div class='card'>
                      <div class='card-header white-text warning-color'>
                        Monthly sales
                      </div>

                      <h6 class='ml-4 mt-5 font-weight-bold'>
                        <i
                          class='fas fa-arrow-up blue-text mr-3'
                          aria-hidden='true'
                        ></i>{' '}
                        $ 2000
                      </h6>
                      {/* Card Data 

                      {/* Card content
                      <div class='card-body'>
                        <div class='progress'>
                          <div
                            class='progress-bar blue'
                            role='progressbar'
                            aria-valuenow='65'
                            aria-valuemin='0'
                            aria-valuemax='100'
                          ></div>
                        </div>
                        {/* Text 
                        <p class='font-small grey-text'>
                          Better than last week (25%)
                        </p>
                      </div>
                      {/* Card content 
                    </div>
                    {/* Panel 
                  </div>
                  {/* Grid column 

                  {/* Grid column 
                  <div class='col-xl-3 col-md-6 mb-4'>
                    {/* Panel 
                    <div class='card'>
                      <div class='card-header white-text light-blue lighten-1'>
                        Sales
                      </div>

                      <h6 class='ml-4 mt-5 font-weight-bold'>
                        <i
                          class='fas fa-long-arrow-alt-down red-text mr-3'
                          aria-hidden='true'
                        ></i>{' '}
                        $ 2000
                      </h6>
                      {/* Card Data 

                      {/* Card content 
                      <div class='card-body'>
                        <div class='progress'>
                          <div
                            class='progress-bar red'
                            role='progressbar'
                            aria-valuenow='25'
                            aria-valuemin='0'
                            aria-valuemax='100'
                          ></div>
                        </div>
                        {/* Text 
                        <p class='font-small grey-text'>
                          Worse than last week (25%)
                        </p>
                      </div>
                      {/* Card content  
                    </div>
                    {/* Panel  
                  </div>
                  {/* Grid column  

                  {/* Grid column  
                  <div class='col-xl-3 col-md-6 mb-4'>
                    {/* Panel  
                    <div class='card'>
                      <div class='card-header white-text red accent-2'>
                        Daily Sales
                      </div>

                      <h6 class='ml-4 mt-5 font-weight-bold'>
                        <i
                          class='fas fa-long-arrow-alt-down red-text mr-3'
                          aria-hidden='true'
                        ></i>{' '}
                        $ 2000
                      </h6>
                      {/* Card Data 

              {/* Card content  
                      <div class='card-body'>
                        <div class='progress'>
                          <div
                            class='progress-bar red'
                            role='progressbar'
                            aria-valuenow='25'
                            aria-valuemin='0'
                            aria-valuemax='100'
                          ></div>
                        </div>
                        {/* Text 
                        <p class='font-small grey-text'>
                          Worse than last week (25%)
                        </p>
                      </div>
                      {/* Card content  
                    </div>
                    {/* Panel  
                  </div>
                  {/* Grid column  
                </div>
                {/* Grid row  */}
              </section>
              {/* Section: Cards color  */}

              {secondUserPosition === 'Business Owner' ||
              secondUserPosition === 'Auditor' ||
              secondUserPosition === 'Manager' ? (
                <>
                  {/* Section: Panels  */}
                  <section>
                    {/* Grid row  */}
                    <div class='row'>
                      {/* Grid column  */}
                      <div class='col-xl-12 col-md-12'>
                        {/* Card  */}
                        <div class='card mb-4'>
                          {/* Card Data  */}

                          {/* Card content */}
                          <div class='card-body'>
                            <h5 class='form-header mt-4 mb-4 font-weight-bold'>
                              COMPLIANCES REPORTS
                            </h5>
                            <div class='progress mb-2 mt-1'>
                              <div
                                class='progress-bar warning-color'
                                role='progressbar'
                                aria-valuenow='25'
                                aria-valuemin='0'
                                aria-valuemax='100'
                              ></div>
                            </div>

                            {/* Text */}
                            <p class='font-small grey-text mb-4'>
                              FIRE CERTIFICATE
                            </p>

                            <div class='progress mb-2'>
                              <div
                                class='progress-bar red accent-2'
                                role='progressbar'
                                aria-valuenow='25'
                                aria-valuemin='0'
                                aria-valuemax='100'
                              ></div>{' '}
                            </div>

                            {/* Text */}
                            <p class='font-small grey-text mb-4'>
                              BUSINESS LEVY
                            </p>

                            <div class='progress mb-2'>
                              <div
                                class='progress-bar primary-color'
                                role='progressbar'
                                aria-valuenow='25'
                                aria-valuemin='0'
                                aria-valuemax='100'
                              ></div>
                            </div>

                            {/* Text */}
                            <p class='font-small grey-text mb-4'>
                              PACRA CERTIFICATE
                            </p>

                            <div class='progress mb-2'>
                              <div
                                class='progress-bar light-blue lighten-1'
                                role='progressbar'
                                aria-valuenow='25'
                                aria-valuemin='0'
                                aria-valuemax='100'
                              ></div>
                            </div>
                            {/* Text */}
                            <p class='font-small grey-text mb-2'>
                              ZPPA CERTIFICATE
                            </p>
                          </div>
                          {/* Card content */}
                        </div>
                        {/* Card */}
                      </div>
                      {/* Grid column */}

                      {/* Grid column 
                  <div class='col-xl-3 col-md-6 mb-2'>
                    {/* Card 
                    <div class='card'>
                      {/* Card Data 
                      <div class='row mt-4 mb-3'>
                        <div class='col-md-3 col-3 text-left pl-4'>
                          {/* Facebook 
                          <a class='p-2 m-2 fa-lg fb-ic'>
                            <i class='fab fa-facebook-f fa-2x blue-text'> </i>
                          </a>
                        </div>

                        <div class='col-md-9 col-9 text-right pr-5'>
                          <p class='font-small grey-text mb-1'>
                            Facebook Users
                          </p>
                          <h5 class='ml-4 mb-2 font-weight-bold'>4,567 </h5>
                        </div>
                      </div>
                      {/* Card Data 
                    </div>
                    {/* Card 

                    {/* Card 
                    <div class='card mt-4'>
                      {/* Card Data 
                      <div class='row mt-4 mb-3'>
                        <div class='col-md-3 col-3 text-left pl-4'>
                          {/*  Facebook 
                          <a class='p-2 m-2 fa-lg fb-ic'>
                            <i class='fab fa-google-plus-g fa-2x red-text'> </i>
                          </a>
                        </div>

                        <div class='col-md-9 col-9 text-right pr-5'>
                          <p class='font-small grey-text mb-1'>Google+ Users</p>
                          <h5 class='ml-4 mb-2 font-weight-bold'>2,669 </h5>
                        </div>
                      </div>
                      {/*  Card Data 
                    </div>
                    {/*  Card 

                    {/*  Card 
                    <div class='card mt-4 mb-4'>
                      {/*  Card Data 
                      <div class='row mt-4 mb-3'>
                        <div class='col-md-3 col-3 text-left pl-4'>
                          {/* Facebook 
                          <a class='p-2 m-2 fa-lg fb-ic'>
                            <i class='fab fa-twitter fa-2x cyan-text'> </i>
                          </a>
                        </div>

                        <div class='col-md-9 col-9 text-right pr-5'>
                          <p class='font-small grey-text mb-1'>Twitter Users</p>
                          <h5 class='ml-4 mb-2 font-weight-bold'>3,562 </h5>
                        </div>
                      </div>
                      {/* Card Data 
                    </div>
                    {/* Card 
                  </div>
                  {/* Grid column */}
                    </div>
                    {/* Grid row */}
                  </section>
                </>
              ) : (
                <></>
              )}
            </div>
          </main>
          {/* Main layout */}
        </body>
      </div>
    </>
  )
}

export default Home
