import { useRef, useState, useEffect } from 'react'
import { NumericFormat  } from 'react-number-format';
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
//import 'jquery/dist/jquery.min.js'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
/*import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'*/
import $ from 'jquery'
import Select from 'react-select'
//import { MDBSelect } from 'mdb-react-ui-kit'
import {
  MDBContainer,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption
} from 'mdbreact'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import {BarcodeScanner} from "@itexperts/barcode-scanner";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody
} from 'mdb-react-ui-kit';

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const QUANTITY_REGEX = /^\d{1,9}$/
//const PRICE_REGEX = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/ ;
const PRICE_REGEX = /^\d+(,\d{3})*(\.\d{2,2})?$/
//const PRICE_REGEX = /^(?!0+$)*[,\d{3}]*{0,10}$/;
//const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;

const Pos = () => {

  let navigate = useNavigate()

  var posTableVisibilty = localStorage.getItem('posTableVisibilty')
  posTableVisibilty = JSON.parse(posTableVisibilty)
  //alert(posTableVisibilty)
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  let businessCategory = localStorage.getItem('businessCategory')
  businessCategory = JSON.parse(businessCategory)

let outlet = localStorage.getItem('systemSetupBranchIDStoreName')
outlet = JSON.parse(outlet)

let businessName = localStorage.getItem('userOrganisation')
businessName = JSON.parse(businessName)

let cashier = localStorage.getItem('userName')
cashier = JSON.parse(cashier)

  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const total = 0
  const [ip, setIP] = useState('')
  const [posProductDropDown, setPosProductDropDown] = useState([])
  const [posTable, setPosTable] = useState([])
  const [paymentType, setPaymentType] = useState()
  const [posTableTotal, setPosTableTotal] = useState([])
  const [receiptPrint, setReceiptPrint] = useState([])
  const [receiptPrintTotal, setReceiptPrintTotal] = useState([])
  const [receiptPrintAmountReceived, setReceiptPrintAmountReceived] = useState(
    []
  )
  const [receiptPrintChange, setReceiptPrintChange] = useState([])
  const [itemQuantity, setItemQuantity] = useState('')
  const [itemID, setItemID] = useState('')
  const [itemQuantityHidden, setItemQuantityHidden] = useState('')
  const [itemStockIDHidden, setItemStockIDHidden] = useState('')
  const [onloadItemID, setOnloadItemID] = useState('')
  const [supervisorUsername, setSupervisorUsername] = useState()
  const [supervisorPassword, setSupervisorPassword] = useState()
  const [customerEmail, setCustomerEmail] = useState()
  const [amountReceived, setAmountReceived] = useState('')
  let [insertPrice, setInsertPrice] = useState('')
  let [total_prices, setTotalPrices] = useState(0)
  const [receiptTableValues, setReceiptTableValues] = useState([])

  var [phoneNumber, setPhoneNumber] = useState('')
  var [emailKyc, setEmailKyc] = useState('')
  var [lastName, setLastName] = useState('')
  var [firstName, setFirstName] = useState('')
  var [physicalAddress, setPhysicalAddress] = useState('')

  var [referalOrNot, setReferalOrNot] = useState('')
  var [phoneNumberReferal, setPhoneNumberReferal] = useState('')
  var [emailKycReferal, setEmailKycReferal] = useState('')
  var [lastNameReferal, setLastNameReferal] = useState('')
  var [firstNameReferal, setFirstNameReferal] = useState('')
  var [physicalAddressReferal, setPhysicalAddressReferal] = useState('')
  var [refererID, setRefererID] = useState('')

  var [phoneReferedDB, setPhoneReferedDB] = useState('')
  
  var [scanWithBarcodeScanner, setScanWithBarcodeScanner] = useState('')
  var [barcodeValue, setBarcodeValue] = useState('')
  var [scanned, setScanned] = useState('')
  var [barcode, setBarcode] = useState('')

  let [handlePageSpinner, setHandlePageSpinner] = useState('')
  let [handleSubmitBtnPay, setHandleSubmitBtnPay] = useState('')
  let [handleSubmitAddItem, setHandleSubmitAddItem] = useState('')
  let [handleSubmitBtnSupervisorLogin, setHandleSubmitBtnSupervisorLogin] = useState('')
  let [handleSubmitBtnSupervisorLogout, setHandleSubmitBtnSupervisorLogout] = useState('')
  let [handleSubmitBtnDelete, setHandleSubmitBtnDelete] = useState('')


  //
  useEffect(() => {
    setBarcodeValue('')
    setItemQuantity('')
    setInsertPrice('')
  }, [scanWithBarcodeScanner])

  useEffect(() => {
    
    let options = {
      timeOut: 130,
      characterCount: 13
    }    
    let barcodeScanner = new BarcodeScanner(options);
    barcodeScanner.addEventListener('scan', function(e){
      scanned = e.detail;
        //return scanned;   
        //localStorage.setItem('barcode', JSON.stringify(scanned))          
        //setBarcode(scanned) 
        //alert('From Scanner = '+scanned);        
        //alert('From Scanner = '+itemQuantity);        
      });
      localStorage.setItem('barcode', JSON.stringify(barcodeValue)) 
      const timer = setTimeout(() => {
        //console.log('value = '+itemQuantity)
    barcode = localStorage.getItem('barcode')
    barcode = JSON.parse(barcode)    
    if(barcodeValue === barcode && barcodeValue != '' && barcodeValue !=undefined && barcodeValue !='undefine' && barcodeValue != null && barcodeValue != 'null'){
    //alert('From Scanners = '+barcode);

    setHandleSubmitAddItem('Loading')
       var formDataPosTable = new FormData(this)
      //formDataPosTable.append('apiType', 'testInsertPosTableBarcode')
      formDataPosTable.append('businessNameID', systemSetupIDInitial)
      formDataPosTable.append('branchNameID', secondSystemSetupBranchID)
      formDataPosTable.append('barcode', barcode)
      formDataPosTable.append('inputType', 'Scanner')
      
      formDataPosTable.append('apiType', 'insertPosTable')
      formDataPosTable.append('itemID', itemID)
      formDataPosTable.append('itemQuantity', itemQuantity)
      formDataPosTable.append('transactionID', transactionIDSecond)
      formDataPosTable.append('userID', userID)
      formDataPosTable.append('POSTransactionID', POSTransactionID)
      //formDataPosTable.append('insertPrice', insertPrice)
        if (insertPrice != '') {
          formDataPosTable.append('insertPrice', insertPrice)
        }
      Axios({
        method: 'post',
        url: `${server_app_api_php}/index.php`,
        data: formDataPosTable,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        setHandleSubmitAddItem('')
        if(response.data === 'undefined' || response.data === undefined || response.data === null || response.data === 'Something Is Wrong, Check Your Code'){
          localStorage.removeItem('barcode')
          //alert('From Database = '+response.data) 
          setBarcodeValue(''); 
          setItemQuantity('')
        setInsertPrice('')
      }   
      else if(response.data === 'Failed : Barcode Does Not Exist'){
        localStorage.removeItem('barcode')
        setBarcodeValue('');
        setItemQuantity('')
        setInsertPrice('')
        //localStorage.setItem('posTableVisibilty', JSON.stringify('Yes'))
        alert(response.data) 
      }
      else if(response.data === 'Negative Numbers and Zeroes Are Not Allowed' || response.data === 'The Quantity Entered Is Larger Than The Available Quantity In Stock'){
        localStorage.removeItem('barcode')
        setBarcodeValue('');
        setItemQuantity('')
        setInsertPrice('')
        //localStorage.setItem('posTableVisibilty', JSON.stringify('Yes'))
        alert(response.data) 
    } 
    else if(response.data === 'Successful'){
      localStorage.removeItem('barcode')
      setBarcodeValue('');
      setItemQuantity('')
      setInsertPrice('')
      localStorage.setItem('posTableVisibilty', JSON.stringify('Yes'))
      //alert(response.data) 
  }    
      })
    } 
  }, 1000)
  }, [barcodeValue])
  /*var inputTemp = '';
  var inputTempInterval = setInterval(function(){
    if(inputTemp.length >= 5){
      var detected = inputTemp;
      inputTemp = '';
      clearInterval(inputTempInterval);
      onScannerTrigger(detected);
    } else{
      inputTemp = '';
    }
  }, 100);

  $(document).keypress(function(e){
    inputTemp += String.fromCharCode(e.which);
  });
  function onScannerTrigger(scannedCode){
    console.log(scannedCode);
  }*/

  /*useEffect(() => {
   setTimeout(function () {
    alert(itemQuantity)*/
    /*var formDataPosTable = new FormData(this)
      formDataPosTable.append('apiType', 'testInsertPosTableBarcode')
      formDataPosTable.append('businessNameID', systemSetupIDInitial)
      formDataPosTable.append('branchNameID', secondSystemSetupBranchID)
      formDataPosTable.append('barcode', itemQuantity)
      formDataPosTable.append('inputType', 'Scanner')

      
      //formDataPosTable.append('apiType', 'insertPosTable')
      formDataPosTable.append('itemID', itemID)
      formDataPosTable.append('itemQuantity', '')
      formDataPosTable.append('transactionID', transactionIDSecond)
      formDataPosTable.append('userID', userID)
      formDataPosTable.append('POSTransactionID', POSTransactionID)
        formDataPosTable.append('insertPrice', insertPrice)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/index.php`,
        data: formDataPosTable,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        if(response.data != null){
          alert(response.data)  
      }      
      })*/

  /*}, 1000)
  }, [itemQuantity])*/

  /*if (insertPrice > 0) {
    total_prices = insertPrice
  }*/
  //const setItemID = e => {
  const HandelChangeItemDropDown = obj => {
    setItemID(obj.value)
  }

  const handleChangeAmountReceived = event => {
    setAmountReceived(event.target.value)
  }
  const handleChangeInsertPrice = event => {
    setInsertPrice(event.target.value)
  }

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [ip])

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  const firstSystemSetupBranchID = localStorage.getItem('systemSetupBranchID')
  const secondSystemSetupBranchID = JSON.parse(firstSystemSetupBranchID)
  //systemSetupBranchID:""3""
  const firstUserID = localStorage.getItem('userID')
  const userIDInitial = JSON.parse(firstUserID)
  const userID = userIDInitial
  //alert (userID);
  //alert("id = "+systemSetupIDInitial);
  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getPosProductDropDown`, {
      businessNameID: systemSetupIDInitial
    })*/
    setHandlePageSpinner('Loading')
    var formDataProductNames = new FormData(this)
    formDataProductNames.append('apiType', 'getPosProductDropDown')
    formDataProductNames.append('businessNameID', systemSetupIDInitial)
    formDataProductNames.append('branchNameID', secondSystemSetupBranchID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataProductNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandlePageSpinner('')
      const posProductDropDown = response.data
      setPosProductDropDown(
        posProductDropDown.map(
          ({ id, product, stockID, stock_quantity, product_unit_price }) => ({
            id,
            label:
              product +
              '(Quantity = ' +
              stock_quantity +
              ') (Unit Price = K ' +
              product_unit_price +
              ')',
            value: stockID
          })
        )
      )
      //setPosProductDropDown(countriesLocal)
      var a = response.data
      localStorage.setItem('arr', JSON.stringify(a))
      //a = JSON.parse(localStorage.getItem('arr')) || [];
      //alert(a);
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [posProductDropDown])
  }, [])

  const handleSupervisorLogin = async e => {
    //alert("Username "+ supervisorUsername +" Password = "+supervisorPassword);
    /*Axios.post(`${server_app_api}/api/userLogin`, {
      user: supervisorUsername,
      pwd: supervisorPassword,
      user_IP: ip
    })*/
    setHandleSubmitBtnSupervisorLogin('Loading')
    var formDataLogin = new FormData(this)
    formDataLogin.append('apiType', 'supervisorLogin')
    formDataLogin.append('user', supervisorUsername)
    formDataLogin.append('pwd', supervisorPassword)
    formDataLogin.append('user_IP', ip)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataLogin,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setHandleSubmitBtnSupervisorLogin('')
      if (res.data != 'Failed To Login, Check Your Credentials') {
        res.data.map(val => {
          localStorage.setItem('userPosition', JSON.stringify(val.position))
          //var valposition = val.position
          //alert(valposition)
        })
        //localStorage.setItem('userPosition', JSON.stringify(res.data.position))
      } else {
        alert(res.data)
      }
    })
  }

  //let navigate = useNavigate();
  const handleSupervisorLogout = async e => {
    /* Axios.post(`${server_app_api}/api/supervisorLogout`, {
      userID: userID
    })*/
    setHandleSubmitBtnSupervisorLogout('Loading')
    var formDataSupervisorLogout = new FormData(this)
    formDataSupervisorLogout.append('apiType', 'supervisorLogout')
    formDataSupervisorLogout.append('userID', userID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataSupervisorLogout,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setHandleSubmitBtnSupervisorLogout('')
      if (res.data != 'Failed To Login, Check Your Credentials') {
        res.data.map(vals => {
          localStorage.setItem('userPosition', JSON.stringify(vals.position))
          //var valsposition = vals.position
          //alert('Login Successful = ' + valsposition)
        })
      } else {
        alert('Your Session Has Expired. We are Logging out')

        navigate('/')
      }
    })
  }

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`
  const timeNow =
    currentDate.getHours() +
    ':' +
    currentDate.getMinutes() +
    ':' +
    currentDate.getSeconds()

  const dateTodayPOSTable = `${currentDate.getDate()}${
    currentDate.getMonth() + 1
  }${currentDate.getFullYear()}`
  const timeNowPOSTable =
    currentDate.getHours() +
    '' +
    currentDate.getMinutes() +
    '' +
    currentDate.getSeconds()

  const transactionIDSaved = localStorage.getItem('transactionID')
  const secondTransactionIDSaved = JSON.parse(transactionIDSaved)
  if (secondTransactionIDSaved === '0') {
    localStorage.setItem(
      'transactionID',
      JSON.stringify(timeNowPOSTable + dateTodayPOSTable + userID)
    )
  }
  const firstTransactionID = localStorage.getItem('transactionID')
  const transactionIDSecond = JSON.parse(firstTransactionID)

  const POSTransactionID1 = localStorage.getItem('POSTransactionID')
  const POSTransactionID = JSON.parse(POSTransactionID1)

  const transactionIDPrintReceipt1 = localStorage.getItem(
    'transactionIDPrintReceipt'
  )
  const transactionIDPrintReceipt = JSON.parse(transactionIDPrintReceipt1)

  const organisation_address = JSON.parse(
    localStorage.getItem('organisation_address')
  )
  const organisation_phone = JSON.parse(
    localStorage.getItem('organisation_phone')
  )
  const organisation_email = JSON.parse(
    localStorage.getItem('organisation_email')
  )
  //alert(organisation_phone);

  if (POSTransactionID === '' || POSTransactionID === null) {
    localStorage.setItem(
      'POSTransactionID',
      JSON.stringify(userID + timeNowPOSTable + dateTodayPOSTable + userID)
    )
  }

  /*KYC FROM DATABASE STARTS HERE*/
  useEffect(() => {
    //alert(phoneNumber)
    var formDataGetKyc = new FormData(this)
    formDataGetKyc.append('apiType', 'getKyc')
    formDataGetKyc.append('phoneNumber', phoneNumber)
    formDataGetKyc.append('branchNameID', secondSystemSetupBranchID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataGetKyc,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setPosTable(response.data)
      //alert(response.data)
      //setPhoneNumber(response.data.phone)
      setEmailKyc(response.data.email)
      setLastName(response.data.lastName)
      setFirstName(response.data.firstName)
      setPhysicalAddress(response.data.physicalAddress)
    })
    //.catch(err => alert('ERROR : Check Your Network Connection'))
  }, [phoneNumber])

  useEffect(() => {
    var formDataGetKyc = new FormData(this)
    formDataGetKyc.append('apiType', 'getKyc')
    formDataGetKyc.append('phoneNumber', phoneNumberReferal)
    formDataGetKyc.append('branchNameID', secondSystemSetupBranchID)
    formDataGetKyc.append('businessNameID', systemSetupIDInitial)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataGetKyc,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setEmailKycReferal(response.data.email)
      setLastNameReferal(response.data.lastName)
      setFirstNameReferal(response.data.firstName)
      setPhysicalAddressReferal(response.data.physicalAddress)
      setRefererID(response.data.kycID)
    })
    //.catch(err => alert('ERROR : Check Your Network Connection'))
  }, [phoneNumberReferal])

  useEffect(() => {
    var formDataGetKyc = new FormData(this)
    formDataGetKyc.append('apiType', 'getKyc')
    formDataGetKyc.append('phoneNumber', phoneNumber)
    formDataGetKyc.append('branchNameID', secondSystemSetupBranchID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataGetKyc,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setPhoneReferedDB(response.data.phoneNumber)
      if (response.data.phoneNumber != '' && referalOrNot === 'Yes') {
        setReferalOrNot('No')
        alert('This Customer Was Already Refered Here')
      }
    })
    //.catch(err => alert('ERROR : Check Your Network Connection'))
  }, [phoneNumber])
  /*KYC FROM DATABASE ENDS HERE*/

  /*KYC TO DATABASE STARTS HERE*/
  const handleKyc = async e => {
    // useEffect(() => {
    var formDataGetKyc = new FormData(this)
    formDataGetKyc.append('apiType', 'postKyc')
    formDataGetKyc.append('phoneNumber', phoneNumber)
    formDataGetKyc.append('emailKyc', emailKyc)
    formDataGetKyc.append('lastName', lastName)
    formDataGetKyc.append('firstName', firstName)
    formDataGetKyc.append('physicalAddress', physicalAddress)
    formDataGetKyc.append('businessNameID', systemSetupIDInitial)
    formDataGetKyc.append('branchNameID', secondSystemSetupBranchID)
    formDataGetKyc.append('userID', userID)
    formDataGetKyc.append('POSTransactionID', POSTransactionID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataGetKyc,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setPosTable(response.data)
      setPhoneNumber('')
      setEmailKyc('')
      setLastName('')
      setFirstName('')
      setPhysicalAddress('')
      alert(response.data)
    })
  } //, [phoneNumber])
  /*KYC TO DATABASE ENDS HERE*/

  const handleAddItems = async e => {
    //console.log('data ' + itemQuantity)
    if (itemID === undefined || itemQuantity === undefined) {
      alert('please Fill Empty Fieds')
    } else {
      //alert('Added Item' + itemID)
      localStorage.setItem('transactionIDPrintReceipt', JSON.stringify('0')) // This line here Empties The transaction ID For Receipt Printing To Hide Print and Email Buttons

      /*Axios.post(`${server_app_api}/api/insertPosTable`, {
        businessNameID: systemSetupIDInitial,
        itemID: itemID,
        itemQuantity: itemQuantity,
        transactionID: transactionIDSecond,
        userID: userID,
        POSTransactionID: POSTransactionID
      })*/

      setHandleSubmitAddItem('Loading')
      var formDataPosTable = new FormData(this)
      formDataPosTable.append('apiType', 'insertPosTable')
      formDataPosTable.append('businessNameID', systemSetupIDInitial)
      formDataPosTable.append('itemID', itemID)
      formDataPosTable.append('itemQuantity', itemQuantity)
      formDataPosTable.append('transactionID', transactionIDSecond)
      formDataPosTable.append('userID', userID)
      formDataPosTable.append('POSTransactionID', POSTransactionID)
      formDataPosTable.append('inputType', 'Keyboard')
      if (insertPrice != '') {
        formDataPosTable.append('insertPrice', insertPrice)
      }

      Axios({
        method: 'post',
        url: `${server_app_api_php}/index.php`,
        data: formDataPosTable,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        setHandleSubmitAddItem('')
        setInsertPrice('')
        setItemQuantity('')
        localStorage.setItem('posTableVisibilty', JSON.stringify('Yes'))
        //setPosTable(response.data);
        if (response.data != '' && response.data != 'Successful') {
          alert(response.data)
        }
      })
    }

    //([posProductDropDown])
  }

  const handlePay = async e => {
    setHandleSubmitBtnPay('Loading')
    // if(paymentType !="Cash")
    //{
    //amountReceived = null;
    //}
    alert('You Are Now Processing The Transaction')

    /*Axios.post(`${server_app_api}/api/insertPay`, {
      businessNameID: systemSetupIDInitial,
      dateToday: dateToday,
      timeNow: timeNow,
      system_setup_IP: ip,
      paymentType: paymentType,
      transactionID: transactionIDSecond,
      userID: userID,
      amountReceived: amountReceived
    })*/
    var formDataPay = new FormData(this)
    formDataPay.append('apiType', 'insertPay')
    formDataPay.append('businessNameID', systemSetupIDInitial)
    formDataPay.append('dateToday', dateToday)
    formDataPay.append('timeNow', timeNow)
    formDataPay.append('paymentType', paymentType)
    formDataPay.append('transactionID', transactionIDSecond)
    formDataPay.append('userID', userID)
    formDataPay.append('amountReceived', amountReceived)
    formDataPay.append('system_setup_IP', ip)
    formDataPay.append('phoneNumber', phoneNumber)
    formDataPay.append('emailKyc', emailKyc)
    formDataPay.append('lastName', lastName)
    formDataPay.append('firstName', firstName)
    formDataPay.append('physicalAddress', physicalAddress)
    if (phoneReferedDB != '' && phoneNumberReferal != '') {
      refererID = ''
      alert('This Customer Was Already Refered Here')
    }
    formDataPay.append('refererID', refererID)
    formDataPay.append('branchNameID', secondSystemSetupBranchID)
    formDataPay.append('POSTransactionID', POSTransactionID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataPay,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtnPay('')
      //setPosTable(response.data);
      //alert(response.data)
      if (response.data === 'Transaction Processed Successfuly') {
        setPaymentType('')
        setAmountReceived('')

        setPhoneNumber('')
        setEmailKyc('')
        setLastName('')
        setFirstName('')
        setPhysicalAddress('')

        setPhoneNumberReferal('')
        setEmailKycReferal('')
        setLastNameReferal('')
        setFirstNameReferal('')
        setPhysicalAddressReferal('')
        setRefererID('')
        setReferalOrNot('')
        setPhoneReferedDB('')
        //setPosProductDropDown('')
        localStorage.setItem('posTableVisibilty', JSON.stringify(''))
        localStorage.setItem(
          'transactionIDPrintReceipt',
          JSON.stringify(transactionIDSecond)
        ) // This line here Sets The transaction ID For Receipt Printing To Show Print and Email Buttons
        localStorage.setItem('transactionID', JSON.stringify('0')) // This line here Empties The transaction ID
      } else {
        alert(
          'There Was A Problem Processing Transaction, Please Contact The Administrator Immediately'
        )
      }
      alert(response.data)
      //window.location.reload()
    })
  }

  //This API Call Returns The Receipt Values For This Transaction
  useEffect(() => {
    /* Axios.post(`${server_app_api}/api/getReceiptPrint`, {
      transactionID: transactionIDPrintReceipt
    })*/
    var formDataGetReceiptPrint = new FormData(this)
    formDataGetReceiptPrint.append('apiType', 'getReceiptPrint')
    formDataGetReceiptPrint.append('transactionID', transactionIDPrintReceipt)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataGetReceiptPrint,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(responseReceiptPrint => {
      setReceiptPrint(responseReceiptPrint.data)
      setReceiptTableValues(responseReceiptPrint.data)
      localStorage.setItem(
        'responseReceiptPrint',
        JSON.stringify(responseReceiptPrint.data)
      )
      //alert(JSON.stringify(responseReceiptPrint));
    })
  }, [receiptPrint])

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getReceiptPrintTotal`, {
      transactionID: transactionIDPrintReceipt
    })*/
    var formDataGetReceiptPrintTotal = new FormData(this)
    formDataGetReceiptPrintTotal.append('apiType', 'getReceiptPrintTotal')
    formDataGetReceiptPrintTotal.append(
      'transactionID',
      transactionIDPrintReceipt
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataGetReceiptPrintTotal,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(responseReceiptPrintTotal => {
      setReceiptPrintTotal(responseReceiptPrintTotal.data)
    })
  }, [receiptPrintTotal])

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getReceiptPrintAmountReceived`, {
      transactionID: transactionIDPrintReceipt
    })*/
    var formDataGetReceiptPrintAmountReceived = new FormData(this)
    formDataGetReceiptPrintAmountReceived.append(
      'apiType',
      'getReceiptPrintAmountReceived'
    )
    formDataGetReceiptPrintAmountReceived.append(
      'transactionID',
      transactionIDPrintReceipt
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataGetReceiptPrintAmountReceived,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(responseReceiptPrintAmountReceived => {
      setReceiptPrintAmountReceived(responseReceiptPrintAmountReceived.data)
    })
  }, [receiptPrintAmountReceived])

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getReceiptPrintChange`, {
      transactionID: transactionIDPrintReceipt
    })*/
    var formDataGetReceiptPrintChange = new FormData(this)
    formDataGetReceiptPrintChange.append('apiType', 'getReceiptPrintChange')
    formDataGetReceiptPrintChange.append(
      'transactionID',
      transactionIDPrintReceipt
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataGetReceiptPrintChange,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(responseReceiptPrintChange => {
      setReceiptPrintChange(responseReceiptPrintChange.data)
      localStorage.setItem(
        'responseReceiptPrintTotal',
        JSON.stringify(responseReceiptPrintChange.data)
      )
    })
  }, [receiptPrintChange])
  // }, [receiptPrintChange])
  //The Receipt Call Ends Here

  /*var existing= localStorage.getItem('namesArray');
existing = existing ? existing.split(',') : [];
alert("tuna");
existing.push(tuna);
localStorage.setItem('namesArray', existing.toString());*/
  //prompt("New member name?");
  //prompt("New member names?");
  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getPosTable`, {
      transactionID: transactionIDSecond
    })*/
    var formDataGetPosTable = new FormData(this)
    formDataGetPosTable.append('apiType', 'getPosTable')
    formDataGetPosTable.append('transactionID', transactionIDSecond)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataGetPosTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setPosTable(response.data)
      //alert(response.data)
    })
  }, [posTable])

  //var total_prices = 0
  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getPosTableTotal`, {
      transactionID: transactionIDSecond
    })*/
    var formDataGetPosTableTotal = new FormData(this)
    formDataGetPosTableTotal.append('apiType', 'getPosTableTotal')
    formDataGetPosTableTotal.append('transactionID', transactionIDSecond)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataGetPosTableTotal,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setPosTableTotal(response.data)
      response.data.map(val => {
        //alert(val.total_prices)
        setTotalPrices(val.total_prices)
      })
    })
  }, [posTableTotal])

  const handlePOSTableDelete = async e => {
    setHandleSubmitBtnDelete('Loading')
    const posTableDeleteID = e.target.value
    //alert(posTableDeleteID);
    //useEffect( () => {
    /*Axios.post(`${server_app_api}/api/posTableDeleteID`, {
      posTableDeleteID: posTableDeleteID
    })*/
    var formDataPosTableDeleteID = new FormData(this)
    formDataPosTableDeleteID.append('apiType', 'posTableDeleteID')
    formDataPosTableDeleteID.append('posTableDeleteID', posTableDeleteID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataPosTableDeleteID,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtnDelete('')
      //setPosTableTotal(response.data);
      alert(response.data)
    })(
      //},
      //[posTable, posProductDropDown,posTableTotal, ])

      [posTable, posProductDropDown, posTableTotal]
    )
  }

  const firstRolePosition = localStorage.getItem('userPosition')
  const secondRolePosition = JSON.parse(firstRolePosition)
  //alert(secondRolePosition);

  //alert(POSTransactionID);

  //const handleCustomerEmail = async (e) =>
  //{

  const firstUserOrganisation = localStorage.getItem('userOrganisation')
  const secondUserOrganisation = JSON.parse(firstUserOrganisation)

  const firstResponseReceiptPrintVar = localStorage.getItem(
    'responseReceiptPrint'
  )
  let secondResponseReceiptPrintVar = JSON.parse(firstResponseReceiptPrintVar)
  secondResponseReceiptPrintVar = JSON.stringify(secondResponseReceiptPrintVar)

  const firstResponseReceiptPrintTotalVar = localStorage.getItem(
    'responseReceiptPrintTotal'
  )
  let secondResponseReceiptPrintTotalVar = JSON.parse(
    firstResponseReceiptPrintTotalVar
  )
  secondResponseReceiptPrintTotalVar = JSON.stringify(
    secondResponseReceiptPrintTotalVar
  )
  //setReceiptTableValues(secondResponseReceiptPrintVar);
  const handleCustomerEmail = e => {
    e.preventDefault()
    const form = $(e.target)
    $.ajax({
      type: 'POST',
      url: form.attr('action'),
      data: form.serialize(),
      success: function (data) {
        alert(data)
      }
      //success(data) {
      //setResult(data);
      //},
      //alert(data);
    })
    /*
<script>
 $(document).ready(function(){
	 $("#province").change(function(){
		 var province= $('#province').val().trim();
		 //alert(province);
		  $.ajax({
            type:'POST',
            url: 'drops.php',
             data: {
			province : province,
			},
            
            success:function(data){				
				//alert(data);
				$('#divShowlocalAuthority').html(data);
				$('#divShowlocalAuthority').show();
				$('#hideLocalAuthority').hide();
            },
        });	
		 
	 });
 });
 
 </script>
*/
    alert('Receipt Sent To The Email : ' + customerEmail)

    /*const subject = "Receipt";
    const message = "We Have Sent The Receipt Via E - Mail";
	  const html = "Body";
   // const responseReceiptPrintVar = JSON.stringify(responseReceiptPrint); 
//alert(secondResponseReceiptPrintVar);
alert ("We Are Sending The Receipt To Our Customer Via Email...");
Axios.post(`${server_app_api}/api/emailSend`, {
  //Axios.post("http://localhost:3001/api/DataJson", {
  customerEmail : customerEmail,
  secondUserOrganisation : secondUserOrganisation,
  dateToday : dateToday,
  timeNow : timeNow,
  organisation_addressToPrint : organisation_address,
  organisation_phoneToPrint : organisation_phone,
  organisation_emailToPrint : organisation_email,
  responseReceiptPrintVar : secondResponseReceiptPrintVar,
  responseReceiptPrintTotalVar : secondResponseReceiptPrintTotalVar,
  transactionIDSecondToPrint : transactionIDSecond,

}).then((response) => { 
//setPosTableTotal(response.data);
alert(response.data);
})
*/
  }

  const testPDF = async e => {
    alert('Testing To PDF')
    Axios.get(`${server_app_api}/api/DataJson`, {}).then(response => {
      //setPosTableTotal(response.data);
      alert(response.data)
    })
  }

  function print () {
    var content = document.getElementById('printarea')
    var pri = document.getElementById('ifmcontentstoprint').contentWindow
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()
  }

  /*const sendEmail = (e) => {
  e.preventDefault(); // this helps in making the form not to refresh when submitting
alert("We Are Submiting Form");
}
originalString = 'GeeksForGeeks';
            newString = originalString.replace(/G/g, '');
*/

  //let originalString = 'GeeksForGeeks';
  //let newString = originalString.replace('ee', '');
  //alert(newString);
  /*;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()*/

  /*$(document).ready(function () {
    $('.mdb-select').materialSelect()
  })*/

  /*const ICountryOption {
    label: string
    value: string
    population: number
  }
  */

  const countriesLocal = [
    { label: 'China', value: 'china', population: 1402000 },
    { label: 'India', value: 'india', population: 1380000 },
    { label: 'USA', value: 'usa', population: 330000 }
  ]

  useEffect(() => {
    if (referalOrNot === undefined || referalOrNot === '') {
      setPhoneNumber('')
      setEmailKyc('')
      setLastName('')
      setFirstName('')
      setPhysicalAddress('')

      setPhoneNumberReferal('')
      setEmailKycReferal('')
      setLastNameReferal('')
      setFirstNameReferal('')
      setPhysicalAddressReferal('')
      setRefererID('')
    } else if (referalOrNot === 'Yes' || referalOrNot === 'No') {
      setPhoneNumberReferal('')
      setEmailKycReferal('')
      setLastNameReferal('')
      setFirstNameReferal('')
      setPhysicalAddressReferal('')
      setRefererID('')
    }
  }, [referalOrNot])

  /* useEffect(() => {
    if (phoneReferedDB != '') {
      setPhoneNumberReferal('')
      setEmailKycReferal('')
      setLastNameReferal('')
      setFirstNameReferal('')
      setPhysicalAddressReferal('')
      setRefererID('')
      alert('This Customer Was Already Refered Here')
    }
  }, [phoneNumber])*/

  var phoneKycWidth = ''
  if (phoneNumber === '' || phoneNumber === undefined) {
    phoneKycWidth = 'col-lg-12 col-md-12 mb-0'
  } else if (phoneNumber != '' && phoneNumber != undefined) {
    phoneKycWidth = 'col-lg-6 col-md-12 mb-0'
  }

  var referalWidth = ''
  if (
    referalOrNot === undefined ||
    referalOrNot === '' ||
    referalOrNot === 'No'
  ) {
    referalWidth = 'col-lg-12 col-md-12 mb-0'
  } else if (referalOrNot === 'Yes') {
    referalWidth = 'col-lg-2 col-md-12 mb-0'
  }

  var submitBtnTitle = 'The Product Amount Is Not Yet Entered'

  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)

  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - Point Of Sale -{' '}
        {systemSetupBranchIDStoreName}{' '}
      </title>
      <>
        <Headers />
        <br />
        <br />
      </>
     
      <div hidden={handlePageSpinner ===''}>
        <div id='overlayer'></div>
        <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
            <span class='visually-hidden'>Loading...</span>
          </div>
 
        </div>
        </div>        
     
        <div hidden={handleSubmitBtnDelete ===''}>
        <div class="loader d-flex justify-content-center">          
          <div class="spinner-border text-danger" role="status" style={{width: '10rem', height: '10rem'}}>
          <span class=''>Loading...</span>
</div>
        </div>
        </div>

      <main>
        <div class='container-fluid'>
          <section class='section card mb-1'>
            <div class='card'>
              <div class='card-body'>
                <div class='form-header ocean blue-gradient'>
                  <h1 class='text-center my-1 h1'>POINT OF SALE</h1>
                </div>

                <div class='row'>
                  {/*<!-- Modal: modalManagerLogin  -->*/}
                  <div
                    class='modal fade'
                    id='modalManagerLogin'
                    tabindex='-1'
                    role='dialog'
                    aria-labelledby='myModalLabel'
                    aria-hidden='true'
                  >
                    <div class='modal-dialog cascading-modal' role='document'>
                      {/*<!-- Content -->*/}
                      <div class='modal-content'>
                        {/* <!-- Header -->*/}
                        <div class='modal-header light-blue darken-3 white-text '>
                          <h4 class=''>Supervisor Login :</h4>
                          <button
                            type='button'
                            class='close waves-effect waves-light'
                            data-dismiss='modal'
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>
                        {/*<!-- Body -->*/}
                        <div class='modal-body mb-0'>
                          <div class='row'>
                            {' '}
                            {/*Row Starts Here*/}
                            {/*<!-- Grid column -->*/}
                            <div class='col-md-12 mb-0.00001'>
                              <div class='md-form '>
                                <label for='f3' class=''>
                                  Username
                                </label>
                                <br />
                                <input
                                  type='text'
                                  id='f3'
                                  class='form-control form-control'
                                  value={supervisorUsername}
                                  onChange={e =>
                                    setSupervisorUsername(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            {/*<!-- Grid column -->*/}
                          </div>{' '}
                          {/*Row Ends Here*/}
                          <div class='row'>
                            {' '}
                            {/*Row Starts Here*/}
                            {/*<!-- Grid column -->*/}
                            <div class='col-md-12 mb-0.00001'>
                              <div class='md-form '>
                                <label for='f3' class=''>
                                  Password
                                </label>
                                <br />
                                <input
                                  type='password'
                                  id='f3'
                                  class='form-control form-control'
                                  value={supervisorPassword}
                                  onChange={e =>
                                    setSupervisorPassword(e.target.value)
                                  }
                                />
                              </div> 
                            </div>
                            {/*<!-- Grid column -->*/}
                          </div>{' '}
                          {/*Row Ends Here*/}
                          <button
                            class='btn btn-primary'
                            onClick={handleSupervisorLogin}
                            disabled = {handleSubmitBtnSupervisorLogin === 'Loading'}
                          >
                                                     <div class="spinner-border" role="status" hidden={handleSubmitBtnSupervisorLogin === ''}>
</div>
                            <i class='w-fa fas fa-sign-in-alt'></i>Login
                          </button>
                        </div>
                        {/*Modal Body Ends Here*/}
                      </div>
                    </div>
                    {/*<!-- Content -->*/}
                  </div>
                  {/* <!-- Modal: modalManager .-->*/}
                  {/*<!-- Modal: modalKyc  -->*/}
                  <div
                    class='modal fade'
                    id='modalKyc'
                    tabindex='-1'
                    role='dialog'
                    aria-labelledby='myModalLabel'
                    aria-hidden='true'
                  >
                    <div class='modal-dialog cascading-modal' role='document'>
                      {/*<!-- Content -->*/}
                      <div
                        class='modal-content'
                        style={{
                          backgroundColor: '',
                          width: '130%',
                          align: 'center'
                        }}
                      >
                        {/* <!-- Header -->*/}
                        <div class='modal-header light-blue darken-3 white-text '>
                          <h4 class=''>KNOW YOUR CUSTOMER</h4>
                          <button
                            type='button'
                            class='close waves-effect waves-light'
                            data-dismiss='modal'
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>
                        {/*<!-- Body -->*/}
                        <div class='modal-body mb-0'>
                          {/*<!-- Grid row -->*/}
                          <div class='row'>
                            {/*<!-- Grid column -->*/}
                            <div class='col-lg-6 col-md-12 mb-0'>
                              <div class='md-form '>
                                <label for='f3' class=''>
                                  Phone Number
                                </label>
                                <br />
                                <input
                                  type='text'
                                  id='f3'
                                  class='form-control form-control'
                                  value={phoneNumber}
                                  onChange={e => setPhoneNumber(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            {/*<!-- Grid column -->*/}
                            {/*<!-- Grid column -->*/}
                            <div
                              class='col-lg-6 col-md-12 mb-0'
                              hidden={
                                phoneNumber === '' || phoneNumber === undefined
                              }
                            >
                              <div class='md-form '>
                                <label for='f3' class=''>
                                  Email
                                </label>
                                <br />
                                <input
                                  type='email'
                                  id='f3'
                                  class='form-control form-control'
                                  value={emailKyc}
                                  onChange={e => setEmailKyc(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            {/*<!-- Grid column -->*/}
                          </div>{' '}
                          {/*Row Ends Here*/}
                          <div
                            class='row'
                            hidden={
                              phoneNumber === '' || phoneNumber === undefined
                            }
                          >
                            {' '}
                            {/*Row Starts Here*/}
                            {/*<!-- Grid column -->*/}
                            <div class='col-lg-6 col-md-12 mb-0'>
                              <div class='md-form '>
                                <label for='f3' class=''>
                                  Last Name
                                </label>
                                <br />
                                <input
                                  type='text'
                                  id='f3'
                                  class='form-control form-control'
                                  value={lastName}
                                  onChange={e => setLastName(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            {/*<!-- Grid column -->*/}
                            <div class='col-lg-6 col-md-12 mb-0'>
                              <div class='md-form '>
                                <label for='f3' class=''>
                                  First Name
                                </label>
                                <br />
                                <input
                                  type='text'
                                  id='f3'
                                  class='form-control form-control'
                                  value={firstName}
                                  onChange={e => setFirstName(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                          </div>{' '}
                          {/*Row Ends Here*/}
                          {/*Row Physical Address Starts Here*/}
                          <div
                            class='row'
                            hidden={
                              phoneNumber === '' || phoneNumber === undefined
                            }
                          >
                            <div class='col-lg-12 col-md-12 mb-0'>
                              <div class='md-form '>
                                <label for='f3' class=''>
                                  Physical Address
                                </label>
                                <br />
                                <input
                                  type='text'
                                  id='f3'
                                  class='form-control form-control'
                                  value={physicalAddress}
                                  onChange={e =>
                                    setPhysicalAddress(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          {/*Row physical Address Ends Here*/}
                          <button
                            disabled={
                              phoneNumber === '' ||
                              phoneNumber === undefined ||
                              emailKyc === undefined ||
                              emailKyc === '' ||
                              lastName === '' ||
                              lastName === undefined ||
                              firstName === '' ||
                              firstName === undefined ||
                              physicalAddress === '' ||
                              physicalAddress === undefined
                            }
                            class='btn btn-primary'
                            onClick={handleKyc}
                          >
                            Register <i class='w-fa fa fa-plus'></i>
                          </button>
                        </div>
                        {/*Modal Body Ends Here*/}
                      </div>
                    </div>
                    {/*<!-- Content -->*/}
                  </div>
                  {/* <!-- Modal: modalKyc .-->*/}
                  {/*<!-- Modal: modalAddItem  -->*/}
                  <div
                    class='modal fade'
                    id='modalAddItem'
                    tabindex='-1'
                    role='dialog'
                    aria-labelledby='myModalLabel'
                    aria-hidden='true'
                  >
                    <MDBModalDialog size='xl'>
                      {/*<!-- Content -->*/}
                      <div
                        class='modal-content'>
                        {/* <!-- Header -->*/}
                        <div class='modal-header ocean blue-gradient white-text '>
                          <h4 class=''>Add item(Product)</h4>
                          <button
                            type='button'
                            class='close waves-effect waves-light white-text'
                            data-dismiss='modal'
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>
                        {/*<!-- Body -->*/}
                        <div class='modal-body mb-0'>
                          <div class='row'>
                            
                      <div class='col-lg-12 col-md-12 mb-2 text-left'>
                        <div class='grey-text'>
                          <label>
                            Scan With Barcode Scanner <i class='red-text'></i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            value={scanWithBarcodeScanner}
                            onChange={e =>
                              setScanWithBarcodeScanner(e.target.value)
                            }
                          >
                            <option value=''>... Choose ...</option>
                                <option>Yes</option>
                                <option>No</option>
                          </select>
                        </div>
                      </div>
                      <div class='col-md-12 mb-0.00001' hidden={scanWithBarcodeScanner === '' || scanWithBarcodeScanner === undefined || scanWithBarcodeScanner === 'No' || scanWithBarcodeScanner === 'undefined'}>
                              <div class='md-form '>
                                <input
                                  type='text'
                                  id='f3'
                                  placeholder='Place Cursor Here'
                                  class='form-control form-control'
                                  value={barcodeValue}
                                  onChange={e =>
                                    setBarcodeValue(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {/*<!-- Grid row -->*/}
                          <div class='row' hidden = {scanWithBarcodeScanner === 'Yes'}>
                            {/*<!-- Grid column -->*/}
                            <div class='col-lg-12 col-md-12 mb-4 mt-2'>
                              {/*<!-- Name -->*/}
                              {/* <select
                                class='form-control'
                                isClearable={true}
                                isSearchable={true}
                                onChange={e => setItemID(e.target.value)}
                                required
                              >
                                <option value=''>Select Product</option>
                                {posProductDropDown.map(val => {
                                  return (
                                    <option
                                      value={val.stockID}
                                     
                                    >
                                      {val.product} (Quantity ={' '}
                                      {val.stock_quantity}) (Unit Price = K{' '}
                                      {val.product_unit_price})
                                    </option>
                                  )
                                })}
                              </select>
                              */}

                              <Select
                                options={posProductDropDown}
                                //isClearable={true}
                                isSearchable={true}
                                //isMulti={true}
                                required
                                //value={itemID} // this doesn't let me click options
                                //maxMenuHeight={150}
                                onChange={option =>
                                  HandelChangeItemDropDown(option)
                                } // this returns (option) => option.phaseText) as a string
                              />
                            </div>
                            {/*<!-- Grid column -->*/}
                          </div>{' '}
                          {/*Row Ends Here*/}
                          <div class='row'>
                            {' '}
                            {/*Row Starts Here*/}
                            {/*<!-- Grid column -->*/}
                            <div class='col-md-6 mb-4'>
                              <div class='md-form '>
                                <label for='f3' class=''>
                                  Quantity
                                </label>
                                <br />
                                <input
                                  type='number'
                                  id='f3'
                                  class='form-control form-control'
                                  value={itemQuantity}
                                  onChange={e =>
                                    setItemQuantity(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                            {/*<!-- Grid column -->*/}
                            <div class='col-md-6 mb-4 text-left'>
                              <label for='f3' class=''>
                                Insert Price
                              </label>
                              <input
                                type='number'
                                id='f3'
                                class='form-control form-control'
                                value={insertPrice}
                                onChange={handleChangeInsertPrice}
                                required
                              />
                            </div>
                          </div>{' '}
                          {/*Row Ends Here*/}
                          <button
                            disabled={
                              handleSubmitAddItem === 'Loading' || 
                              itemID === '' ||
                              itemID === undefined ||
                              itemQuantity === undefined ||
                              itemQuantity === '' ||
                              itemQuantity < 0 ||
                              itemQuantity === '0'
                            }
                            class='btn btn-primary'
                            onClick={handleAddItems}
                          >
                               <div class="spinner-border" role="status" hidden={handleSubmitAddItem === ''}>
</div>
                            Add <i class='w-fa fa fa-plus'></i>
                          </button>
                        </div>
                        {/*Modal Body Ends Here*/}
                      </div>
                      </MDBModalDialog>
                    {/*<!-- Content -->*/}
                  </div>
                  {/* <!-- Modal: modalAddItem .-->*/}
                  {/*<!-- Modal: modalEmailReceipt  -->*/}
                  <div
                    class='modal fade'
                    id='modalEmailReceipt'
                    tabindex='-1'
                    role='dialog'
                    aria-labelledby='myModalLabel'
                    aria-hidden='true'
                  >
                    <div class='modal-dialog cascading-modal' role='document'>
                      {/*<!-- Content -->*/}
                      <div class='modal-content'>
                        {/* <!-- Header -->*/}
                        <div class='modal-header light-blue darken-3 white-text '>
                          <h4 class=''>Send Receipt Via E - mail :</h4>
                          <button
                            type='button'
                            class='close waves-effect waves-light'
                            data-dismiss='modal'
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>
                        {/*<!-- Body -->*/}
                        <div class='modal-body mb-0'>
                          <form
                            //action="http://localhost:8000/server.php"
                            //action="http://localhost:8080/react/kanchy/src/server/tcpdf/posReceipt.php"
                            //action="http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php"
                            action='https://sims.app.kanchinnotech.nl/tcpdf/posReceipt.php'
                            method='post'
                            onSubmit={event => handleCustomerEmail(event)}
                          >
                            <div class='row'>
                              {' '}
                              {/*Row Starts Here*/}
                              {/*<!-- Grid column -->*/}
                              <div class='col-md-12 mb-0.00001'>
                                <div class='md-form '>
                                  <label for='f3' class=''>
                                    E - mail
                                  </label>
                                  <br />
                                  <input
                                    type='email'
                                    id='f3'
                                    class='form-control form-control'
                                    name='customerEmail'
                                    value={customerEmail}
                                    onChange={e =>
                                      setCustomerEmail(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <input
                                type='hidden'
                                name='receiptTableValues'
                                value={secondResponseReceiptPrintVar}
                                onChange={e =>
                                  setReceiptTableValues(e.target.value)
                                }
                              />
                              <input
                                type='hidden'
                                name='secondUserOrganisation'
                                value={secondUserOrganisation}
                                onChange={e =>
                                  setReceiptTableValues(e.target.value)
                                }
                              />
                              <input
                                type='hidden'
                                name='responseReceiptPrintTotalVar'
                                value={secondResponseReceiptPrintTotalVar}
                                onChange={e =>
                                  setReceiptTableValues(e.target.value)
                                }
                              />
                              <input
                                type='hidden'
                                name='organisation_address'
                                value={organisation_address}
                                onChange={e =>
                                  setReceiptTableValues(e.target.value)
                                }
                              />
                              <input
                                type='hidden'
                                name='organisation_phone'
                                value={organisation_phone}
                                onChange={e =>
                                  setReceiptTableValues(e.target.value)
                                }
                              />
                              <input
                                type='hidden'
                                name='organisation_email'
                                value={organisation_email}
                                onChange={e =>
                                  setReceiptTableValues(e.target.value)
                                }
                              />
                              <input
                                type='hidden'
                                name='dateToday'
                                value={dateToday}
                                onChange={e =>
                                  setReceiptTableValues(e.target.value)
                                }
                              />
                              <input
                                type='hidden'
                                name='timeNow'
                                value={timeNow}
                                onChange={e =>
                                  setReceiptTableValues(e.target.value)
                                }
                              />
                              <input
                                type='hidden'
                                name='transactionIDSecondToPrint'
                                value={transactionIDSecond}
                                onChange={e =>
                                  setReceiptTableValues(e.target.value)
                                }
                              />
                              {/*    
        :
  dateToday : dateToday,
  timeNow : timeNow,
  organisation_addressToPrint : organisation_address,
  organisation_phoneToPrint : organisation_phone,
  organisation_emailToPrint : organisation_email,
  receiptTableValues : secondResponseReceiptPrintVar,
  responseReceiptPrintTotalVar : secondResponseReceiptPrintTotalVar,
  transactionIDSecondToPrint : transactionIDSecond,*/}
                              {/*<!-- Grid column -->*/}
                            </div>{' '}
                            {/*Row Ends Here*/}
                            <button class='btn btn-primary'>
                              Send Email <i class='fa fa-paper-plane'></i>
                            </button>
                          </form>
                        </div>
                        {/*Modal Body Ends Here*/}
                      </div>
                    </div>
                    {/*<!-- Content -->*/}
                  </div>
                  {/* <!-- Modal: modalEmailReceipt .-->*/}
                  <div class='col-lg-9 col-md-1 '>
                    <button
                      hidden={
                        secondRolePosition != 'Cashier' ||
                        posTableVisibilty === '' ||
                        posTableVisibilty === null
                      }
                      class='btn btn-sm btn-red'
                      data-toggle='modal'
                      data-target='#modalManagerLogin'
                    >
                      Supervisor Login <i class='w-fa fa fa-sign-in-alt'></i>
                    </button>
                    <button
                      hidden={
                        secondRolePosition === 'Cashier' ||
                        posTableVisibilty === '' ||
                        posTableVisibilty === null
                      }
                      class='btn btn-sm btn-red white-text'
                      onClick={handleSupervisorLogout}
                    >
       <div class="spinner-border" role="status" hidden={handleSubmitBtnSupervisorLogout === ''}>
</div>
                      Supervisor Logout <i class='w-fa fa fa-sign-out-alt'></i>
                    </button>
                    {/*</div>
                  <div class='col-lg-6 col-md-1'>*/}
                    <button
                      class='btn btn-sm btn-success white-text'
                      data-toggle='modal'
                      data-target='#modalKyc'
                      hidden
                    >
                      KYC <i class='w-fa fa fa-users'></i>{' '}
                    </button>
                    {/*</div>
                  <div class='col-lg-4'>*/}
                    <button
                      class='btn btn-sm btn-brown white-text'
                      data-toggle='modal'
                      data-target='#modalAddItem'
                    >
                      Add Item <i class='w-fa fa fa-plus'></i>{' '}
                    </button>
                    {/*</div>
                  <div class='col-lg-4 col-md-1'>*/}
                    <button
                      hidden={transactionIDPrintReceipt === '0'}
                      class='btn btn-sm btn-success'
                      data-toggle='modal'
                      data-target='#modalEmailReceipt'
                    >
                      Email Receipt <i class='fas fa-paper-plane'></i>
                    </button>
                    <button
                      hidden={transactionIDPrintReceipt === '0'}
                      class='btn btn-sm btn-primary'
                      onClick={print}
                    >
                      Print Receipt <i class='fa fa-print'></i>
                    </button>
                  </div>
                  <div class='col-lg-3 col-md-1'>
                    <button
                      class='btn btn-sm btn-outline-primary text-right  h6'
                      style={{ cursor: 'default', right: '0' }}
                    >
                      Session ID:{POSTransactionID}
                    </button>
                  </div>
                  <br />
                  <table
                    id=''
                    className='table align-items-center table-striped table-bordered table-hover table-sm'
                    hidden={
                      posTableVisibilty === '' || posTableVisibilty === null
                    }
                  >
                    {' '}
                    {/*POS Table Starts Here*/}
                    <thead class='card-header text-uppercase white-text text-sm font-weight-bolder opacity-7 ps-2 ocean blue-gradient'>
                      <tr>
                        <th>Sn</th>
                        <th>PRODUCT</th>
                        <th>QUANTITY</th>
                        <th>UNIT PRICE</th>
                        <th>TOTAL PRODUCT PRICE</th>
                        {secondRolePosition != 'Cashier' ? (
                          <th>Delete</th>
                        ) : (
                          <input type='hidden' />
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {posTable.map((valPOSTable, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{valPOSTable.product}</td>
                            <td>{valPOSTable.itemQuantity}</td>
                            <td>{valPOSTable.posTable_product_unit_price}</td>
                            <td>{valPOSTable.total_price}</td>
                            {secondRolePosition != 'Cashier' ? (
                              <td>
                                <button
                                  title='Remove This Item'
                                  class='btn btn-danger'
                                  value={valPOSTable.posTableID}
                                  onClick={handlePOSTableDelete}
                                  disabled = {handleSubmitBtnDelete === 'Loading'}
                                >
                                  {' '}
                                  <FontAwesomeIcon icon={faTimes} />{' '}
                                </button>
                              </td>
                            ) : (
                              <input type='hidden' />
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      {posTableTotal.map(valPOSTableTotal => {
                        return valPOSTableTotal.total_prices != null ? (
                          <tr>
                            <td>
                              <div class='row'>
                                {' '}
                                {/*Row Starts Here*/}
                                {/*<!-- Grid column -->*/}
                                <div class='col-md-8 mb-4'>
                                  <label for='f3' class=''>
                                    Payment Method
                                  </label>
                                  <select
                                    class='custom-select d-block w-100'
                                    onChange={e =>
                                      setPaymentType(e.target.value)
                                    }
                                  >
                                    <option value=''>Choose</option>
                                    <option>Cash</option>
                                    <option
                                      hidden={
                                        businessCategory != 'Pharmacy Shop'
                                      }
                                    >
                                      NHIMA
                                    </option>
                                    <option>Mobile Money</option>
                                    <option>Debit Card</option>
                                    <option
                                      hidden={businessCategory != 'Phones Shop'}
                                    >
                                      Yellow
                                    </option>
                                    <option
                                      hidden={businessCategory != 'Phones Shop'}
                                    >
                                      Xtenda
                                    </option>
                                  </select>
                                </div>
                                {/*<!-- Grid column -->*/}
                                {/*<!-- Grid column -->*/}
                                <div class='col-md-4 mb-4'>
                                  {paymentType === 'Cash' ? (
                                    <div class=''>
                                      <label for='f3' class=''>
                                        Amount Received
                                      </label>
                                      <input
                                        type='number'
                                        id='f3'
                                        class='form-control form-control'
                                        value={amountReceived}
                                        onChange={handleChangeAmountReceived}
                                        /*onChange={e =>
                                        setAmountReceived(e.target.value)}*/

                                        required
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      <label for='f3' class=''>
                                        Amount Received
                                      </label>
                                      <input
                                        type='number'
                                        id='f3'
                                        class='form-control form-control'
                                        disabled
                                      />
                                    </>
                                  )}
                                </div>
                                {/*<!-- Grid column -->*/}
                              </div>{' '}
                              {/*Row Ends Here*/}
                            </td>
                            <td colspan='3' align='right'>
                              <h6>TOTAL : </h6>
                              {/*
                              {alert(
                                'Amount Received=' +
                                  amountReceived +
                                  ' Bill =' +
                                  valPOSTableTotal.total_prices +
                                  'Payment Type =' +
                                  paymentType
                              )}
                              */}
                              {amountReceived > 0 &&
                              amountReceived >= parseInt(total_prices) &&
                              paymentType === 'Cash' &&
                              paymentType != '' &&
                              paymentType != undefined ? (
                                <>
                                  <h6>MONEY RECEIVED : </h6> <h6>CHANGE : </h6>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td align='left'>
                              <h6>K{total_prices}</h6>

                              {amountReceived != 0 &&
                              parseInt(total_prices) <= amountReceived &&
                              paymentType === 'Cash' &&
                              paymentType != '' &&
                              paymentType != undefined ? (
                                <>
                                  <h6>K{amountReceived}</h6>{' '}
                                  <h6>K{amountReceived - total_prices}</h6>{' '}
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        ) : (
                          <h6></h6>
                        )
                      })}
                    </tfoot>
                  </table>{' '}
                  {/*POS Table Ends Here*/}
                  {/*KYC STARTS HERE*/}
                  <div
                    class='col-lg-12 col-md-12 mt-5'
                    hidden={
                      posTableVisibilty === '' || posTableVisibilty === null
                    }
                  >
                    <h4 class='form-header ocean blue-gradient white-text '>
                      KNOW YOUR CUSTOMER
                    </h4>
                    {/*<!-- Customer Referer Starts Here -->*/}
                    <div class='row'>
                      <div
                        class='col-lg-12 col-md-12 mb-0'
                        hidden={
                          referalOrNot === undefined ||
                          referalOrNot === '' ||
                          referalOrNot === 'No'
                        }
                      >
                        <h4 class='light-blue-text darken-3'>
                          REFEREE DETAILS
                        </h4>
                      </div>
                      {/*<!-- Grid column -->*/}
                      <div class={referalWidth}>
                        <div class='md-form '>
                          <label for='f3' class=''>
                            Customer Refered ?
                          </label>
                          <br /> <br />
                          <select
                            class='custom-select d-block w-100'
                            onChange={e => setReferalOrNot(e.target.value)}
                            value={referalOrNot}
                          >
                            <option value=''>..Choose..</option>
                            <option>No</option>
                            <option>Yes</option>
                          </select>
                        </div>
                      </div>

                      {/*<!-- Grid column -->*/}
                      <div
                        class='col-lg-2 col-md-12 mb-0'
                        hidden={
                          referalOrNot === undefined ||
                          referalOrNot === '' ||
                          referalOrNot === 'No'
                        }
                      >
                        <div class='md-form '>
                          <label for='f3' class=''>
                            Phone Number
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            id='f3'
                            class='form-control form-control'
                            value={phoneNumberReferal}
                            onChange={e =>
                              setPhoneNumberReferal(e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                      {/*<!-- Grid column -->*/}
                      {/*<!-- Grid column -->*/}
                      <div
                        class='col-lg-2 col-md-12 mb-0'
                        hidden={
                          referalOrNot === undefined ||
                          referalOrNot === '' ||
                          referalOrNot === 'No'
                        }
                      >
                        <div class='md-form '>
                          <label for='f3' class=''>
                            Email
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            id='f3'
                            class='form-control form-control'
                            value={emailKycReferal}
                            onChange={e => setEmailKycReferal(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                      {/*<!-- Grid column -->*/}
                      {/*<!-- Grid column -->*/}
                      <div
                        class='col-lg-2 col-md-12 mb-0'
                        hidden={
                          referalOrNot === undefined ||
                          referalOrNot === '' ||
                          referalOrNot === 'No'
                        }
                      >
                        <div class='md-form '>
                          <label for='f3' class=''>
                            Last Name
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            id='f3'
                            class='form-control form-control'
                            value={lastNameReferal}
                            onChange={e => setLastNameReferal(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                      {/*<!--/Grid column -->*/}
                      {/*<!-- Grid column -->*/}
                      <div
                        class='col-lg-2 col-md-12 mb-0'
                        hidden={
                          referalOrNot === undefined ||
                          referalOrNot === '' ||
                          referalOrNot === 'No'
                        }
                      >
                        <div class='md-form '>
                          <label for='f3' class=''>
                            First Name
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            id='f3'
                            class='form-control form-control'
                            value={firstNameReferal}
                            onChange={e => setFirstNameReferal(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                      {/*<!-- /Grid column -->*/}
                      {/*<!-- Grid column -->*/}
                      <div
                        class='col-lg-2 col-md-12 mb-0'
                        hidden={
                          referalOrNot === undefined ||
                          referalOrNot === '' ||
                          referalOrNot === 'No'
                        }
                      >
                        <div class='md-form '>
                          <label for='f3' class=''>
                            Physical Address
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            id='f3'
                            class='form-control form-control'
                            value={physicalAddressReferal}
                            onChange={e =>
                              setPhysicalAddressReferal(e.target.value)
                            }
                            disabled
                          />
                        </div>
                      </div>
                      {/*<!--/Grid column -->*/}
                    </div>
                    {/*<!-- Customer Referer Ends Here -->*/}
                    {/*<!-- Customer Data Collection Starts Here -->*/}
                    <div
                      class='row mt-5'
                      hidden={referalOrNot === undefined || referalOrNot === ''}
                    >
                      <div class='col-lg-12 col-md-12 mb-0'>
                        <h4 class='light-blue-text darken-3'>
                          CUSTOMER DETAILS
                        </h4>
                      </div>
                      {/*<!-- Grid column -->*/}
                      <div class={phoneKycWidth}>
                        <div class='md-form '>
                          <label for='f3' class=''>
                            Phone Number
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            id='f3'
                            class='form-control form-control'
                            value={phoneNumber}
                            onChange={e => setPhoneNumber(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      {/*<!-- Grid column -->*/}
                      {/*<!-- Grid column -->*/}
                      <div
                        class='col-lg-6 col-md-12 mb-0'
                        hidden={phoneNumber === '' || phoneNumber === undefined}
                      >
                        <div class='md-form '>
                          <label for='f3' class=''>
                            Email
                          </label>
                          <br /> <br />
                          <input
                            type='email'
                            id='f3'
                            class='form-control form-control'
                            value={emailKyc}
                            onChange={e => setEmailKyc(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      {/*<!-- Grid column -->*/}
                    </div>{' '}
                    {/*Row Ends Here*/}
                    <div
                      class='row'
                      hidden={
                        phoneNumber === '' ||
                        phoneNumber === undefined ||
                        referalOrNot === undefined ||
                        referalOrNot === ''
                      }
                    >
                      {' '}
                      {/*Row Starts Here*/}
                      {/*<!-- Grid column -->*/}
                      <div class='col-lg-6 col-md-12 mb-0'>
                        <div class='md-form '>
                          <label for='f3' class=''>
                            Last Name
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            id='f3'
                            class='form-control form-control'
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      {/*<!-- Grid column -->*/}
                      <div class='col-lg-6 col-md-12 mb-0'>
                        <div class='md-form '>
                          <label for='f3' class=''>
                            First Name
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            id='f3'
                            class='form-control form-control'
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>{' '}
                    {/*Row Ends Here*/}
                    {/*Row Physical Address Starts Here*/}
                    <div
                      class='row'
                      hidden={phoneNumber === '' || phoneNumber === undefined}
                    >
                      <div class='col-lg-12 col-md-12 mb-0'>
                        <div class='md-form '>
                          <label for='f3' class=''>
                            Physical Address
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            id='f3'
                            class='form-control form-control'
                            value={physicalAddress}
                            onChange={e => setPhysicalAddress(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/*Row physical Address Ends Here*/}
                    {paymentType != 'Cash' ? (
                      <button
                        disabled={
                          paymentType === '' || paymentType === undefined || handleSubmitBtnPay === 'Loading'
                        }
                        class='btn btn-primary'
                        onClick={handlePay}
                      >
                      <div class="spinner-border" role="status" hidden = {handleSubmitBtnPay === ''}>
          </div>
                        Check-Out
                      </button>
                    ) : (
                      <button
                        disabled={
                          handleSubmitBtnPay === 'Loading' || 
                          paymentType === '' ||
                          paymentType === undefined ||
                          parseInt(total_prices) > amountReceived
                        }
                        class='btn btn-primary'
                        onClick={handlePay}
                      >
                        <div class="spinner-border" role="status" hidden = {handleSubmitBtnPay === ''}>
          </div>
                        Check-Out Cash
                      </button>
                    )}
                  </div>
                  {/*KYC ENDS HERE*/}
                  {/*Printing Receipt Starts Here*/}
                  <iframe
                    id='ifmcontentstoprint'
                    style={{
                      height: '0px',
                      width: '0px',
                      position: 'absolute'
                    }}
                  ></iframe>
                  <div id='printarea' style={{ display: 'none'}}>
                    <table>
                    <tr>
                        <th  colspan='5'><center>{businessName}</center></th>
                      </tr>

                      <tr>
                        <th colspan='5'><center>{outlet}</center></th>
                      </tr>


                      <tr>
                        <th>Sn</th>
                        <th>Item</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Total</th>
                      </tr>

                      {receiptPrint.map((valReceiptPrint, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{valReceiptPrint.product}</td>
                            <td>{valReceiptPrint.unit_quantity}</td>
                            <td>{valReceiptPrint.product_unit_price}</td>
                            <td>{valReceiptPrint.product_total_price}</td>
                          </tr>
                        )
                      })}

                      {receiptPrintChange.map(valReceiptPrintTotal => {
                        return (
                          <tr>
                            <td></td>
                            <td colspan='3' align='right'>
                              <h4>TOTAL : </h4>
                              <h4>Amount Received : </h4>
                              <h4>Change : </h4>
                            </td>

                            <td>
                              <h4>K{valReceiptPrintTotal.sum_total_price}</h4>
                              <h4>K{valReceiptPrintTotal.amount_received}</h4>
                              <h4>K{valReceiptPrintTotal.customer_change}</h4>
                            </td>
                          </tr>
                        )
                      })}

                      <tr><td>Time</td><td> : </td><td colspan='3'>{timeNow}</td></tr>
                      <tr><td>Date</td><td> : </td><td colspan='3'>{dateToday}</td></tr>
                      <tr><td><strong>Cashier</strong></td><td>:</td><td colspan='3'>{cashier}</td></tr>
                    </table>
                  </div>
                  {/*Printing Receipt Ends Here*/}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  )
}

export default Pos
