import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

const UpdateProfile = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const businessNameRef = useRef()
  const errRef = useRef()

  //const [logo, setLogo] = useState('');
  //const [pacraCertificate, setPacraCertificate] = useState('');

  //const [employeeImage, setEmployeeImage] = useState('');
  //const [employeeThumbPrint, setEmployeeThumbPrint] = useState('');
  const [lastNameDB, setLastNameDB] = useState('')
  const [firstNameDB, setFirstNameDB] = useState('')
  //const [emailDB, setEmailDB] = useState('')
  //const [phoneDB, setPhoneDB] = useState('')

  const [Last_name, setLastName] = useState()
  const [first_name, setFirstName] = useState()
  const [ip, setIP] = useState('')
  const firstUserID = localStorage.getItem('userID')
  const userIDInitial = JSON.parse(firstUserID)
  const [userID, setUserID] = useState(userIDInitial)
  const [businessNameDropDown, setBusinessNameDropDown] = useState([])
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )
  const [userProfile, setUserProfile] = useState([])
  const [phoneDB, setPhoneDB] = useState('')
  const [validPhone, setValidPhone] = useState(false)
  const [phoneFocus, setPhoneFocus] = useState(false)

  const [emailDB, setEmailDB] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  //const [success, setSuccess ] = useState(false);

  const [employeeImage, setEmployeeImage] = useState()
  const [employeeImageFileName, setEmployeeImageFileName] = useState('')
  const [employeeThumbPrint, setEmployeeThumbPrintFile] = useState()
  const [employeeThumbPrintFileName, setEmployeeThumbPrintFileName] =
    useState('')

  useEffect(() => {
    // storing input name
    //localStorage.setItem("ruth", JSON.stringify(businessName));
  }, [])

  useEffect(() => {
    const result = PHONE_REGEX.test(phoneDB)
    setValidPhone(result)
  }, [phoneDB])

  useEffect(() => {
    const result = EMAIL_REGEX.test(emailDB)
    setValidEmail(result)
  }, [emailDB])

  useEffect(() => {
    setErrMsg('')
  }, [phoneDB, emailDB])

  /*useEffect( () => {
	alert("we are in drops");
},
[businessNameDropDown])*/

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
    setTimeout(() => {
      //console.log('IP = ' + res.data.IPv4)
    }, 3000)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  //useEffect(() => {
  Axios({
    method: 'get',
    url: `${server_app_api_php}/getUserProfile.php?userID=${userID}`,
    //url: `http://localhost:8080/react/kanchy/src/server/reactphp/getUserProfile.php?userID=${userID}`,
    //userID: userID,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(response => {
    setUserProfile(response.data)
    setTimeout(() => {
      //console.log('Email = ' + response.data.email)
    }, 2000)
  }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  //getUserProfile()
  //}, [])

  const firstUserOrganisation = localStorage.getItem('userOrganisation')
  const UserOrganisation = JSON.parse(firstUserOrganisation)

  const saveEmployeeImage = e => {
    setEmployeeImage(e.target.files[0])
    setEmployeeImageFileName(e.target.files[0].name)
  }

  const saveEmployeeThumbPrintFile = e => {
    setEmployeeThumbPrintFile(e.target.files[0])
    setEmployeeThumbPrintFileName(e.target.files[0].name)
  }

  //alert(email)

  const handleSubmit = async e => {
    e.preventDefault()
    var formData = new FormData(this)

    formData.append('employeeImage', employeeImage)
    formData.append('employeeImageFileName', employeeImageFileName)
    formData.append('lastName', lastNameDB)
    formData.append('firstName', firstNameDB)
    formData.append('phone', phoneDB)
    formData.append('email', emailDB)
    formData.append('userID', userID)
    formData.append('system_setup_IP', ip)

    Axios({
      method: 'post',
      url: `${server_app_api_php}/updateUserProfile.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/updateUserProfile.php',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
      })
      .catch(function (response) {
        //handle error
        alert('ERROR : Kindly Contact Your Adminitrator')
      })
  }

  ;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - Update User Profile -{' '}
        {systemSetupBranchIDStoreName}{' '}
      </title>
      <>
        <Headers />
      </>
      {/*<!-- Preloader 
      <div id='overlayer'></div>
      <div class='loader'>
        <div class='spinner-border' role='status'>
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div>-->*/}
      <main>
        <form method='POST'>
          <div class='container-fluid'>
            <div class='row  mt-5'>
              <div class='col-md-12 mb-2'>
                <div class='card'>
                  <div class='card-body' id='faults'>
                    {userProfile.map(val => {
                      return (
                        <>
                          <div id='divShowMessageFromAjax'></div>
                          <div class='form-header white-text ocean blue-gradient'>
                            {' '}
                            UPDATE USER PROFILE HERE{' '}
                          </div>
                          <input
                            type='hidden'
                            class='form-control'
                            id='ip'
                            value={userID}
                            onChange={e => setUserID(e.target.value)}
                          />
                          {/*Start Of Row 1*/}
                          <table class='' style={{ width: '100%' }}>
                            <tr>
                              <th>OLD</th> <th>NEW</th>
                            </tr>
                            <tr>
                              <td>
                                <div class=''>
                                  <div class='col-lg-12 col-md-6 mb-1'>
                                    <div class='md-form'>
                                      <img src='' alt='User Profile Photo' />
                                    </div>
                                  </div>
                                </div>
                              </td>{' '}
                              <td>
                                <div class=''>
                                  <div class='col-lg-12 col-md-6 mb-1'>
                                    <div class='md-form'>
                                      <label>
                                        Update Passport Size Photo{' '}
                                        <i class='red-text'></i>{' '}
                                      </label>
                                      <br />
                                      <br />
                                      <br />
                                      <input
                                        type='file'
                                        class='form-control'
                                        name='employeeImage'
                                        id='employeeImage'
                                        onChange={saveEmployeeImage}
                                        autoComplete='off'
                                        accept='image/*'
                                      />
                                    </div>
                                  </div>
                                </div>{' '}
                                {/*End Of Row 1*/}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class='col-lg-12 col-md-6 mb-1'>
                                  <div class='md-form'>
                                    <label>
                                      Last Name <i class='red-text'></i>{' '}
                                    </label>
                                    <br /> <br />
                                    <input
                                      type='text'
                                      class='form-control'
                                      name='lastName'
                                      id='lastName'
                                      onChange={e =>
                                        setLastName(e.target.value)
                                      }
                                      autoComplete='off'
                                      value={val.Last_name}
                                      disabled
                                      required
                                    />
                                  </div>
                                </div>
                              </td>{' '}
                              <td>
                                <div class='col-lg-12 col-md-6 mb-1'>
                                  <div class='md-form'>
                                    <label>
                                      Last Name <i class='red-text'></i>{' '}
                                    </label>
                                    <br /> <br />
                                    <input
                                      type='text'
                                      class='form-control'
                                      name='lastName'
                                      id='lastName'
                                      onChange={e =>
                                        setLastNameDB(e.target.value)
                                      }
                                      autoComplete='off'
                                      value={lastNameDB}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class='col-lg-12 col-md-6 mb-1'>
                                  <div class='md-form'>
                                    <label>
                                      First Name <i class='red-text'></i>{' '}
                                    </label>
                                    <br /> <br />
                                    <input
                                      type='text'
                                      class='form-control'
                                      name='firstName'
                                      id='firstName'
                                      onChange={e =>
                                        setFirstName(e.target.value)
                                      }
                                      autoComplete='off'
                                      value={val.first_name}
                                      disabled
                                      required
                                    />
                                  </div>
                                </div>
                              </td>{' '}
                              <td>
                                <div class='col-lg-12 col-md-6 mb-1'>
                                  <div class='md-form'>
                                    <label>
                                      First Name <i class='red-text'></i>{' '}
                                    </label>
                                    <br /> <br />
                                    <input
                                      type='text'
                                      class='form-control'
                                      name='firstName'
                                      id='firstName'
                                      onChange={e =>
                                        setFirstNameDB(e.target.value)
                                      }
                                      autoComplete='off'
                                      value={firstNameDB}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class='col-lg-12 col-md-6 mb-1'>
                                  <div class='md-form'>
                                    {' '}
                                    <label for='registerFullName'>
                                      Phone <i class='red-text'></i>
                                    </label>
                                    <br /> <br />
                                    <input
                                      type='text'
                                      class='form-control'
                                      name='phone'
                                      id='phone'
                                      autoComplete='off'
                                      value={val.phone}
                                      disabled
                                      required
                                    />
                                  </div>
                                </div>
                              </td>{' '}
                              <td>
                                <div class='col-lg-12 col-md-6 mb-1'>
                                  <div class='md-form'>
                                    {' '}
                                    <label for='registerFullName'>
                                      Phone <i class='red-text'></i>
                                      <span
                                        className={
                                          validPhone ? 'valid' : 'hide'
                                        }
                                      >
                                        {' '}
                                        <FontAwesomeIcon icon={faCheck} />
                                      </span>{' '}
                                      <span
                                        className={
                                          validPhone || !phoneDB
                                            ? 'hide'
                                            : 'invalid'
                                        }
                                      >
                                        {' '}
                                        <FontAwesomeIcon icon={faTimes} />
                                      </span>{' '}
                                    </label>
                                    <br /> <br />
                                    <input
                                      type='text'
                                      class='form-control'
                                      name='phone'
                                      id='phone'
                                      onChange={e => setPhoneDB(e.target.value)}
                                      autoComplete='off'
                                      value={phoneDB}
                                      required
                                      aria-invalid={
                                        validPhone ? 'false' : 'true'
                                      }
                                      aria-describedby='phonenote'
                                      onFocus={() => setPhoneFocus(true)}
                                      onBlur={() => setPhoneFocus(false)}
                                    />
                                    <p
                                      id='phonenote'
                                      className={
                                        phoneFocus && phoneDB && !validPhone
                                          ? 'instructions'
                                          : 'offscreen'
                                      }
                                    >
                                      <FontAwesomeIcon icon={faInfoCircle} />
                                      there should be 10 or more, but less than
                                      14 numerical values. <br />
                                      You can also add + followed by your
                                      country or area phone number code.
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class='col-lg-12 col-md-6 mb-1'>
                                  <div class='md-form'>
                                    <label>
                                      Email <i class='red-text'></i>
                                    </label>
                                    <br /> <br />
                                    <input
                                      type='text'
                                      class='form-control'
                                      name='email'
                                      id='email'
                                      autoComplete='off'
                                      value={val.email}
                                      disabled
                                      required
                                    />
                                  </div>
                                </div>
                              </td>{' '}
                              <td>
                                <div class='col-lg-12 col-md-6 mb-1'>
                                  <div class='md-form'>
                                    <label>
                                      Email <i class='red-text'></i>
                                      <span
                                        className={
                                          validEmail ? 'valid' : 'hide'
                                        }
                                      >
                                        <FontAwesomeIcon icon={faCheck} />
                                      </span>
                                      <span
                                        className={
                                          validEmail || !emailDB
                                            ? 'hide'
                                            : 'invalid'
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTimes} />
                                      </span>
                                    </label>
                                    <br /> <br />
                                    <input
                                      type='text'
                                      class='form-control'
                                      name='email'
                                      id='email'
                                      onChange={e => setEmailDB(e.target.value)}
                                      autoComplete='off'
                                      value={emailDB}
                                      required
                                      aria-invalid={
                                        validEmail ? 'false' : 'true'
                                      }
                                      aria-describedby='emailnote'
                                      onFocus={() => setEmailFocus(true)}
                                      onBlur={() => setEmailFocus(false)}
                                    />
                                    <p
                                      id='emailnote'
                                      className={
                                        emailFocus && emailDB && !validEmail
                                          ? 'instructions'
                                          : 'offscreen'
                                      }
                                    >
                                      <FontAwesomeIcon icon={faInfoCircle} />
                                      4 to 24 characters. <br />
                                      Let It Be A Valid Email Address.
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>{' '}
                            {/*End Of Row 3*/}
                            <tr>
                              {' '}
                              <td colspan='2'>
                                <button
                                  onClick={handleSubmit}
                                  class='btn btn-primary'
                                  disabled={
                                    lastNameDB === '' &&
                                    firstNameDB === '' &&
                                    employeeImageFileName === '' &&
                                    !validPhone &&
                                    !validEmail
                                  }
                                >
                                  Update
                                </button>
                              </td>
                            </tr>
                          </table>
                          <br /> <br /> <br />
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </>
  )
}

export default UpdateProfile
