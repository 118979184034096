import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

const RegisterUsers = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const businessNameRef = useRef()
  const errRef = useRef()

  //const [logo, setLogo] = useState('');
  //const [pacraCertificate, setPacraCertificate] = useState('');

  //const [employeeImage, setEmployeeImage] = useState('');
  //const [employeeThumbPrint, setEmployeeThumbPrint] = useState('');
  const [businessName, setBusinessName] = useState('')
  const [businessBranchName, setBusinessBranchName] = useState('')
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [position, setPosition] = useState('')
  const [role, setRole] = useState('')
  const [ip, setIP] = useState('')
  const firstUserID = localStorage.getItem('userID')
  const userIDInitial = JSON.parse(firstUserID)
  const [userID, setUserID] = useState(userIDInitial)
  const [businessNameDropDown, setBusinessNameDropDown] = useState([])
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )
  const [phone, setPhone] = useState('')
  const [validPhone, setValidPhone] = useState(false)
  const [phoneFocus, setPhoneFocus] = useState(false)

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  //const [success, setSuccess ] = useState(false);

  const [employeeImage, setEmployeeImage] = useState()
  const [employeeImageFileName, setEmployeeImageFileName] = useState('')
  const [employeeThumbPrint, setEmployeeThumbPrintFile] = useState()
  const [employeeThumbPrintFileName, setEmployeeThumbPrintFileName] =
    useState('')
let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  useEffect(() => {
    // storing input name
    //localStorage.setItem("ruth", JSON.stringify(businessName));
  }, [])

  useEffect(() => {
    businessNameRef.current.focus()
  }, [])

  useEffect(() => {
    const result = PHONE_REGEX.test(phone)
    setValidPhone(result)
  }, [phone])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  useEffect(() => {
    setErrMsg('')
  }, [phone, email])

  /*useEffect( () => {
	alert("we are in drops");
},
[businessNameDropDown])*/

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)
  //alert("id = "+systemSetupIDInitial);

  useEffect(() => {
    var formDataViewBusinesses = new FormData(this)
    formDataViewBusinesses.append('apiType', 'getViewBusinesses')
    formDataViewBusinesses.append('businessNameID', systemSetupIDInitial)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewBusinesses,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      /*Axios.post(`${server_app_api}/api/getBusinessNameDropDown`, {
      businessNameID: systemSetupIDInitial
    })*/
      .then(response => {
        setBusinessNameDropDown(response.data)
      }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getBusinessBranchNameDropDown`, {
      businessNameID: businessName
    })*/
    var formDataViewBusinessBranchNames = new FormData(this)
    formDataViewBusinessBranchNames.append(
      'apiType',
      'getBusinessBranchNameDropDown'
    )
    formDataViewBusinessBranchNames.append(
      'businessNameID',
      systemSetupIDInitial
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewBusinessBranchNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessBranchNameDropDown(response.data)
    })
    //.catch(err => alert('ERROR : Kindly Contact Your Administrator'))
  }, [businessName])

  /*setBusinessName = async (e) => 
            {
alert("changed business name value");
            }*/
  const firstUserOrganisation = localStorage.getItem('userOrganisation')
  const UserOrganisation = JSON.parse(firstUserOrganisation)

  const saveEmployeeImage = e => {
    setEmployeeImage(e.target.files[0])
    setEmployeeImageFileName(e.target.files[0].name)
  }

  const saveEmployeeThumbPrintFile = e => {
    setEmployeeThumbPrintFile(e.target.files[0])
    setEmployeeThumbPrintFileName(e.target.files[0].name)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setHandleSubmitBtn('Loading');
    var formData = new FormData(this)

    formData.append('employeeImage', employeeImage)
    formData.append('employeeImageFileName', employeeImageFileName)
    formData.append('employeeThumbPrint', employeeThumbPrint)
    formData.append('employeeThumbPrintFileName', employeeThumbPrintFileName)
    formData.append('businessName', businessName)
    formData.append('businessBranchName', businessBranchName)
    formData.append('UserOrganisation', UserOrganisation)
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('position', position)
    formData.append('role', role)
    formData.append('userID', userID)
    formData.append('system_setup_IP', ip)

    Axios({
      method: 'post',
      url: `${server_app_api_php}/registerUsers.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/registerUsers.php',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
        setHandleSubmitBtn('')
        if(response.data != 'Failed To Register ... Duplicate Entry !'){
        setEmployeeImage('')
        setEmployeeImageFileName('')
        setEmployeeThumbPrintFile('');
        setEmployeeThumbPrintFileName('')
        setBusinessName('')
        setBusinessBranchName('')
        setLastName('')
        setFirstName('')
        setPhone('')
        setEmail('')
        setPosition('')
        setRole('')
      }
      })
      .catch(function (response) {
        //handle error
        alert('ERROR : Kindly Contact Your Adminitrator')
        setHandleSubmitBtn('')
      })

    //alert("businessName = "+businessName);

    /*Axios.post(`${server_app_api}/api/insertRegisterUser`, {
        
        employeeImage : employeeImage,
        employeeThumbPrint : employeeThumbPrint,
        businessName : businessName,
        businessBranchName : businessBranchName,
        UserOrganisation : UserOrganisation,
		lastName : lastName,
        firstName : firstName,
		phone : phone,
		email : email,
        position : position,
        role : role,
        userID : userID,
		system_setup_IP : ip,
		
    }).then((res) => {	alert(res.data);
  
	  })  
	  .catch((err) => alert("ERROR : Kindly Contact Your Adminitrator"));*/
  }

  ;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()

  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - Register Business Users / Employees -{' '}
        {systemSetupBranchIDStoreName}
      </title>
      <>
        <Headers />
      </>
      {/*<!-- Preloader 
      <div id='overlayer'></div>
      <div class='loader'>
        <div class='spinner-border' role='status'>
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div>-->*/}
      <main>
        <form method='POST'>
          <div class='container-fluid'>
            <div class='row mt-5'>
              <div class='col-md-12 mb-2'>
                <div class='card'>
                  <div class='card-body' id='faults'>
                    <div id='divShowMessageFromAjax'></div>
                    <div class='form-header white-text ocean blue-gradient'>
                      {' '}
                      REGISTER USERS HERE{' '}
                    </div>
                    <input
                      type='hidden'
                      class='form-control'
                      id='ip'
                      value={userID}
                      onChange={e => setUserID(e.target.value)}
                    />
                    {/*Start Of Row 1*/}
                    <div class='row'>
                      <div class='col-lg-6 col-md-12 mb-1 text-left'>
                        <div class='grey-text'>
                          <label>
                            Passport Size Photo <i class='red-text'></i>{' '}
                          </label>
                          <input
                            type='file'
                            class='form-control'
                            name='employeeImage'
                            id='employeeImage'
                            onChange={saveEmployeeImage}
                            autoComplete='off'
                            accept='image/*'
                          />
                        </div>
                      </div>

                      <div class='col-lg-6 col-md-12 mb-1 text-left'>
                        <div class='grey-text'>
                          <label>
                            Employee ThumbPrint <i class='red-text'></i>{' '}
                          </label>
                          <input
                            type='file'
                            class='form-control'
                            name='employeeThumbPrint'
                            id='employeeThumbPrint'
                            onChange={saveEmployeeThumbPrintFile}
                            autoComplete='off'
                          />
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 1*/}
                    {/*Start Of Row 1*/}
                    <div class='row'>
                      <div class='col-lg-6 col-md-12 mb-1 text-left'>
                        <div class='grey-text'>
                          <label>
                            Business Name <i class='red-text'>*</i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            name='businessName'
                            id='businessName'
                            onChange={e => setBusinessName(e.target.value)}
                            autoComplete='off'
                            value={businessName}
                            ref={businessNameRef}
                          >
                            <option value=''>Choose Business Name ..</option>
                            {businessNameDropDown.map(val => {
                              return (
                                <option value={val.system_setupID}>
                                  {val.organisation}{' '}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>

                      <div class='col-lg-6 col-md-12 mb-1 text-left'>
                        <div class='grey-text'>
                          <label>
                            Business Branch Name <i class='red-text'>*</i>{' '}
                          </label>
                          <select
                            disabled={businessName === ''}
                            class='custom-select d-block w-100'
                            name='businessBranchName'
                            id='businessBranchName'
                            onChange={e =>
                              setBusinessBranchName(e.target.value)
                            }
                            autoComplete='off'
                            value={businessBranchName}
                          >
                            <option value=''>
                              Choose Business Branch Name
                            </option>
                            {businessBranchNameDropDown.map(val => {
                              return (
                                <option value={val.system_setup_branchID}>
                                  {val.branch_name}{' '}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 1*/}
                    <div class='row'>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label>
                            Last Name <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            name='lastName'
                            id='lastName'
                            onChange={e => setLastName(e.target.value)}
                            autoComplete='off'
                            value={lastName}
                            required
                          />
                        </div>
                      </div>

                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label>
                            First Name <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            name='firstName'
                            id='firstName'
                            onChange={e => setFirstName(e.target.value)}
                            autoComplete='off'
                            value={firstName}
                            required
                          />
                        </div>
                      </div>

                      <div class='col-lg-2 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Phone <i class='red-text'>*</i>
                            <span className={validPhone ? 'valid' : 'hide'}>
                              {' '}
                              <FontAwesomeIcon icon={faCheck} />
                            </span>{' '}
                            <span
                              className={
                                validPhone || !phone ? 'hide' : 'invalid'
                              }
                            >
                              {' '}
                              <FontAwesomeIcon icon={faTimes} />
                            </span>{' '}
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            name='phone'
                            id='phone'
                            onChange={e => setPhone(e.target.value)}
                            autoComplete='off'
                            value={phone}
                            required
                            aria-invalid={validPhone ? 'false' : 'true'}
                            aria-describedby='phonenote'
                            onFocus={() => setPhoneFocus(true)}
                            onBlur={() => setPhoneFocus(false)}
                          />
                          <p
                            id='phonenote'
                            className={
                              phoneFocus && phone && !validPhone
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            there should be 10 or more, but less than 14
                            numerical values. <br />
                            You can also add + followed by your country or area
                            phone number code.
                          </p>
                        </div>
                      </div>

                      <div class='col-lg-4 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label>
                            Email <i class='red-text'>*</i>
                            <span className={validEmail ? 'valid' : 'hide'}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span
                              className={
                                validEmail || !email ? 'hide' : 'invalid'
                              }
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </span>
                          </label>
                          <br />

                          <input
                            type='text'
                            class='form-control'
                            name='email'
                            id='email'
                            onChange={e => setEmail(e.target.value)}
                            autoComplete='off'
                            value={email}
                            required
                            aria-invalid={validEmail ? 'false' : 'true'}
                            aria-describedby='emailnote'
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          />
                          <p
                            id='emailnote'
                            className={
                              emailFocus && email && !validEmail
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            4 to 24 characters. <br />
                            Let It Be A Valid Email Address.
                          </p>
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 3*/}
                    {/*Start Of Row 1*/}
                    <div class='row'>
                      <div class='col-lg-6 col-md-12 mb-1 text-left'>
                        <div class='grey-text'>
                          <label>
                            Position <i class='red-text'>*</i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            name='position'
                            id='position'
                            onChange={e => setPosition(e.target.value)}
                            autoComplete='off'
                            value={position}
                          >
                            <option value=''>Choose Position ..</option>
                            <option>Manager</option>
                            <option>Cashier</option>
                            <option>Auditor</option>
                          </select>
                        </div>
                      </div>

                      <div class='col-lg-6 col-md-12 mb-1 text-left'>
                        <div class='grey-text'>
                          <label>
                            User Role <i class='red-text'>*</i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            name='role'
                            id='role'
                            onChange={e => setRole(e.target.value)}
                            autoComplete='off'
                            value={role}
                          >
                            <option value=''>Choose User Role</option>
                            <option>System Administrator</option>
                            <option>User</option>
                          </select>
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 1*/}
                    <p>
                      <span class='red-text'>* Required</span>{' '}
                      <span>- Do not leave Blank</span>
                    </p>
                    <hr class='mb-1' />
                    <button
                      onClick={handleSubmit}
                      class='btn btn-primary'
                      disabled={
                        businessName === '' ||
                        businessBranchName === '' ||
                        lastName === '' ||
                        firstName === '' ||
                        position === '' ||
                        role === '' ||
                        !validPhone ||
                        !validEmail ||
                        handleSubmitBtn === 'Loading'
                      }
                    >
                    <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
                      Submit
                    </button>
                    <br /> <br /> <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </>
  )
}

export default RegisterUsers
