
import {useRef, useState, useEffect, useContext} from 'react';

const Main = () => {
    return(
	<div>
  {/* Main Navigation */}
  <header>

  {/* Sidebar navigation */}
    <div id="slide-out" class="side-nav sn-bg-4 fixed">
      <ul class="custom-scrollbar">

       {/* Logo */}
        <li class="logo-sn waves-effect py-3">
          <div class="text-center">
            <a href="#" class="pl-0"><img src="https://mdbootstrap.com/img/logo/mdb-transaprent-noshadows.png" /></a>
          </div>
        </li>

       {/* Search Form */}
        <li>
          <form class="search-form" role="search">
            <div class="md-form mt-0 waves-light">
              <input type="text" class="form-control py-2" placeholder="Search" />
            </div>
          </form>
        </li>

        {/* Side navigation links */}
        <li>
          <ul class="collapsible collapsible-accordion">

            <li>
              <a class="collapsible-header waves-effect arrow-r">
                <i class="w-fa fas fa-tachometer-alt"></i>Dashboards<i class="fas fa-angle-down rotate-icon"></i>
              </a>
              <div class="collapsible-body">
                <ul>
                  <li>
                    <a href="../dashboards/v-1.html" class="waves-effect">Version 1</a>
                  </li>
                  <li>
                    <a href="../dashboards/v-2.html" class="waves-effect">Version 2</a>
                  </li>
                  <li>
                    <a href="../dashboards/v-3.html" class="waves-effect">Version 3</a>
                  </li>
                  <li>
                    <a href="../dashboards/v-4.html" class="waves-effect">Version 4</a>
                  </li>
                  <li>
                    <a href="../dashboards/v-5.html" class="waves-effect">Version 5</a>
                  </li>
                  <li>
                    <a href="../dashboards/v-6.html" class="waves-effect">Version 6</a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a class="collapsible-header waves-effect arrow-r">
                <i class="w-fa fas fa-image"></i>Pages<i class="fas fa-angle-down rotate-icon"></i>
              </a>
              <div class="collapsible-body">
                <ul>
                  <li>
                    <a href="../pages/login.html" class="waves-effect">Login</a>
                  </li>
                  <li>
                    <a href="../pages/register.html" class="waves-effect">Register</a>
                  </li>
                  <li>
                    <a href="../pages/pricing.html" class="waves-effect">Pricing</a>
                  </li>
                  <li>
                    <a href="../pages/about.html" class="waves-effect">About us</a>
                  </li>
                  <li>
                    <a href="../pages/single.html" class="waves-effect">Single post</a>
                  </li>
                  <li>
                    <a href="../pages/post.html" class="waves-effect">Post listing</a>
                  </li>
                  <li>
                    <a href="../pages/landing.html" class="waves-effect">Landing page</a>
                  </li>
                  <li>
                    <a href="../pages/customers.html" class="waves-effect">Customers</a>
                  </li>
                  <li>
                    <a href="../pages/invoice.html" class="waves-effect">Invoice</a>
                  </li>
                  <li>
                    <a href="../pages/page-creator.html" class="waves-effect">Page Creator</a>
                  </li>
                  <li>
                    <a href="../pages/support.html" class="waves-effect">Support</a>
                  </li>
                  <li>
                    <a href="../pages/chat.html" class="waves-effect">Chat</a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a class="collapsible-header waves-effect arrow-r">
                <i class="w-fa fas fa-user"></i>Profile<i class="fas fa-angle-down rotate-icon"></i>
              </a>
              <div class="collapsible-body">
                <ul>
                  <li>
                    <a href="../profile/basic-1.html" class="waves-effect">Basic 1</a>
                  </li>
                  <li>
                    <a href="../profile/basic-2.html" class="waves-effect">Basic 2</a>
                  </li>
                  <li>
                    <a href="../profile/extended.html" class="waves-effect">Extended</a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a class="collapsible-header waves-effect arrow-r">
                <i class="w-fa fab fa-css3"></i>CSS<i class="fas fa-angle-down rotate-icon"></i>
              </a>
              <div class="collapsible-body">
                <ul>
                  <li>
                    <a href="../css/grid.html" class="waves-effect">Grid system</a>
                  </li>
                  <li>
                    <a href="../css/media.html" class="waves-effect">Media object</a>
                  </li>
                  <li>
                    <a href="../css/utilities.html" class="waves-effect">Utilities / helpers</a>
                  </li>
                  <li>
                    <a href="../css/code.html" class="waves-effect">Code</a>
                  </li>
                  <li>
                    <a href="../css/icons.html" class="waves-effect">Icons</a>
                  </li>
                  <li>
                    <a href="../css/images.html" class="waves-effect">Images</a>
                  </li>
                  <li>
                    <a href="../css/typography.html" class="waves-effect">Typography</a>
                  </li>
                  <li>
                    <a href="../css/animations.html" class="waves-effect">Animations</a>
                  </li>
                  <li>
                    <a href="../css/colors.html" class="waves-effect">Colors</a>
                  </li>
                  <li>
                    <a href="../css/hover.html" class="waves-effect">Hover effects</a>
                  </li>
                  <li>
                    <a href="../css/masks.html" class="waves-effect">Masks</a>
                  </li>
                  <li>
                    <a href="../css/shadows.html" class="waves-effect">Shadows</a>
                  </li>
                  <li>
                    <a href="../css/skins.html" class="waves-effect">Skins</a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a class="collapsible-header waves-effect arrow-r">
                <i class="w-fa fas fa-th"></i>Components<i class="fas fa-angle-down rotate-icon"></i>
              </a>
              <div class="collapsible-body">
                <ul>
                  <li>
                    <a href="../components/buttons.html" class="waves-effect">Buttons</a>
                  </li>
                  <li>
                    <a href="../components/cards.html" class="waves-effect">Cards</a>
                  </li>
                  <li>
                    <a href="../components/collapse.html" class="waves-effect">Collapse</a>
                  </li>
                  <li>
                    <a href="../components/date.html" class="waves-effect">Date picker</a>
                  </li>
                  <li>
                    <a href="../components/list.html" class="waves-effect">List group</a>
                  </li>
                  <li>
                    <a href="../components/panels.html" class="waves-effect">Panels</a>
                  </li>
                  <li>
                    <a href="../components/pagination.html" class="waves-effect">Pagination</a>
                  </li>
                  <li>
                    <a href="../components/popovers.html" class="waves-effect">Popovers</a>
                  </li>
                  <li>
                    <a href="../components/progress.html" class="waves-effect">Progress bars</a>
                  </li>
                  <li>
                    <a href="../components/stepper.html" class="waves-effect">Stepper</a>
                  </li>
                  <li>
                    <a href="../components/tabs.html" class="waves-effect">Tabs & pills</a>
                  </li>
                  <li>
                    <a href="../components/tags.html" class="waves-effect">Tags, labels & badges</a>
                  </li>
                  <li>
                    <a href="../components/time.html" class="waves-effect">Time picker</a>
                  </li>
                  <li>
                    <a href="../components/tooltips.html" class="waves-effect">Tooltips</a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a class="collapsible-header waves-effect arrow-r">
                <i class="w-fa far fa-check-square"></i>Forms<i class="fas fa-angle-down rotate-icon"></i>
              </a>
              <div class="collapsible-body">
                <ul>
                  <li>
                    <a href="../forms/basic.html" class="waves-effect">Basic</a>
                  </li>
                  <li>
                    <a href="../forms/extended.html" class="waves-effect">Extended</a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a class="collapsible-header waves-effect arrow-r">
                <i class="w-fa fas fa-table"></i>Tables<i class="fas fa-angle-down rotate-icon"></i>
              </a>
              <div class="collapsible-body">
                <ul>
                  <li>
                    <a href="../tables/basic.html" class="waves-effect">Basic</a>
                  </li>
                  <li>
                    <a href="../tables/extended.html" class="waves-effect">Extended</a>
                  </li>
                  <li>
                    <a href="../tables/datatables.html" class="waves-effect">DataTables.net</a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a class="collapsible-header waves-effect arrow-r">
                <i class="w-fa fas fa-map"></i>Maps<i class="fas fa-angle-down rotate-icon"></i>
              </a>
              <div class="collapsible-body">
                <ul>
                  <li>
                    <a href="../maps/google.html" class="waves-effect">Google Maps</a>
                  </li>
                  <li>
                    <a href="../maps/full.html" class="waves-effect">Full screen map</a>
                  </li>
                  <li>
                    <a href="../maps/vector.html" class="waves-effect">Vector world map</a>
                  </li>
                </ul>
              </div>
            </li>

            {/* Simple link */}
            <li>
              <a href="../alerts/alerts.html" class="collapsible-header waves-effect"><i
                  class="w-fa far fa-bell"></i>Alerts</a>
            </li>
            <li>
              <a href="../modals/modals.html" class="collapsible-header waves-effect"><i
                  class="w-fa fas fa-bolt"></i>Modals</a>
            </li>
            <li>
              <a href="../charts/charts.html" class="collapsible-header waves-effect"><i
                  class="w-fa fas fa-chart-pie"></i>Charts</a>
            </li>
            <li>
              <a href="../calendar/calendar.html" class="collapsible-header waves-effect"><i
                  class="w-fa far fa-calendar-check"></i>Calendar</a>
            </li>
            <li>
              <a href="../sections/sections.html" class="collapsible-header waves-effect"><i
                  class="w-fa fas fa-th-large"></i>Sections</a>
            </li>

          </ul>
        </li>
        {/* Side navigation links */}

      </ul>
     <div class="sidenav-bg mask-strong"></div>
    </div>
    {/* Sidebar navigation */}

    {/* Navbar */}
	
    <nav class="navbar fixed-top navbar-expand-lg scrolling-navbar double-nav">

      {/* SideNav slide-out button */}
      <div class="float-left">
        <a href="#" data-activates="slide-out" class="button-collapse"><i class="fas fa-bars"></i></a>
      </div>

      {/* Breadcrumb */}
      <div class="breadcrumb-dn mr-auto">
        <p>KANCHY TECHNOLOGIES BUSINESS MANAGEMENT SYSTEM </p>
      </div>

  <div class="d-flex change-mode">

        <div class="ml-auto mr-3 change-mode-wrapper">
          <button class="btn btn-outline-black btn-sm" id="dark-mode">Change Mode</button>
        </div>

        {/* Navbar links */}
        <ul class="nav navbar-nav nav-flex-icons ml-auto">

		{/* Dropdown */}
          <li class="nav-item dropdown notifications-nav">
            <a class="nav-link dropdown-toggle waves-effect" id="navbarDropdownMenuLink" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="badge red">3</span> <i class="fas fa-bell"></i>
              <span class="d-none d-md-inline-block">Notifications</span>
            </a>
            <div class="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" href="#">
                <i class="far fa-money-bill-alt mr-2" aria-hidden="true"></i>
                <span>New order received</span>
                <span class="float-right"><i class="far fa-clock" aria-hidden="true"></i> 13 min</span>
              </a>
              <a class="dropdown-item" href="#">
                <i class="far fa-money-bill-alt mr-2" aria-hidden="true"></i>
                <span>New order received</span>
                <span class="float-right"><i class="far fa-clock" aria-hidden="true"></i> 33 min</span>
              </a>
              <a class="dropdown-item" href="#">
                <i class="fas fa-chart-line mr-2" aria-hidden="true"></i>
                <span>Your campaign is about to end</span>
                <span class="float-right"><i class="far fa-clock" aria-hidden="true"></i> 53 min</span>
              </a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link waves-effect"><i class="fas fa-envelope"></i> <span
                class="clearfix d-none d-sm-inline-block">Contact</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link waves-effect"><i class="far fa-comments"></i> <span
                class="clearfix d-none d-sm-inline-block">Support</span></a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle waves-effect" href="#" id="userDropdown" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-user"></i> <span class="clearfix d-none d-sm-inline-block">Profile</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
              <a class="dropdown-item" href="Home">Home</a>
              <a class="dropdown-item" href="#">Log Out</a>
              <a class="dropdown-item" href="#">My account</a>
            </div>
          </li>

        </ul>

  </div>
    {/* Navbar */}
    </nav>

  </header>
  {/* Main Navigation */}

 {/* Main layout */}
  <main>
<div class="container-fluid">
    
	
      {/* Section: Button icon */}
      <section>

        {/* Grid row */}
        <div class="row">

          {/* Grid column */}
          <div class="col-xl-3 col-md-6 mb-4">

            {/* Card */}
            <div class="card">

              {/* Card Data */}
              <div class="row mt-3">

                <div class="col-md-5 col-5 text-left pl-4">

                  <a type="button" class="btn-floating btn-lg primary-color ml-4"><i class="far fa-eye"
                      aria-hidden="true"></i></a>

                </div>

                <div class="col-md-7 col-7 text-right pr-5">

                  <h5 class="ml-4 mt-4 mb-2 font-weight-bold">4,567 </h5>

                  <p class="font-small grey-text">Unique Visitors</p>
                </div>

              </div>
              {/* Card Data */}

              {/*Card content */}
              <div class="row my-3">

                <div class="col-md-7 col-7 text-left pl-4">

                  <p class="font-small font-up ml-4 font-weight-bold">Last month</p>

                </div>

                <div class="col-md-5 col-5 text-right pr-5">

                  <p class="font-small grey-text">145,567</p>
                </div>

              </div>
              {/* Card content */}

            </div>
            {/* Card */}

          </div>
          {/* Grid column */}

          {/* Grid column */}
          <div class="col-xl-3 col-md-6 mb-4">

            {/* Card */}
            <div class="card">

              {/* Card Data */}
              <div class="row mt-3">

                <div class="col-md-5 col-5 text-left pl-4">

                  <a type="button" class="btn-floating btn-lg warning-color ml-4"><i class="fas fa-user"
                      aria-hidden="true"></i></a>

                </div>

                <div class="col-md-7 col-7 text-right pr-5">

                  <h5 class="ml-4 mt-4 mb-2 font-weight-bold">4,567 </h5>
                  <p class="font-small grey-text">New Users</p>

                </div>

              </div>
             {/* Card Data */}

              {/* Card content */}
              <div class="row my-3">

                <div class="col-md-7 col-7 text-left pl-4">
                  <p class="font-small font-up ml-4 font-weight-bold">Last month</p>
                </div>

                <div class="col-md-5 col-5 text-right pr-5">
                  <p class="font-small grey-text">145,567</p>
                </div>

              </div>
              {/* Card content */}

            </div>
            {/* Card */}

          </div>
          {/* Grid column */}

          {/* Grid column */}
          <div class="col-xl-3 col-md-6 mb-4">

            {/* Card */}
            <div class="card">

			{/* Card Data */}
              <div class="row mt-3">

                <div class="col-md-5 col-5 text-left pl-4">
                  <a type="button" class="btn-floating btn-lg light-blue lighten-1 ml-4"><i class="fas fa-dollar-sign"
                      aria-hidden="true"></i></a>
                </div>

                <div class="col-md-7 col-7 text-right pr-5">
                  <h5 class="ml-4 mt-4 mb-2 font-weight-bold">4,567 </h5>
                  <p class="font-small grey-text">Total Sales</p>
                </div>

              </div>
              {/* Card Data */}

              {/* Card content */}
              <div class="row my-3">

                {/* Grid column */}
                <div class="col-md-7 col-7 text-left pl-4">
                  <p class="font-small font-up ml-4 font-weight-bold">Last month</p>
                </div>
                {/* Grid column */}

                {/* Grid column */}
                <div class="col-md-5 col-5 text-right pr-5">
                  <p class="font-small grey-text">145,567</p>
                </div>
                {/* Grid column */}

              </div>
              {/* Card content */}

            </div>
            {/* Card */}

          </div>
          {/* Grid column */}

          {/* Grid column */}
          <div class="col-xl-3 col-md-6 mb-4">

            {/* Card */}
            <div class="card">

              {/* Card Data */}
              <div class="row mt-3">

                <div class="col-md-5 col-5 text-left pl-4">
                  <a type="button" class="btn-floating btn-lg red accent-2 ml-4"><i class="fas fa-database"
                      aria-hidden="true"></i></a>
                </div>

                <div class="col-md-7 col-7 text-right pr-5">
                  <h5 class="ml-4 mt-4 mb-2 font-weight-bold">4,567</h5>
                  <p class="font-small grey-text">Order Amount</p>
                </div>

              </div>
              {/* Card Data */}

              {/* Card content */}
              <div class="row my-3">

                <div class="col-md-7 col-7 text-left pl-4">
                  <p class="font-small font-up ml-4 font-weight-bold">Last week</p>
                </div>

                <div class="col-md-5 col-5 text-right pr-5">
                  <p class="font-small grey-text">145,567</p>
                </div>

              </div>
              {/* Card content */}

            </div>
            {/* Card */}

          </div>
          {/* Grid column */}

        </div>
        {/* Grid row */}

      </section>
      {/*Section: Button icon */}

      <div ></div>
	   {/* Section: Analytical panel */}
      <section class="mb-5">

        {/* Card */}
        <div class="card card-cascade narrower">

		{/* Section: Chart */}
          <section>

            {/* Grid row */}
            <div class="row">

              {/* Grid column */}
              <div class="col-xl-5 col-md-12 mr-0">

                {/* Card image */}
                <div class="view view-cascade gradient-card-header light-blue lighten-1">
                  <h4 class="h4-responsive mb-0 font-weight-bold">Traffic</h4>
                </div>
                {/* Card image */}

                {/* Card content */}
                <div class="card-body card-body-cascade pb-0">

                  {/* Panel data */}
                  <div class="row card-body pt-3">

                    {/* First column */}
                    <div class="col-md-12">

					{/* Date select */}
                      <h4>
                        <span class="badge big-badge light-blue lighten-1">Data range</span>
                      </h4>

                      <select class="mdb-select">
                        <option value="" disabled selected>Choose time period</option>
                        <option value="1">Today</option>
                        <option value="2">Yesterday</option>
                        <option value="3">Last 7 days</option>
                        <option value="3">Last 30 days</option>
                        <option value="3">Last week</option>
                        <option value="3">Last month</option>
                      </select>

                      <br />

			{/* Date pickers */}
                      <h5><span class="badge big-badge light-blue lighten-1">Custom date</span></h5>
                        <br />
                        <div class="d-flex justify-content-between">
                          <div class="md-form">
                            <input placeholder="Selected date" type="text" id="from" class="form-control datepicker" />
                            <label for="date-picker-example">From</label>
                          </div>
                          <div class="md-form">
                            <input placeholder="Selected date" type="text" id="to" class="form-control datepicker" />
                            <label for="date-picker-example">To</label>
                          </div>
                        </div>

                    </div>
                    {/* First column */}

                  </div>
                 {/* Panel data */}

                </div>
                {/* Card content */}

              </div>
              {/* Grid column */}

              {/* Grid column */}
              <div class="col-xl-7 col-md-12 mb-4">

                {/* Card image */}
                <div class="view view-cascade gradient-card-header white">

                  {/* Chart */}
                  <canvas id="barChart"></canvas>

                </div>
                {/* Card image */}

              </div>
			{/* Grid column */}

            </div>
            {/* Grid row */}

          </section>
          {/* Section: Chart */}

        </div>
        {/* Card */}      
</section>



      {/* Section: data tables */}
      <section class="section">

        {/*Grid row */}
        <div class="row">

		{/* Grid column */}
          <div class="col-md-12 col-lg-4 ">

            <div class="card mb-4">

              <div class="card-body">

                <div class="table-responsive">

                  <table class="table large-header">

                    <thead>

                      <tr>
                        <th class="font-weight-bold"><strong>Keywords</strong></th>
                        <th class="font-weight-bold"><strong>Visits</strong></th>
                        <th class="font-weight-bold"><strong>Pages</strong></th>
                      </tr>

                    </thead>

                    <tbody>

                      <tr>
                        <td>Design</td>
                        <td>15</td>
                        <td>307</td>
                      </tr>
                      <tr>
                        <td>Bootstrap</td>
                        <td>32</td>
                        <td>504</td>
                      </tr>
                      <tr>
                        <td>MDBootstrap</td>
                        <td>41</td>
                        <td>613</td>
                      </tr>
                      <tr>
                        <td>Frontend</td>
                        <td>14</td>
                        <td>208</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <button
                  class="btn btn-flat grey lighten-3 btn-rounded waves-effect float-right font-weight-bold btn-dash">View
                  full report</button>

              </div>

            </div>

          </div>

          <div class="col-lg-8 col-md-12">

            <div class="card mb-4">

              <div class="card-body">

                <table class="table large-header">

                  <thead>
                    <tr>
                      <th class="font-weight-bold"><strong>Browser</strong></th>
                      <th class="font-weight-bold"><strong>Visits</strong></th>
                      <th class="font-weight-bold"><strong>Pages</strong></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Google Chrome</td>
                      <td>15</td>
                      <td>307</td>
                    </tr>
                    <tr>
                      <td>Mozilla Firefox</td>
                      <td>32</td>
                      <td>504</td>
                    </tr>
                    <tr>
                      <td>Safari</td>
                      <td>41</td>
                      <td>613</td>
                    </tr>
                    <tr>
                      <td>Opera</td>
                      <td>14</td>
                      <td>208</td>
                    </tr>

                  </tbody>
                </table>

                <button
                  class="btn btn-flat grey lighten-3 btn-rounded waves-effect font-weight-bold float-right btn-dash">View
                  full report</button>

              </div>

            </div>

          </div>
		{/* Grid column */}

        </div>
        {/* Grid row */}

      </section>
      {/* Section: data tables */}
	  
	   {/* Section: Cards color */}
      <section class="mt-2">

       {/* Grid row */}
        <div class="row">

          {/* Grid column */}
          <div class="col-xl-3 col-md-6 mb-4">

            {/* Panel */}
            <div class="card">

              <div class="card-header white-text primary-color">
                Orders
              </div>

              <h6 class="ml-4 mt-5 font-weight-bold"><i class="fas fa-arrow-up blue-text mr-3" aria-hidden="true"></i>
                2000</h6>
             {/* Card Data */}

              {/* Card content */}
              <div class="card-body">

                <div class="progress">

                  <div class="progress-bar blue" role="progressbar"  aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100">

                  </div>
                </div>
		{/* Text */}
                <p class="font-small grey-text">Better than last week (25%)</p>
              </div>
              {/* Card content */}

            </div>
            {/* Panel */}

          </div>
          {/* Grid column */}

         {/* Grid column */}
          <div class="col-xl-3 col-md-6 mb-4">

           {/* Panel */}
            <div class="card">

              <div class="card-header white-text warning-color">
                Monthly sales
              </div>

              <h6 class="ml-4 mt-5 font-weight-bold"><i class="fas fa-arrow-up blue-text mr-3" aria-hidden="true"></i> $
                2000</h6>
              {/* Card Data */}

              {/* Card content */}
              <div class="card-body">

                <div class="progress">

                  <div class="progress-bar blue" role="progressbar"  aria-valuenow="65"
                    aria-valuemin="0" aria-valuemax="100">
                  </div>

                </div>
		{/* Text */}
                <p class="font-small grey-text">Better than last week (25%)</p>
              </div>
             {/* Card content */}

            </div>
            {/* Panel */}

          </div>
          {/* Grid column */}

          {/* Grid column */}
          <div class="col-xl-3 col-md-6 mb-4">

            {/* Panel */}
            <div class="card">

              <div class="card-header white-text light-blue lighten-1">
                Sales
              </div>

              <h6 class="ml-4 mt-5 font-weight-bold"><i class="fas fa-long-arrow-alt-down red-text mr-3"
                  aria-hidden="true"></i> $ 2000</h6>
              {/* Card Data */}

              {/* Card content */}
              <div class="card-body">

                <div class="progress">

                  <div class="progress-bar red" role="progressbar"  aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100">
                  </div>

                </div>
                {/* Text */}
                <p class="font-small grey-text">Worse than last week (25%)</p>
              </div>
              {/* Card content  */}

            </div>
            {/* Panel  */}

          </div>
          {/* Grid column  */}

          {/* Grid column  */}
          <div class="col-xl-3 col-md-6 mb-4">

            {/* Panel  */}
            <div class="card">
              <div class="card-header white-text red accent-2">
                Daily Sales
              </div>

              <h6 class="ml-4 mt-5 font-weight-bold"><i class="fas fa-long-arrow-alt-down red-text mr-3"
                  aria-hidden="true"></i> $ 2000</h6>
              {/* Card Data -->

              {/* Card content  */}
              <div class="card-body">

                <div class="progress">

                  <div class="progress-bar red" role="progressbar"  aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100">
                  </div>

                </div>
                {/* Text */}
                <p class="font-small grey-text">Worse than last week (25%)</p>
              </div>
              {/* Card content  */}

            </div>
            {/* Panel  */}


          </div>
          {/* Grid column  */}
        </div>
        {/* Grid row  */}

      </section>
      {/* Section: Cards color  */}
	  
	  
	     {/* Section: Panels  */}
      <section>

        {/* Grid row  */}
        <div class="row">

          {/* Grid column  */}
          <div class="col-xl-5 col-md-12">

            {/* Card  */}
            <div class="card mb-4">

              {/* Card Data  */}
              <div class="row">

                <div class="col-md-12 text-center">
                  <h5 class="mt-4 mb-4 font-weight-bold">Monthly Sales</h5>
                </div>

              </div>

			  {/* Card content */}
              <div class="card-body">

                <div class="progress mb-2 mt-1">

                  <div class="progress-bar warning-color" role="progressbar"  aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100">
                  </div>

                </div>

			  {/* Text */}
                <p class="font-small grey-text mb-4">January</p>

                <div class="progress mb-2">
                  <div class="progress-bar red accent-2" role="progressbar"  aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>

                {/* Text */}
                <p class="font-small grey-text mb-4">Febuary</p>

                <div class="progress mb-2">

                  <div class="progress-bar primary-color" role="progressbar"  aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100">
                  </div>

                </div>

                {/* Text */}
                <p class="font-small grey-text mb-4">March</p>

                <div class="progress mb-2">
                  <div class="progress-bar light-blue lighten-1" role="progressbar" 
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                {/* Text */}
                <p class="font-small grey-text mb-2">April</p>
              </div>
              {/* Card content */}

            </div>
            {/* Card */}

          </div>
			 {/* Grid column */}


          {/* Grid column */}
          <div class="col-xl-3 col-md-6 mb-2">

            {/* Card */}
            <div class="card">

              {/* Card Data */}
              <div class="row mt-4 mb-3">

                <div class="col-md-3 col-3 text-left pl-4">

                  {/* Facebook */}
                  <a class="p-2 m-2 fa-lg fb-ic"><i class="fab fa-facebook-f fa-2x blue-text"> </i></a>

                </div>

                <div class="col-md-9 col-9 text-right pr-5">

                  <p class="font-small grey-text mb-1">Facebook Users</p>
                  <h5 class="ml-4 mb-2 font-weight-bold">4,567 </h5>

                </div>
              </div>
              {/* Card Data */}

            </div>
            {/* Card */}

            {/* Card */}
            <div class="card mt-4">

			{/* Card Data */}
              <div class="row mt-4 mb-3">

                <div class="col-md-3 col-3 text-left pl-4">

                  {/*  Facebook */}
                  <a class="p-2 m-2 fa-lg fb-ic"><i class="fab fa-google-plus-g fa-2x red-text"> </i></a>

                </div>

                <div class="col-md-9 col-9 text-right pr-5">

                  <p class="font-small grey-text mb-1">Google+ Users</p>
                  <h5 class="ml-4 mb-2 font-weight-bold">2,669 </h5>

                </div>
              </div>
              {/*  Card Data */}

            </div>
            {/*  Card */}

            {/*  Card */}
            <div class="card mt-4 mb-4">

              {/*  Card Data */}
              <div class="row mt-4 mb-3">

                <div class="col-md-3 col-3 text-left pl-4">

				{/* Facebook */}
                  <a class="p-2 m-2 fa-lg fb-ic"><i class="fab fa-twitter fa-2x cyan-text"> </i></a>

                </div>

                <div class="col-md-9 col-9 text-right pr-5">

                  <p class="font-small grey-text mb-1">Twitter Users</p>
                  <h5 class="ml-4 mb-2 font-weight-bold">3,562 </h5>

                </div>
              </div>
              {/* Card Data */}

            </div>
            {/* Card */}

          </div>
          {/* Grid column */}

          {/* Grid column */}
          <div class="col-xl-4 col-md-6 mb-2">

		  {/* Card */}
            <div class="card mb-4">

              <div class="card-body">

                <div class="table-responsive">

                  <table class="table large-header mb-1">

                    <thead>
                      <tr>
                        <th class="font-weight-bold"><strong>Month</strong></th>
                        <th class="font-weight-bold"><strong>Visits</strong></th>
                        <th class="font-weight-bold"><strong>Sales</strong></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>January</td>
                        <td>15</td>
                        <td>307</td>
                      </tr>
                      <tr>
                        <td>Febuary</td>
                        <td>32</td>
                        <td>504</td>
                      </tr>
                      <tr>
                        <td>March</td>
                        <td>41</td>
                        <td>613</td>
                      </tr>

                    </tbody>

                  </table>

                </div>

                <button
                  class="btn btn-flat grey lighten-3 btn-rounded waves-effect float-right font-weight-bold btn-dash">View
                  full report</button>

              </div>

            </div>
            {/* Card */}

          </div>
          {/* Grid column */}

        </div>
        {/* Grid row */}

      </section>
	  
</div>
  </main>
  {/* Main layout */}

</div>
    )


}

export default Main;