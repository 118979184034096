import * as React from 'react';
import { useRef, useState, useEffect } from 'react'
import Axios from '../api/axios'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SearchBar from "material-ui-search-bar";

import MUIDataTable from "mui-datatables";

import Headers from './Headers'
//const columns = ["Name", "Company", "City", "State"];

/*const columns = ["Branch", "Date","Payment Method", "Available Quantity", "Quantity Sold", "Unit Cost", 
"unit Price", "Total Sale", "profit", "Cashier", "Customer Phone",  "Customer", "Referee Phone","Referee Name"
];
*/


export default function SalesReport() {

 


  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      //navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [tableRefresh, setTableRefresh] = useState('')
  const [refresh, setRefresh] = useState(new Date())
  
  const [rows, setDBtableViewSalesReport] = useState([])

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  
  //const [DBtableViewSalesReport, setDBtableViewSalesReport] = useState([])
  
  
  const [businessBranchName, setBusinessBranchName] = useState('All')
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  const [update, setUpdate] = useState('')

  let [handleSubmitBtnDelete, setHandleSubmitBtnDelete] = useState('')

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserRole = localStorage.getItem('userRole')
  let secondUserRole = JSON.parse(firstUserRole)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)


  useEffect(() => {
    const interval = setInterval(() => {
    setRefresh(new Date());
    }, 10000);
    
    return () => clearInterval(interval);
      }, [])
    
/*
      //alert("id = "+systemSetupIDInitial);
      useEffect(() => {
       setHandleSubmitBtn('Loading');
        Axios.post(`${server_app_api}/api/getViewSalesReport`, {
          businessNameID: systemSetupIDInitial
        })
        .then(response => {
          setHandleSubmitBtn('');
          //alert(response.data)
          setDBtableViewSalesReport(response.data)
        }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
      }, [])
*/


useEffect(() => {

  setHandleSubmitBtn('Loading');
  var formDataSalesReport = new FormData(this)
  formDataSalesReport.append('apiType', 'getViewSalesReport')
  if (
    secondUserPosition === 'Business Owner' ||
    secondUserPosition === 'Auditor'
  ) {
    formDataSalesReport.append('businessNameID', systemSetupIDInitial)
  }
  if (secondUserPosition === 'Manager') {
    formDataSalesReport.append(
      'businessBranchID',
      secondUserSystemSetupBranchID
    )
  }
  if (secondUserPosition === 'Cashier') {
    formDataSalesReport.append(
      'businessBranchIDCashier',
      secondUserSystemSetupBranchID
    )
    formDataSalesReport.append('userID', secondUserID)
  }
  Axios({
    method: 'post',
    url: `${server_app_api_php}/index.php`,
    data: formDataSalesReport,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(response => {
    setHandleSubmitBtn('');
    setDBtableViewSalesReport(response.data)
    //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
}, [])



const columns = [
  { label : "SN",   name:'posID',  options: {     filter: true,     sort: true, 
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
       <span>
        {tableMeta.rowIndex + 1}
       </span>
      );
    }
  } ,
},
  {    name: "branch_name",    label: "Branch",    options: {     filter: true,     sort: true, 
  } ,
  setCellProps: () => ({style: {whiteSpace:'nowrap'}}) },
   { label: "Date", name : "pos_date",    options: {     filter: true,     sort: true,    },
   setCellProps: () => ({style: {whiteSpace:'nowrap'}}) },
   {  label: "Item", name : "product",    options: {     filter: true,     sort: true,    },
  setCellProps: () => ({style: {whiteSpace:'nowrap'}})  },  
  {  label: "Payment Method", name : "sell_type",    options: {     filter: true,     sort: true,    },
  setCellProps: () => ({style: {whiteSpace:'nowrap'}})  },
  { label : "Available Quantity", name : "stock_quantity",    options: {     filter: true,     sort: true,    },
  setCellProps: () => ({style: {whiteSpace:'nowrap'}})  },
  {  label : "Quantity Sold", name : "unit_quantity",    options: {     filter: true,     sort: true,    } ,
  setCellProps: () => ({style: {whiteSpace:'nowrap'}}) },
   {  
    label : "Unit Cost", name : "product_unit_cost",    options: {     filter: true,     sort: true,    },
    setCellProps: () => ({style: {whiteSpace:'nowrap'}})
  },
   {  
    label : "unit Price", name : "product_unit_price",    options: {     filter: true,     sort: true,    },
    setCellProps: () => ({style: {whiteSpace:'nowrap'}})
    },
  {  
    label : "Total Sale", name : "product_total_price",    options: {     filter: true,     sort: true,    },
    setCellProps: () => ({style: {whiteSpace:'nowrap'}})
  },
  {  
    label : "profit", name : "profit",    options: {     filter: true,     sort: true,    },
    setCellProps: () => ({style: {whiteSpace:'nowrap'}})
  },
  {  label : "Cashier", name : "cashier",    options: {     filter: true,     sort: true,    } ,
  setCellProps: () => ({style: {whiteSpace:'nowrap'}}) },
  {  label : "Customer Phone", name : "customerPhone",    options: {     filter: true,     sort: true,    },
  setCellProps: () => ({style: {whiteSpace:'nowrap'}})  },
   { label : "Customer", name : "customer",    options: {     filter: true,     sort: true,    } ,
   setCellProps: () => ({style: {whiteSpace:'nowrap'}}) },
   {  label : "Referee Phone", name : "refererPhone",    options: {     filter: true,     sort: true,    },
   setCellProps: () => ({style: {whiteSpace:'nowrap'}})  },
    { label : "Referee Name", name : "refererName",    options: {     filter: true,     sort: true,    } ,
    setCellProps: () => ({style: {whiteSpace:'nowrap'}}) }, 
  
 
];

/*const options = {
  filterType: 'checkbox',
};
*/
const options = {
  filter: true,
  filterType: 'dropdown',
  responsive: 'standard',
  fixedHeader: true,
  fixedSelectColumn: true,
  tableBodyHeight: '400px',
  selectableRowsHeader : false,
  selectableRows : 'none',
  rowsPerPageOptions : [10, 15, 20, 50, 100, 200, 500, 1000, 5000, 10000, 20000, 50000, 100000],
  //rowsPerPage : 5,
  searchAlwaysOpen : true,
  searchPlaceholder: 'Search Here',
  elevation : 4,
  onChangeRowsPerPage(numberOfRows) {
    setRowsPerPage(numberOfRows);
  },
  onChangePage(page) {
    setPage(page);
  },
  customTableBodyFooterRender: (opts) => {
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    let sumstock_quantity = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[5]);
      }, 0);
      let sumunit_quantity = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[6]);
      }, 0);
      let sumproduct_unit_cost = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[7]);
      }, 0);
      let sumproduct_unit_price = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[8]);
      }, 0);
      let sumproduct_total_price = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[9]);
      }, 0);
      let sumprofit = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[10]);
      }, 0);
    return (
      <>
        {rows.length > 0 && (
          <TableFooter style={{backgroundColor:'gray'}}>
            <TableRow>
           
              {opts.columns.map((col, index) => {
                if (col.display === "true") {
                                     
                        if (col.name === "posID") {
                                      return (
                                        <TableCell key={index} > </TableCell>
                                      );                  
                                      }
                        else if (col.name === "branch_name") {
                                        return (
                                          <TableCell key={index} > </TableCell>
                                        );                  
                                        }

                                        else if (col.name === "pos_date") {
                                          return (
                                            <TableCell key={index} > </TableCell>
                                          );                  
                                          }
                                          else if (col.name === "product") {
                                            return (
                                              <TableCell key={index} > </TableCell>
                                            );                  
                                            }
                  else if (col.name === "sell_type") {
                    return (
                      <TableCell key={index} >
                        Total
                      </TableCell>
                    );
                  } else if (col.name === "stock_quantity") {
                    return (
                      <TableCell key={index} >
                        {sumstock_quantity}
                      </TableCell>
                    );
                    }
                    else if (col.name === "unit_quantity") {
                      return (
                        <TableCell key={index} >
                          {sumunit_quantity}
                        </TableCell>
                      );
                      }
                      else if (col.name === "product_unit_cost") {
                        return (
                          <TableCell key={index} >
                            {sumproduct_unit_cost}
                          </TableCell>
                        );
                        }
                        else if (col.name === "product_unit_price") {
                          return (
                            <TableCell key={index} >
                              {sumproduct_unit_price}
                            </TableCell>
                          );
                          }
                          else if (col.name === "product_total_price") {
                            return (
                              <TableCell key={index} >
                                {sumproduct_total_price}
                              </TableCell>
                            );
                            }
                            else if (col.name === "profit") {
                              return (
                                <TableCell key={index} >
                                  {sumprofit}
                                </TableCell>
                              );
                              }
                    else if (col.name === "cashier") {
                      return (
                        <TableCell key={index} > </TableCell>
                      );                  
                      }
                      else if (col.name === "customerPhone") {
                        return (
                          <TableCell key={index} > </TableCell>
                        );                  
                        }
                        else if (col.name === "customer") {
                          return (
                            <TableCell key={index} > </TableCell>
                          );                  
                          }
                          else if (col.name === "refererPhone") {
                            return (
                              <TableCell key={index} > </TableCell>
                            );                  
                            }
                            else if (col.name === "refererName") {
                              return (
                                <TableCell key={index} > </TableCell>
                              );                  
                              }
                }
              })}
            </TableRow>
          </TableFooter>
        )}
      </>
    );
  }
};
  
  return (
<>


<>
          <Headers />
        </>
        
    <div hidden={handleSubmitBtn ===''}>
    <div id='overlayer'></div>
    <div class="loader d-flex justify-content-center">
  <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
      <span class='visually-hidden'>Loading...</span>
    </div>
  </div>
  </div>

  <div hidden={handleSubmitBtnDelete ===''}>
    <div class="loader d-flex justify-content-center">          
      <div class="spinner-border text-danger" role="status" style={{width: '10rem', height: '10rem'}}>
      <span class=''>Loading...</span>
</div>
    </div>
    </div>

<Paper>
          <MUIDataTable
  title={"Sales Report"}
  data={rows}
  columns={columns}
  options={options}
/>     
  
  </Paper>
  </>
  );
}