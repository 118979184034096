import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

const SetCustomerPoints = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const businessNameRef = useRef()
  const errRef = useRef()

  //const [logo, setLogo] = useState('');
  //const [pacraCertificate, setPacraCertificate] = useState('');

  const [businessStoreName, setBusinessStoreName] = useState('')
  var [promotionLifeSpanType, setPromotionLifeSpanType] = useState('')
  var [promotionCodeName, setPromotionCodeName] = useState('')
  var [lifeSpan, setLifeSpan] = useState('')
  var [startDate, setStartDate] = useState('')
  var [endDate, setEndDate] = useState('')
  var [period, setPeriod] = useState('')
  const [expensesAmount, setExpensesAmount] = useState('')
  //const [storeExpenseTypeOther, setStoreExpenseTypeOther] = useState('')
  const [ip, setIP] = useState('')
  var [paymentType, setPaymentType] = useState('')
  var [pointsTypes, setPointsTypes] = useState('')
  var [pointsCriteria, setPointsCriteria] = useState('')
  var [businessStoreNameDropDown, setBusinessStoreNameDropDown] = useState([])
  //var [storeExpensesDropDown, setStoreExpensesDropDown] = useState([])
  var [pointsTypesDropDown, setPointsTypesDropDown] = useState([])
  var [pointsCriteriaDropDown, setPointsCriteriaDropDown] = useState([])

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  //const [success, setSuccess ] = useState(false);

  useEffect(() => {
    businessNameRef.current.focus()
  }, [])

  useEffect(() => {
    if (pointsCriteria === '' || pointsCriteria === undefined) {
      setPeriod('')
      setServiceList([
        {
          from: '',
          to: '',
          points: '',
          referalPoints: ''
        }
      ])
    } else {
      setPeriod('')
      setServiceList([
        {
          from: '',
          to: '',
          points: '',
          referalPoints: ''
        }
      ])
    }
    //alert(period)
  }, [pointsCriteria])

  useEffect(() => {
    if (
      pointsTypes === '' ||
      pointsTypes === '1' ||
      pointsTypes === undefined
    ) {
      setPromotionCodeName('')
      setPromotionLifeSpanType('')
      setLifeSpan('')
      setStartDate('')
      setEndDate('')
    } else {
      setPromotionCodeName('')
      setPromotionLifeSpanType('')
      setLifeSpan('')
      setStartDate('')
      setEndDate('')
    }
    //alert(period)
  }, [pointsTypes])

  /*useEffect( () => {
	alert("we are in drops");
},
[businessNameDropDown])*/

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  /*useEffect( () => {
        Axios.post("http://localhost:3001/api/getBusinessNameDropDown").then((response) => {
            setBusinessNameDropDown(response.data);
            //console.log(response);
            });
        },
        [])*/
  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)
  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)
  //alert('id = ' + systemSetupIDInitial)

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getBusinessNameDropDown`, {
      businessNameID: systemSetupIDInitial
    })*/
    var formDataViewBusinesses = new FormData(this)
    formDataViewBusinesses.append('apiType', 'getBusinessBranchNameDropDown')
    if (secondUserSystemSetupBranchID === '') {
      formDataViewBusinesses.append('businessNameID', systemSetupIDInitial)
    }
    if (secondUserSystemSetupBranchID != '') {
      formDataViewBusinesses.append(
        'businessBranchNameID',
        secondUserSystemSetupBranchID
      )
    }
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewBusinesses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessStoreNameDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataViewBusinessesStoreExpenses = new FormData(this)
    formDataViewBusinessesStoreExpenses.append(
      'apiType',
      'getStoreExpensesTypes'
    )
    formDataViewBusinessesStoreExpenses.append(
      'businessNameID',
      systemSetupIDInitial
    )
    formDataViewBusinessesStoreExpenses.append(
      'businessBranchNameID',
      businessStoreName
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewBusinessesStoreExpenses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setStoreExpensesDropDown(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [businessStoreName])

  useEffect(() => {
    var formDataPointsType = new FormData(this)
    formDataPointsType.append('apiType', 'getPointTypes')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataPointsType,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setPointsTypesDropDown(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataPointsType = new FormData(this)
    formDataPointsType.append('apiType', 'getPointsCriteria')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataPointsType,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setPointsCriteriaDropDown(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  const handleSubmit = async e => {
    //alert("businessName = "+businessName);
    const currentDate = new Date()
    const dateToday = `${currentDate.getDate()}/${
      currentDate.getMonth() + 1
    }/${currentDate.getFullYear()}`
    const timeNow =
      currentDate.getHours() +
      ':' +
      currentDate.getMinutes() +
      ':' +
      currentDate.getSeconds()

      setHandleSubmitBtn('Loading');
    const firstUserID = localStorage.getItem('userID')
    const userIDInitial = JSON.parse(firstUserID)
    var userID = userIDInitial
    var formDataRegisterProducts = new FormData(this)
    formDataRegisterProducts.append('apiType', 'postCustomerPointsRanges')
    formDataRegisterProducts.append('system_setupID', systemSetupIDInitial)
    formDataRegisterProducts.append('system_setup_branchID', businessStoreName)
    formDataRegisterProducts.append('pointsTypes', pointsTypes)
    formDataRegisterProducts.append('pointsCriteria', pointsCriteria)
    formDataRegisterProducts.append('period', period)
    formDataRegisterProducts.append('promotionCodeName', promotionCodeName)
    formDataRegisterProducts.append(
      'promotionLifeSpanType',
      promotionLifeSpanType
    )
    formDataRegisterProducts.append('lifeSpan', lifeSpan)
    formDataRegisterProducts.append('startDate', startDate)
    formDataRegisterProducts.append('endDate', endDate)
    formDataRegisterProducts.append('from', from)
    formDataRegisterProducts.append('to', to)
    formDataRegisterProducts.append('referalPoints', referalPoints)
    formDataRegisterProducts.append('points', points)
    formDataRegisterProducts.append('store_user', secondUserSystemSetupBranchID)

    formDataRegisterProducts.append('store_expenses_enteredBy', userID)
    formDataRegisterProducts.append('system_setup_IP', ip)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataRegisterProducts,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        setHandleSubmitBtn('')
        /*if (
          res.data === 'Successfully Uploaded' ||
          res.data ===
            'Some Were Uploaded, But The Duplicates Were Not Uploaded'
        ) {*/
        if (res.data === 'Successfully Uploaded') {
          setBusinessStoreName('')
          setPointsTypes('')
          setPointsCriteria('')
          setPromotionCodeName('')
          setPromotionLifeSpanType('')
          setLifeSpan('')
          setStartDate('')
          setEndDate('')
          setFrom('')
          setTo('')
          setReferalPoints('')
          setPoints('')
          setPeriod('')
          fromArr = []
          toArr = []
          pointsArr = []
          referalPointsArr = []
          setServiceList([
            {
              from: '',
              to: '',
              points: '',
              referalPoints: ''
            }
          ])
        }
        alert(res.data)
      })
      .catch(err =>{ alert('ERROR : Kindly Contact Your Adminitrator')
      setHandleSubmitBtn('') 
    })
  }

  var fromArr = []
  var toArr = []
  var pointsArr = []
  var referalPointsArr = []
  const [serviceList, setServiceList] = useState([
    {
      from: '',
      to: '',
      points: '',
      referalPoints: ''
    }
  ])

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      {
        from: '',
        to: '',
        points: '',
        referalPoints: ''
      }
    ])
  }
  const handleServiceRemove = index => {
    const list = [...serviceList]
    list.splice(index, 1)
    setServiceList(list)

    list.map(val => {
      fromArr.push(val.from)
      toArr.push(val.to)
      pointsArr.push(val.points)
      referalPointsArr.push(val.referalPoints)
    })
    /*localStorage.setItem('from', JSON.stringify(fromArr))
    localStorage.setItem('to', JSON.stringify(toArr))
    localStorage.setItem('points', JSON.stringify(pointsArr))*/
  }
  const handleChange = (e, index) => {
    var { name, value } = e.target
    const list = [...serviceList]
    list[index][name] = value
    setServiceList(list)

    list.map(val => {
      fromArr.push(val.from)
      toArr.push(val.to)
      pointsArr.push(val.points)
      referalPointsArr.push(val.referalPoints)
    })
    /*localStorage.setItem('from', JSON.stringify(fromArr))
    localStorage.setItem('to', JSON.stringify(toArr))
    localStorage.setItem('points', JSON.stringify(pointsArr))*/

    setFrom(JSON.stringify(fromArr))
    setTo(JSON.stringify(toArr))
    setPoints(JSON.stringify(pointsArr))
    setReferalPoints(JSON.stringify(referalPointsArr))
  }

  const [from, setFrom] = useState([{}])
  const [to, setTo] = useState([{}])
  const [points, setPoints] = useState([{}])
  const [referalPoints, setReferalPoints] = useState([{}])

  // date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(); //output= 2020-5-24
  //var time = new Date().toTimeString();

  //const time = `${currentDate.getTime()}`;
  //var today = new Date(),
  //alert("Time Is = "+timeNow+" Today's Date = "+date);

  ;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - Set Customer Points -{' '}
        {systemSetupBranchIDStoreName}{' '}
      </title>
      <>
        <Headers />
      </>

      {/*<!-- Preloader 
      <div id='overlayer'></div>
      <div class='loader'>
        <div class='spinner-border' role='status'>
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div>-->*/}

      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row mt-5'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body'>
                  <div class='form-header white-text ocean blue-gradient'>
                    {' '}
                    SET BUSINESS CUSTOMER RETENTION POINTS HERE{' '}
                  </div>
                  {/*Start Of Row 1*/}
                  <div class='row'>
                    <div class='col-lg-4 col-md-12 mb-1 text-left'>
                      <div class='grey-text'>
                        <label>
                          Business Store Name <i class='red-text'>*</i>{' '}
                        </label>
                        <select
                          class='custom-select d-block w-100'
                          onChange={e => setBusinessStoreName(e.target.value)}
                          value={businessStoreName}
                          ref={businessNameRef}
                        >
                          <option value=''>..Choose Business Store ..</option>
                          <option>All</option>
                          {businessStoreNameDropDown.map(val => {
                            return (
                              <option value={val.system_setup_branchID}>
                                {val.branch_name}{' '}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>

                    <div class='col-lg-4 col-md-12 mb-1 text-left'>
                      <div class='grey-text'>
                        <label>
                          Points Types <i class='red-text'>*</i>{' '}
                        </label>
                        <select
                          class='custom-select d-block w-100'
                          id='businessName'
                          onChange={e => setPointsTypes(e.target.value)}
                          value={pointsTypes}
                        >
                          <option value=''>..Choose ...</option>
                          {pointsTypesDropDown.map(val => {
                            return (
                              <option
                                value={val.customer_retention_point_typesID}
                              >
                                {val.customer_retention_point_type}{' '}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>

                    <div class='col-lg-4 col-md-12 mb-1 text-left'>
                      <div class='grey-text'>
                        <label>
                          Points Accumulation Criteria <i class='red-text'>*</i>{' '}
                        </label>
                        <select
                          class='custom-select d-block w-100'
                          onChange={e => setPointsCriteria(e.target.value)}
                          autoComplete='off'
                          value={pointsCriteria}
                        >
                          <option value=''>..Choose..</option>
                          {pointsCriteriaDropDown.map(vals => {
                            return (
                              <option
                                value={vals.customer_retention_point_criteriaID}
                              >
                                {vals.customer_retention_point_criteria}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/*<!--Grid row--> */}
                  {serviceList.map((singleServiceList, index) => {
                    return (
                      <>
                        <div
                          class='row'
                          key={index}
                          hidden={
                            pointsCriteria === '' ||
                            pointsCriteria === undefined
                          }
                        >
                          <div class='col-md-1 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                {index + 1} .<span class='red-text'></span>
                              </label>
                              <br /> <br />
                            </div>
                          </div>

                          <div class='col-md-2 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                <span hidden={pointsCriteria != '1'}>
                                  From This Amount
                                </span>
                                <span hidden={pointsCriteria != '2'}>
                                  From This Frequency
                                </span>
                                <span hidden={pointsCriteria != '3'}>
                                  From This Number Of Products
                                </span>
                                <span class='red-text'>*</span>
                              </label>
                              <br /> <br />
                              <input
                                type='Text'
                                class='form-control'
                                placeholder=''
                                value={singleServiceList.from}
                                onChange={e => handleChange(e, index)}
                                id='from'
                                name='from'
                              />
                            </div>
                          </div>

                          <div class='col-md-2 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                <span hidden={pointsCriteria != '1'}>
                                  To This Amount
                                </span>
                                <span hidden={pointsCriteria != '2'}>
                                  To This Frequency
                                </span>
                                <span hidden={pointsCriteria != '3'}>
                                  To This Number Of Products
                                </span>
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <br /> <br />
                              <input
                                type='Text'
                                class='form-control'
                                placeholder=''
                                value={singleServiceList.to}
                                onChange={e => handleChange(e, index)}
                                id='to'
                                name='to'
                              />
                            </div>
                          </div>

                          <div class='col-md-2 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Points
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <br /> <br />
                              <input
                                type='Text'
                                class='form-control'
                                placeholder=''
                                value={singleServiceList.points}
                                onChange={e => handleChange(e, index)}
                                id='points'
                                name='points'
                              />
                            </div>
                          </div>

                          <div class='col-md-2 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Referal Points
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <br /> <br />
                              <input
                                type='Text'
                                class='form-control'
                                placeholder=''
                                value={singleServiceList.referalPoints}
                                onChange={e => handleChange(e, index)}
                                id='referalPoints'
                                name='referalPoints'
                              />
                            </div>
                          </div>

                          {serviceList.length > 1 && (
                            <div class='col-md-3 mb-1'>
                              <div class='md-form'>
                                <br /> <br /> <br />
                                <input
                                  type='button'
                                  class='form-control btn white-text red accent-2'
                                  value='X Remove Row'
                                  onClick={handleServiceRemove}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          class='row'
                          hidden={
                            pointsCriteria === '' ||
                            pointsCriteria === undefined
                          }
                        >
                          {serviceList.length - 1 === index && (
                            <div class='col-md-12'>
                              <div class='md-form'>
                                <button
                                  class='btn white-text blue accent-2 col-md-12'
                                  onClick={() => handleServiceAdd(index)}
                                >
                                  + Add Another Row
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )
                  })}
                  {/*<!--\Grid row--> */}
                  <div
                    class='row'
                    hidden={
                      pointsTypes === '1' ||
                      pointsTypes === '' ||
                      pointsTypes === undefined
                    }
                  >
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Promotion Code Name <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          onChange={e => setPromotionCodeName(e.target.value)}
                          value={promotionCodeName}
                        />
                      </div>
                    </div>

                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Promotion Life Span Type <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <select
                          class='custom-select d-block w-100'
                          onChange={e =>
                            setPromotionLifeSpanType(e.target.value)
                          }
                          value={promotionLifeSpanType}
                        >
                          <option value=''>..Choose ..</option>
                          <option>Hours</option>
                          <option>Days</option>
                          <option>Weeks</option>
                          <option>Months</option>
                          <option>Years</option>
                        </select>
                      </div>
                    </div>

                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Promotion Life Span <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <input
                          type='number'
                          class='form-control'
                          onChange={e => setLifeSpan(e.target.value)}
                          value={lifeSpan}
                        />
                      </div>
                    </div>

                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Start Date <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <input
                          type='date'
                          class='form-control'
                          onChange={e => setStartDate(e.target.value)}
                          value={startDate}
                        />
                      </div>
                    </div>

                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          End Date<i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <input
                          type='date'
                          class='form-control'
                          onChange={e => setEndDate(e.target.value)}
                          value={endDate}
                        />
                      </div>
                    </div>
                  </div>{' '}
                  {/*End Of Row 1*/}
                  {/*<!--\Grid row--> */}
                  <div class='row' hidden={pointsCriteria != '2'}>
                    <div class='col-lg-12 col-md-12 mb-1 text-left'>
                      <div class='grey-text'>
                        <label>
                          Period Frequency To Be Considered{' '}
                          <i class='red-text'>*</i>{' '}
                        </label>
                        <select
                          class='custom-select d-block w-100'
                          onChange={e => setPeriod(e.target.value)}
                          value={period}
                        >
                          <option value=''>..Choose ..</option>
                          <option>Within a Day</option>
                          <option>Within a Week</option>
                          <option>Within a Month</option>
                          <option>Within a Year</option>
                          <option>Beyond One Year</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <p>
                    <span class='red-text'>* Required</span>{' '}
                    <span>- Do not leave Blank</span>
                  </p>
                  <hr class='mb-1' />
                  <button
                    onClick={handleSubmit}
                    class='btn btn-primary'
                    disabled={
                      handleSubmitBtn === 'Loading' || 
                      businessStoreName === '' ||
                      pointsCriteria === '' ||
                      (pointsCriteria === '2' && period === '') ||
                      pointsTypes === '' ||
                      (pointsTypes === '2' &&
                        (promotionCodeName === '' ||
                          promotionLifeSpanType === '' ||
                          lifeSpan === '' ||
                          startDate === '' ||
                          endDate === '')) ||
                      serviceList === '' ||
                      serviceList ===
                        [
                          {
                            from: '',
                            to: '',
                            points: '',
                            referalPoints: ''
                          }
                        ]
                    }
                  >
                <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
                    Submit
                  </button>
                  <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default SetCustomerPoints
