import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import { NumericFormat  } from 'react-number-format';
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
/*import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'*/
import $ from 'jquery'

const DebitCardReport = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )

  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  let loggedIn = sessionStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)
  if (loggedIn === 'No' || loggedIn ==='') {
    navigate('/Logout')
  }

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const userRef = useRef()
  const errRef = useRef()

  const [DBtableViewCashReport, setDBtableViewCashReport] = useState([])
  const [businessBranchName, setBusinessBranchName] = useState('All')
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  const [update, setUpdate] = useState('')

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  //alert("id = "+systemSetupIDInitial);
  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getViewDebitCardReport`, {
      businessNameID: systemSetupIDInitial
    })*/
    setHandleSubmitBtn('Loading')
    var formDataDebitCardReport = new FormData(this)
    formDataDebitCardReport.append('apiType', 'getViewDebitCardReport')
    if (
      secondUserPosition === 'Business Owner' ||
      secondUserPosition === 'Auditor'
    ) {
      formDataDebitCardReport.append('businessNameID', systemSetupIDInitial)
    }
    if (secondUserPosition === 'Manager') {
      formDataDebitCardReport.append(
        'businessBranchID',
        secondUserSystemSetupBranchID
      )
    }
    if (secondUserPosition === 'Cashier') {
      formDataDebitCardReport.append(
        'businessBranchIDCashier',
        secondUserSystemSetupBranchID
      )
      formDataDebitCardReport.append('userID', secondUserID)
    }
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataDebitCardReport,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      setDBtableViewCashReport(response.data)

      setTimeout(function () {
        $('#table').DataTable({
          fnFooterCallback: function (nRow, aasData, iStart, iEnd, aiDisplay) {
            var columnas = [4, 5, 6, 7, 8] //the columns you wish to add
            for (var j in columnas) {
              var columnaActual = columnas[j]
              var total = 0
              for (var i = iStart; i < iEnd; i++) {
                total = total + parseFloat(aasData[aiDisplay[i]][columnaActual])
              }
              $($(nRow).children().get(columnaActual)).html(total)
            } // end of for loop
          }, // end of fnFootCallback function
          retrieve: true, // this hides the errors
          pageLength: 10,
          lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
        
        })
        addTableButtons()
      }, 1000)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  const UpdateRecord = system_setupID => {
    Axios.put(`${server_app_api}/api/update`, {
      id: system_setupID,
      lname: update
    })
    setUpdate('')
  }

  const deleteRecord = system_setupID => {
    Axios.delete(`${server_app_api}/api/delete/${system_setupID}`)
    alert('Successfully Deleted Record')
  }

  var totalProfit = 0

  /*;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()*/

  //initialize datatable
  /*$(function () {
    setTimeout(function () {
      $('#table').DataTable({
        fnFooterCallback: function (nRow, aasData, iStart, iEnd, aiDisplay) {
          var columnas = [4, 5, 6, 7, 8] //the columns you wish to add
          for (var j in columnas) {
            var columnaActual = columnas[j]
            var total = 0
            for (var i = iStart; i < iEnd; i++) {
              total = total + parseFloat(aasData[aiDisplay[i]][columnaActual])
            }
            $($(nRow).children().get(columnaActual)).html(total)
          } // end of for loop
        }, // end of fnFootCallback function
        retrieve: true, // this hides the errors
        pageLength: 10,
        lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
      
      })
      addTableButtons()
    }, 10000)
  })*/

  function addTableButtons () {
    if (secondUserPosition === 'Cashier') {
      var table = $('#table').DataTable()

      new $.fn.dataTable.Buttons(table, {
        buttons: [
          {
            extend: 'copy',
            text: 'Copy <i class="fa fa-files-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 6, 7] }
          },
          {
            extend: 'excel',
            text: 'Excel <i class="fa fa-file-excel-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 6, 7] }
          },
          //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
          {
            extend: 'pdf',
            text: 'PDF <i class="fa fa-file-pdf-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 6, 7] }
          },
          {
            extend: 'print',
            text: 'Print <i class="fa fa-print"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 6, 7] }
          }
        ]
      })

      table.buttons(0, null).containers().appendTo('#filterTopCriteria')
    } else if (secondUserPosition === 'Manager') {
      var table = $('#table').DataTable()

      new $.fn.dataTable.Buttons(table, {
        buttons: [
          {
            extend: 'copy',
            text: 'Copy <i class="fa fa-files-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 5, 6, 7, 8, 9] }
          },
          {
            extend: 'excel',
            text: 'Excel <i class="fa fa-file-excel-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 5, 6, 7, 8, 9] }
          },
          //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
          {
            extend: 'pdf',
            text: 'PDF <i class="fa fa-file-pdf-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 5, 6, 7, 8, 9] }
          },
          {
            extend: 'print',
            text: 'Print <i class="fa fa-print"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 5, 6, 7, 8, 9] }
          }
        ]
      })

      table.buttons(0, null).containers().appendTo('#filterTopCriteria')
    } else {
      var table = $('#table').DataTable()

      new $.fn.dataTable.Buttons(table, {
        buttons: [
          {
            extend: 'copy',
            text: 'Copy <i class="fa fa-files-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] }
          },
          {
            extend: 'excel',
            text: 'Excel <i class="fa fa-file-excel-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] }
          },
          //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
          {
            extend: 'pdf',
            text: 'PDF <i class="fa fa-file-pdf-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] }
          },
          {
            extend: 'print',
            text: 'Print <i class="fa fa-print"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] }
          }
        ]
      })

      table.buttons(0, null).containers().appendTo('#filterTopCriteria')
    }
  }
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <header>
      <>
        <title>
          KANCH INNOVATIVE TECHNOLOGIES - Sales By Debit Card Report -{' '}
          {systemSetupBranchIDStoreName}
        </title>
        <>
          <Headers />
        </>
        {/*<!-- Preloader -->*/}
        <div hidden = {handleSubmitBtn === ''}>
        <div id='overlayer'></div>
        <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
            <span class='visually-hidden'>Loading...</span>
          </div>
        </div>
        </div>

        <section class='fixed-sn white-skin'>
          <div class=' d-flex justify-content-center align-items-center'>
            <div class='container col-12'>
              <div class='card mt-5'>
                <div class='card-body'>
                  {/*<div class="text-center">  <p > <a href="Home" class="blue-text"> Home</a> </p> </div> */}

                  {/* Data Table View Starts Here*/}
                  <div class=''>
                    {/*<div class="row">*/}
                    <br />
                    <h6 class='form-header white-text ocean blue-gradient'>
                      DEBIT CARD SALES REPORT
                    </h6>

                    <center>
                      <div id='filterTopCriteria'></div>
                    </center>
                    <table
                      id='table'
                      class='table table-striped table-bordered table-hover table-sm'
                    >
                      <thead class='form-header text-uppercase ocean blue-gradient'>
                        {/*<tr>
<td colspan="6"><h1 class="card-header white-text">REGISTERED BUSINESSES</h1></td>
</tr>*/}
                        <tr>
                          {/*
SELECT `stockID`, `system_setupID`, `system_setup_branchID`, `stock_batchID`, `productID`, 
`stock_quantity`, `stock_cost`, `suppliersID`, `product_unit_price`, `stockArrivalDate`, 
`stock_date`, `stock_time`, `stock_entered_by`, `stock_IP` FROM `stock` WHERE 1
*/}
                          <th>S/N</th>
                          <th
                            hidden={
                              secondUserPosition === 'Cashier' ||
                              secondUserPosition === 'Manager'
                            }
                          >
                            Branch
                          </th>
                          <th>Date</th>
                          <th>item</th>
                          <th>Quantity</th>
                          <th hidden={secondUserPosition === 'Cashier'}>
                            Unit Cost
                          </th>
                          <th>unit Price</th>
                          <th>Total Sale</th>
                          <th hidden={secondUserPosition === 'Cashier'}>
                            profit
                          </th>
                          <th hidden={secondUserPosition === 'Cashier'}>
                            Cashier
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {DBtableViewCashReport.map((val, index) => {
                          const profit1 =
                            val.unit_quantity * val.product_unit_cost
                          const profit = val.product_total_price - profit1
                          totalProfit =
                            totalProfit + parseInt(val.product_total_price)
                          return (
                            <tr>
                              {/*system_setupID`, `system_setup_branchID`, `Last_name`, `first_name`, `email`, `phone`, 
`image`, `password`, `thumb_print`, `position`, `role`,   */}
                              <td>{index + 1}</td>
                              <td
                                hidden={
                                  secondUserPosition === 'Cashier' ||
                                  secondUserPosition === 'Manager'
                                }
                              >
                                {val.branch_name}
                              </td>
                              <td>{val.pos_date}</td>
                              <td>{val.product}</td>
                              <td>{val.unit_quantity}</td>
                              <td hidden={secondUserPosition === 'Cashier'}>
                                {val.product_unit_cost}
                              </td>
                              <td>{val.product_unit_price}</td>
                              <td>{val.product_total_price}</td>
                              <td hidden={secondUserPosition === 'Cashier'}>
                                {profit}
                              </td>
                              <td hidden={secondUserPosition === 'Cashier'}>
                                {val.Last_name} {val.first_name}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tfoot class='form-header text-center ocean blue-gradient'>
                        <tr>
                          <th></th>
                          <th
                            hidden={
                              secondUserPosition === 'Cashier' ||
                              secondUserPosition === 'Manager'
                            }
                          ></th>
                          <th></th>
                          <th>TOTALS</th>
                          <th></th>
                          <th hidden={secondUserPosition === 'Cashier'}></th>
                          <th></th>
                          <th></th>
                          <th hidden={secondUserPosition === 'Cashier'}></th>
                          <th hidden={secondUserPosition === 'Cashier'}></th>
                        </tr>
                      </tfoot>
                    </table>

                    <table id='table' style={{ float: 'right' }}>
                      <tr>
                        <td colspan='7'></td>{' '}
                        <td>
                          <h3>
                            <u>
                              <b>TOTAL SALES</b>
                            </u>
                          </h3>{' '}
                        </td>{' '}
                        <td> = </td>{' '}
                        <td>
                          <h3>
                            <b>
                            <NumericFormat value={totalProfit} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                            </b>
                          </h3>
                        </td>
                      </tr>
                    </table>
                  </div>
                  {/* Data Table View Ends Here*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </header>
  )
}

export default DebitCardReport
