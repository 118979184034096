import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'

const RegisterShopCategory = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [shopCategory, setShopCategory] = useState('')
  const [shopCategoryFile, setShopCategoryFile] = useState('')

  const handleSubmit = async e => {
    e.preventDefault()
    /*
     Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/registerStockFromExcelSheet.php`,
        //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/registerStockFromExcelSheet.php',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    */
    var formData = new FormData(this)
    formData.append('shopCategory', shopCategory)
    formData.append('apiType', 'insertShopCategory')
    //Axios.post(`${server_app_api}/api/insertShopCategory`, {
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        alert(res.data)
      })
      .catch(err => alert('ERROR : Kindly Contact Your Adminitrator'))
  }

  /*(function () {

		var preloader = function() {
		
		  var loader = document.querySelector('.loader');
		  var overlay = document.getElementById('overlayer');
		
		  function fadeOut(el) {
		  el.style.opacity = 1;
		  (function fade() {
			if ((el.style.opacity -= .1) < 0) {
			el.style.display = "none";
			} else {
			requestAnimationFrame(fade);
			}
		  })();
		  };
		
		  setTimeout(function() {
		  fadeOut(loader);
		  fadeOut(overlay);
		  }, 200);
		};
		preloader();
		
		})()*/

  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)

  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - Register Shop Category -{' '}
        {systemSetupBranchIDStoreName}{' '}
      </title>
      <>
        <Headers />
      </>
      {/*<!-- Preloader -->
   <div id="overlayer"></div>
    <div class="loader">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div> */}
      <br /> <br />
      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body' id='faults'>
                  <div id='divShowMessageFromAjax'></div>
                  <div class='form-header white-text ocean blue-gradient'>
                    {' '}
                    REGISTER REGISTER CATEGORY HERE{' '}
                  </div>

                  <form method='POST'>
                    {/*Start Of Row 1*/}
                    <div class='row' hidden={shopCategoryFile != ''}>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label>
                            Shop Category <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            id='shopCategory'
                            name='shopCategory'
                            onChange={e => setShopCategory(e.target.value)}
                            autoComplete='off'
                            value={shopCategory}
                          />
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 1*/}
                    {/*Start Of Row 2*/}
                    <div class='row' hidden={shopCategory != ''}>
                      <h2>OR</h2>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label>
                            Excel File Containing Shop Categories
                            <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='file'
                            class='form-control'
                            id='shopCategoryFile'
                            onChange={e => setShopCategoryFile(e.target.value)}
                            autoComplete='off'
                            value={shopCategoryFile}
                          />
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 2*/}
                    <p>
                      <span class='red-text'>* Required</span>{' '}
                      <span>- Do not leave Blank</span>
                    </p>
                    <hr class='mb-1' />
                    <button
                      onClick={handleSubmit}
                      class='btn btn-primary'
                      disabled={shopCategory === '' && shopCategoryFile === ''}
                    >
                      Submit
                    </button>
                    <br /> <br /> <br />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default RegisterShopCategory
