import { useRef, useState, useEffect, useContext } from 'react'
import Headers from './Headers'
import { useNavigate, redirect } from 'react-router-dom'
import Axios from '../api/axios'
import $ from 'jquery'
import { MDBContainer } from 'mdbreact'
import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter
} from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import Select from 'react-select'

export const ViewCustomers = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [
    DBtableViewformDataTransferReport,
    setDBtableViewformDataTransferReport
  ] = useState([])

  const [posProductDropDown, setPosProductDropDown] = useState([])
  const [itemID, setItemID] = useState([{}])
  var [quantity, setQuantity] = useState('')
  //if (quantity === '') {
  //quantity = 0
  //}
  //alert(quantity)
  const [quantityDB, setQuantityDB] = useState('')
  const [compareProductsPricesData, setCompareProductsPricesData] = useState([])
  const [compareProductsPricesLabel, setCompareProductsPricesLabel] = useState(
    []
  )
  const [businessBranchName, setBusinessBranchName] = useState('')
  const [businessBranchNameTo, setBusinessBranchNameTo] = useState('')
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )
  const [businessBranchNameDropDownTo, setBusinessBranchNameDropDownTo] =
    useState([])

  const [storeExpensesDropDown, setStoreExpensesDropDown] = useState([])
  const [DBtableViewExpenses, setDBtableViewExpenses] = useState([])
  const [expensesStore, setDBtableViewExpensesStore] = useState('')
  const [viewCustomersTable, setViewCustomersTable] = useState([])

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  const HandelChangeItemDropDown = obj => {
    var rodeen = []
    obj.map(val => {
      rodeen.push(val.value)
    })
    localStorage.setItem('ItemDropDownValue', JSON.stringify(rodeen))
    setItemID(JSON.stringify(rodeen))
  }

  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const monthToday = `${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const firstUserSystemSetupID = localStorage.getItem('systemSetupID')
  let secondUserSystemSetupID = JSON.parse(firstUserSystemSetupID)

  const firstUserRole = localStorage.getItem('userRole')
  let secondUserRole = JSON.parse(firstUserRole)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  let businessCategory = localStorage.getItem('businessCategory')
  businessCategory = JSON.parse(businessCategory)

  //alert('Branch ID = ' + secondUserSystemSetupBranchID)

  useEffect(() => {
    var formDataTransferReport = new FormData(this)
    formDataTransferReport.append('apiType', 'stockTransferReport')
    formDataTransferReport.append('system_setupID', secondUserSystemSetupID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataTransferReport,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBtableViewformDataTransferReport(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataBranchNames = new FormData(this)
    formDataBranchNames.append('apiType', 'getBusinessBranchNameDropDown')
    formDataBranchNames.append('businessNameID', secondUserSystemSetupID)
    formDataBranchNames.append(
      'businessBranchNameID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataBranchNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessBranchNameDropDown(response.data)
      //alert(response.data)
    })
    //.catch(err => alert('ERROR : Kindly Contact Your Administrator'))
  }, [])

  useEffect(() => {
    var formDataBranchNames = new FormData(this)
    formDataBranchNames.append('apiType', 'getBusinessBranchNameDropDown')
    formDataBranchNames.append('businessNameID', secondUserSystemSetupID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataBranchNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessBranchNameDropDownTo(response.data)
    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [])

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getPosProductDropDown`, {
      businessNameID: systemSetupIDInitial
    })*/
    var formDataProductNames = new FormData(this)
    formDataProductNames.append('apiType', 'getPosProductDropDown')
    formDataProductNames.append('businessNameID', secondUserSystemSetupID)
    formDataProductNames.append('branchNameID', businessBranchName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataProductNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      const posProductDropDown = response.data
      // alert(response.data)
      setPosProductDropDown(
        posProductDropDown.map(
          ({ id, product, stockID, stock_quantity, product_unit_price }) => ({
            id,
            label:
              product +
              '(Quantity = ' +
              stock_quantity +
              ') (Unit Price = K ' +
              product_unit_price +
              ')',
            value: stockID
          })
        )
      )
      //setPosProductDropDown(countriesLocal)
      var a = response.data
      localStorage.setItem('arrHome', JSON.stringify(a))
      //a = JSON.parse(localStorage.getItem('arr')) || [];
      //alert(a);
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [posProductDropDown, businessBranchName])

  useEffect(() => {
    //alert(itemID)
    var formDataQuantityDB = new FormData(this)
    formDataQuantityDB.append('itemID', itemID)
    formDataQuantityDB.append('apiType', 'transferStockQuantityDB')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataQuantityDB,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setQuantityDB(response.data)
      //alert(response.data)
    })
    //.catch(err => alert(err))
  }, [itemID])

  useEffect(() => {
    var formDataCompareProductsPriceslabel = new FormData(this)
    formDataCompareProductsPriceslabel.append('itemID', itemID)
    formDataCompareProductsPriceslabel.append(
      'reportType',
      'compareProductsPricesLabel'
    )
    //formDataCompareProductsPriceslabel.append('fromDate', fromDate)
    //formDataCompareProductsPriceslabel.append('toDate', toDate)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataCompareProductsPriceslabel,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setCompareProductsPricesLabel(response.data)
    })
  }, [businessBranchName, itemID])

  const compareProductsPrices = {
    labels: compareProductsPricesLabel,
    datasets: [
      {
        label: 'PRODUCT SALES',
        data: compareProductsPricesData,
        fill: true,
        backgroundColor: 'rgba(6, 156,51, .3)',
        borderColor: '#02b844'
      }
    ]
  }

  const handleSubmit = async e => {
    //alert(quantity)
    //e.preventDefault()
    //useEffect(() => {
    var userType = ''
    if (secondUserSystemSetupBranchID === '') {
      userType = 'Owner'
    } else if (secondUserSystemSetupBranchID != '') {
      userType = 'User'
    }
    var formDatastockTransferUpload = new FormData(this)
    formDatastockTransferUpload.append('itemID', itemID)
    formDatastockTransferUpload.append('apiType', 'stockTransferUpload')
    formDatastockTransferUpload.append('quantity', quantity)
    formDatastockTransferUpload.append('quantityDB', quantityDB)
    formDatastockTransferUpload.append('userID', secondUserID)
    formDatastockTransferUpload.append('userType', userType)
    formDatastockTransferUpload.append('businessBranchName', businessBranchName)
    formDatastockTransferUpload.append(
      'businessBranchNameTo',
      businessBranchNameTo
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDatastockTransferUpload,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      //setformDatastockTransferUpload(response.data)
      alert(response.data)
    })
  }
  //}, [businessBranchName, itemID])*/
  useEffect(() => {
    //alert('ID' + secondUserSystemSetupBranchID)
    var formDataViewExpenses = new FormData(this)
    formDataViewExpenses.append('apiType', 'getViewExpensesDayClose')
    formDataViewExpenses.append('expenseID', '')
    formDataViewExpenses.append('businessNameID', secondUserSystemSetupID)
    formDataViewExpenses.append(
      'businessBranchNameID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewExpenses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBtableViewExpenses(response.data)
      //alert(response.data)
    })
  }, [])

  useEffect(() => {
    var formDataViewExpensesStore = new FormData(this)
    formDataViewExpensesStore.append('apiType', 'getViewExpensesDayCloseStore')
    formDataViewExpensesStore.append('expenseID', '')
    formDataViewExpensesStore.append(
      'businessBranchNameID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewExpensesStore,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBtableViewExpensesStore(response.data)
      //alert(response.data)
    })
  }, [])

  /*KYC FROM DATABASE STARTS HERE*/
  useEffect(() => {
    //alert(phoneNumber)
    setHandleSubmitBtn('Loading')
    var formDataGetKyc = new FormData(this)
    formDataGetKyc.append('apiType', 'getViewCustomers')
    //formDataGetKyc.append('phoneNumber', phoneNumber)
    formDataGetKyc.append('businessNameID', secondUserSystemSetupID)
    formDataGetKyc.append('branchNameID', secondUserSystemSetupBranchID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataGetKyc,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //alert(response.data)
      setHandleSubmitBtn('')
      setViewCustomersTable(response.data)

      setTimeout(function () {
        $('#table').DataTable(
          {          
            retrieve: true, // this hides the errors
            pageLength: 10,
            lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
          
          }
        )
        addTableButtons()
      }, 1000)
      //setPhoneNumber(response.data.phone)
      /*setEmailKyc(response.data.email)
      setLastName(response.data.lastName)
      setFirstName(response.data.firstName)
      setPhysicalAddress(response.data.physicalAddress)*/
    })
    //.catch(err => alert('ERROR : Check Your Network Connection'))
  }, [])
  /*KYC FROM DATABASE ENDS HERE*/

  //initialize datatable
 /* $(function () {
    setTimeout(function () {
      $('#table').DataTable(
        {          
          retrieve: true, // this hides the errors
          pageLength: 10,
          lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
        
        }
      )
      addTableButtons()
    }, 10000)
  })*/

  function addTableButtons () {
    if (secondUserSystemSetupBranchID === '') {
      var table = $('#table').DataTable()

      new $.fn.dataTable.Buttons(table, {
        buttons: [
          {
            extend: 'copy',
            text: 'Copy <i class="fa fa-files-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5] }
          },
          {
            extend: 'excel',
            text: 'Excel <i class="fa fa-file-excel-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5] }
          },
          //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
          {
            extend: 'pdf',
            text: 'PDF <i class="fa fa-file-pdf-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5] }
          },
          {
            extend: 'print',
            text: 'Print <i class="fa fa-print"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5] }
          }
        ]
      })

      table.buttons(0, null).containers().appendTo('#filterTopCriteria')
    } else {
      var table = $('#table').DataTable()

      new $.fn.dataTable.Buttons(table, {
        buttons: [
          {
            extend: 'copy',
            text: 'Copy <i class="fa fa-files-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 5] }
          },
          {
            extend: 'excel',
            text: 'Excel <i class="fa fa-file-excel-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 5] }
          },
          //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
          {
            extend: 'pdf',
            text: 'PDF <i class="fa fa-file-pdf-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 5] }
          },
          {
            extend: 'print',
            text: 'Print <i class="fa fa-print"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 2, 3, 4, 5] }
          }
        ]
      })

      table.buttons(0, null).containers().appendTo('#filterTopCriteria')
    }
  }
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - View Business Customers -{' '}
        {systemSetupBranchIDStoreName}{' '}
      </title>
      <>
        <Headers />
      </>
      <title></title>
      {/*<!-- Preloader --> */}
      <div hidden = {handleSubmitBtn === ''}>
      <div id="overlayer"></div>
      <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    </div>

      <div>
        <body class='col-12'>
          {/* Main layout */}
          <main>
            <div class='container-fluid'>
              <section class='section mt-5'>
                {/*Grid row */}
                <div class='row'>
                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body' style={{ overflowX: 'auto' }}>
                        <h6 class='form-header white-text ocean blue-gradient mt-0 text-uppercase'>
                          View Customers {expensesStore}
                        </h6>

                        <center>
                          <div id='filterTopCriteria'></div>
                        </center>
                        <table
                          id='table'
                          class='table table-striped table-bordered table-hover table-sm nowrap'
                          width='100%'
                        >
                          <thead class='form-header text-uppercase ocean blue-gradient'>
                            {/*<tr>
<td colspan="6"><h1 class="card-header white-text">REGISTERED BUSINESSES</h1></td>
</tr>*/}
                            <tr>
                              <th>S/N</th>
                              <th hidden={secondUserSystemSetupBranchID != ''}>
                                Outlet
                              </th>
                              <th>Name</th>
                              <th>Phone</th>
                              <th>Email</th>
                              <th>Address</th>
                              <th>Bonus Points</th>
                              <th>Loyalty Points</th>
                            </tr>
                          </thead>
                          <tbody>
                            {viewCustomersTable.map((val, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td
                                    hidden={secondUserSystemSetupBranchID != ''}
                                  >
                                    {val.location}
                                  </td>
                                  <td>{val.name}</td>
                                  <td>{val.phone}</td>
                                  <td>{val.email}</td>
                                  <td>{val.physicalAddress}</td>
                                  <td>{val.bonusPoints}</td>
                                  <td>{val.loyaltyPoints}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
            </div>
          </main>
          {/* Main layout */}
        </body>
      </div>
    </>
  )
}

export default ViewCustomers
