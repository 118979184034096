import logo from './logo.svg'
//import './App.css'
import Register from './Register'
import Login from './Login'
import Home from './components/Home'
import Hme from './components/Hme'
import Layout from './components/Layout'
import Editor from './components/Editor'
import Admin from './components/Admin'
import Missing from './components/Missing'
import Unauthorized from './components/Unauthorized'
import Logout from './components/Logout'
import Linkage from './components/Linkage'
import Main from './components/Main'
import RegisterBusiness from './components/System_Setup'
import ViewBusinesses from './components/ViewBusinesses'
import RegisterBusinessBranch from './components/RegisterBusinessBranch'
import FileUploadTest from './components/FileUploadTest'
import Form1 from './components/Form1'
import RegisterUsers from './components/RegisterUsers'
import RegisterSuppliers from './components/RegisterSuppliers'
import RegisterProducts from './components/RegisterProducts'
import RegisterStock from './components/RegisterStock'
import ViewUsers from './components/ViewUsers'
import ViewSuppliers from './components/ViewSuppliers'
import ViewProducts from './components/ViewProducts'
import ViewStock from './components/ViewStock'
import Pos from './components/pos'
import DataTables from './components/DataTables'
import Tesseract from './components/Tesseract.tsx'
import LoginAdmin from './LoginAdmin'
import CollapsibleTable from './components/muidatatables'
import { Routes, Route } from 'react-router-dom'
import SalesReport from './components/SalesReport'
import CashReport from './components/CashReport'
import DebitCardReport from './components/DebitCardReport'
import MobileMoneyReport from './components/MobileMoneyReport'
import YellowReport from './components/YellowReport'
import XtendaReport from './components/XtendaReport'
import NHIMAReport from './components/NHIMAReport'
import ProfitReport from './components/ProfitReport'
import IncomeStatement from './components/IncomeStatement'
import RegisterShopCategory from './components/RegisterShopCategory'
import TESTPDF from './components/TESTPDF'
import UpdateProfile from './components/UpdateProfile'
import UpdatePassword from './components/UpdatePassword'
import ForgotPassword from './components/ForgotPassword'
import CompareProductSales from './components/CompareProductSales'
import StockTransfer from './components/StockTransfer'
import RegisterStoreExpensesTypes from './components/RegisterStoreExpensesTypes'
import RegisterStoreExpenses from './components/RegisterStoreExpenses'
import CloseStoreSales from './components/CloseStoreSales'
import StoreExpenses from './components/StoreExpenses'
import StoreExpensesDetailed from './components/StoreExpensesDetailed'
import ViewCustomers from './components/ViewCustomers'
import SetCustomerPoints from './components/SetCustomerPoints'
import ViewStockBarcode from './components/ViewStockBarcode'
import TestDataTables from './components/TestDataTables'
import Sales from './components/Sales'

function App () {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        {/* Public Routes*/}
        <Route path='Home' element={<Home />} />
        <Route path='Hme' element={<Hme />} />
        <Route path='RegisterBusiness' element={<RegisterBusiness />} />
        <Route path='ViewBusinesses' element={<ViewBusinesses />} />
        <Route
          path='RegisterBusinessBranch'
          element={<RegisterBusinessBranch />}
        />
        <Route path='MAIN' element={<Main />} />
        <Route path='register' element={<Register />} />
        <Route path='linkage' element={<Linkage />} />
        <Route path='unauthorised' element={<Unauthorized />} />
        <Route path='FileUploadTest' element={<FileUploadTest />} />
        <Route path='Form1' element={<Form1 />} />

        {/* We want To Protect These Routes*/}
        <Route path='/' element={<Login />} />
        <Route path='IncomeStatement' element={<IncomeStatement />} />
        <Route path='ProfitReport' element={<ProfitReport />} />
        <Route path='DebitCardReport' element={<DebitCardReport />} />
        <Route path='YellowReport' element={<YellowReport />} />
        <Route path='XtendaReport' element={<XtendaReport />} />
        <Route path='NHIMAReport' element={<NHIMAReport />} />
        <Route path='MobileMoneyReport' element={<MobileMoneyReport />} />
        <Route path='RegisterShopCategory' element={<RegisterShopCategory />} />
        <Route path='TESTPDF' element={<TESTPDF />} />

        <Route path='CashReport' element={<CashReport />} />
        <Route path='SalesReport' element={<SalesReport />} />
        <Route path='CollapsibleTable' element={<CollapsibleTable />} />
        <Route path='RegisterUsers' element={<RegisterUsers />} />
        <Route path='RegisterSuppliers' element={<RegisterSuppliers />} />
        <Route path='RegisterProducts' element={<RegisterProducts />} />
        <Route path='RegisterStock' element={<RegisterStock />} />
        <Route path='ViewUsers' element={<ViewUsers />} />
        <Route path='ViewSuppliers' element={<ViewSuppliers />} />
        <Route path='ViewProducts' element={<ViewProducts />} />
        <Route path='ViewStock' element={<ViewStock />} />
        <Route path='Pos' element={<Pos />} />
        <Route path='DataTables' element={<DataTables />} />
        <Route path='LoginAdmin' element={<LoginAdmin />} />
        <Route path='editor' element={<Editor />} />
        <Route path='admin' element={<Admin />} />
        <Route path='Logout' element={<Logout />} />
        <Route path='Tesseract' element={<Tesseract />} />
        <Route path='UpdateProfile' element={<UpdateProfile />} />
        <Route path='UpdatePassword' element={<UpdatePassword />} />
        <Route path='ForgotPassword' element={<ForgotPassword />} />
        <Route path='CompareProductSales' element={<CompareProductSales />} />
        <Route path='StockTransfer' element={<StockTransfer />} />
        <Route path='RegisterStoreExpensesTypes' element={<RegisterStoreExpensesTypes />} />
        <Route path='RegisterStoreExpenses' element={<RegisterStoreExpenses />} />
        <Route path='CloseStoreSales' element={<CloseStoreSales />} />
        <Route path='StoreExpenses' element={<StoreExpenses />} />
        <Route path='StoreExpensesDetailed' element={<StoreExpensesDetailed />} />
        <Route path='ViewCustomers' element={<ViewCustomers />} />
        <Route path='SetCustomerPoints' element={<SetCustomerPoints />} />
        <Route path='ViewStockBarcode' element={<ViewStockBarcode />} />
        <Route path='TestDataTables' element={<TestDataTables />} />
        <Route path='Sales' element={<Sales />} />
        {/* catch all*/}
        <Route path='*' element={<Missing />} />
      </Route>
    </Routes>
  )
}

export default App
