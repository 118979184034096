import { useRef, useState, useEffect, useContext } from 'react'
import Headers from './Headers'
import { useNavigate, redirect } from 'react-router-dom'
import Axios from '../api/axios'
import $ from 'jquery'
import { NumericFormat  } from 'react-number-format';
import { MDBContainer } from 'mdbreact'
import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter
} from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import Select from 'react-select'

import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import {
  TableCell,
  TableFooter,
  TableRow,
  makeStyles,
  Box
} from "@material-ui/core";

export const StoreExpensesDetailed = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [
    DBtableViewformDataTransferReport,
    setDBtableViewformDataTransferReport
  ] = useState([])

  const [posProductDropDown, setPosProductDropDown] = useState([])
  const [itemID, setItemID] = useState([{}])
  var [quantity, setQuantity] = useState('')

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  //if (quantity === '') {
  //quantity = 0
  //}
  //alert(quantity)
  const [quantityDB, setQuantityDB] = useState('')
  const [compareProductsPricesData, setCompareProductsPricesData] = useState([])
  const [compareProductsPricesLabel, setCompareProductsPricesLabel] = useState(
    []
  )
  const [businessBranchName, setBusinessBranchName] = useState('')
  const [businessBranchNameTo, setBusinessBranchNameTo] = useState('')
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )
  const [businessBranchNameDropDownTo, setBusinessBranchNameDropDownTo] =
    useState([])

  const [storeExpensesDropDown, setStoreExpensesDropDown] = useState([])
  const [DBtableViewExpenses, setDBtableViewExpenses] = useState([])
  const [expensesStore, setDBtableViewExpensesStore] = useState('')

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  let [handleSubmitBtnDelete, setHandleSubmitBtnDelete] = useState('')

  const HandelChangeItemDropDown = obj => {
    var rodeen = []
    obj.map(val => {
      rodeen.push(val.value)
    })
    localStorage.setItem('ItemDropDownValue', JSON.stringify(rodeen))
    setItemID(JSON.stringify(rodeen))
  }

  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const monthToday = `${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const firstUserSystemSetupID = localStorage.getItem('systemSetupID')
  let secondUserSystemSetupID = JSON.parse(firstUserSystemSetupID)

  const firstUserRole = localStorage.getItem('userRole')
  let secondUserRole = JSON.parse(firstUserRole)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  let businessCategory = localStorage.getItem('businessCategory')
  businessCategory = JSON.parse(businessCategory)

  //alert('Branch ID = ' + secondUserSystemSetupBranchID)


  useEffect(() => {
    //alert('ID' + secondUserSystemSetupBranchID)
    setHandleSubmitBtn('Loading')
    var formDataViewExpenses = new FormData(this)
    formDataViewExpenses.append('apiType', 'getViewExpensesDayClose')
    formDataViewExpenses.append('expenseID', '')
    formDataViewExpenses.append('businessNameID', secondUserSystemSetupID)
    formDataViewExpenses.append(
      'businessBranchNameID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewExpenses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      setDBtableViewExpenses(response.data)

      //alert(response.data)
    })
  }, [])

  useEffect(() => {
    var formDataViewExpensesStore = new FormData(this)
    formDataViewExpensesStore.append('apiType', 'getViewExpensesDayCloseStore')
    formDataViewExpensesStore.append('expenseID', '')
    formDataViewExpensesStore.append(
      'businessBranchNameID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewExpensesStore,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBtableViewExpensesStore(response.data)
      //alert(response.data)
    })
  }, [])

  

  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)

    
const columns = [
  { label : "SN",   name:'store_expensesID',  options: {     filter: true,     sort: true, 
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
       <span>
        {tableMeta.rowIndex + 1}
       </span>
      );
    }
  } ,
},
  {    name: "store",    label: "Store",    options: {     filter: true,     sort: true,
  } },
   { label: "Store Expense", name : "store_expense",    options: {     filter: true,     sort: true,    }},
   {  label: "Amount", name : "amount",    options: {     filter: true,     sort: true,    }},  
  {  label: "Time", name : "time",    options: {     filter: true,     sort: true,    }},
  { label : "Date", name : "date",    options: {     filter: true,     sort: true,    }},
  {  label : "Status", name : "transaction_status",    options: {     filter: true,     sort: true,    }},
   {  
    label : "User", name : "user",    options: {     filter: true,     sort: true,    }
  }, 

  
 
];

const options = {
  filter: true,
  filterType: 'dropdown',
  responsive: 'standard',
  fixedHeader: true,
  fixedSelectColumn: true,
  tableBodyHeight: '400px',
  selectableRowsHeader : false,
  selectableRows : 'none',
  rowsPerPageOptions : [5, 10, 15, 20, 50, 100, 200, 500, 1000, 5000, 10000, 20000, 50000, 100000],
  rowsPerPage : 5,
  searchAlwaysOpen : true,
  searchPlaceholder: 'Search Here',
  elevation : 4,
  onChangeRowsPerPage(numberOfRows) {
    setRowsPerPage(numberOfRows);
  },
  onChangePage(page) {
    setPage(page);
  },
  customTableBodyFooterRender: (opts) => {
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    let sumamount = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[3]);
      }, 0);
    return (
      <>
        {DBtableViewExpenses.length > 0 && (
          <TableFooter style={{backgroundColor:'gray'}}>
            <TableRow>
           
              {opts.columns.map((col, index) => {
                if (col.display === "true") {
                                     
                        if (col.name === "store_expensesID") {
                                      return (
                                        <TableCell key={index} > </TableCell>
                                      );                  
                                      }
                        else if (col.name === "store") {
                                        return (
                                          <TableCell key={index} > </TableCell>
                                        );                  
                                        }

                  else if (col.name === "store_expense") {
                    return (
                      <TableCell key={index} >
                        Total
                      </TableCell>
                    );
                  } else if (col.name === "amount") {
                    return (
                      <TableCell key={index} >
                        {sumamount}
                      </TableCell>
                    );
                    }
                    else if (col.name === "time") {
                      return (
                        <TableCell key={index} > </TableCell>
                      );                  
                      }
                      else if (col.name === "date") {
                        return (
                          <TableCell key={index} > </TableCell>
                        );                  
                        }
                        else if (col.name === "transaction_status") {
                          return (
                            <TableCell key={index} > </TableCell>
                          );                  
                          }
                          else if (col.name === "user") {
                            return (
                              <TableCell key={index} > </TableCell>
                            );                  
                            }
                }
              })}
            </TableRow>
          </TableFooter>
        )}
      </>
    );
  }
  
};

  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - View Detailed Store Expenses -{' '}
        {systemSetupBranchIDStoreName}
      </title>
      <>
        <Headers />
      </>
      <title></title>
      {/*<!-- Preloader --> */}
      <div hidden = {handleSubmitBtn === ''}>
      <div id="overlayer"></div>
      <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    </div>

    <div hidden={handleSubmitBtnDelete ===''}>
        <div class="loader d-flex justify-content-center">          
          <div class="spinner-border text-danger" role="status" style={{width: '10rem', height: '10rem'}}>
          <span class=''>Loading...</span>
</div>
        </div>
        </div>


      <div>
        <body class='col-12'>
          {/* Main layout */}
          <main>
            <div class='container-fluid'>
              <section class='section mt-5'>
                {/*Grid row */}
                <div class='row'>
                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body' style={{ overflowX: 'auto' }}>
                        <h6 class='form-header white-text ocean blue-gradient mt-0 text-uppercase'>
                          STORE EXPENSES - DETAILED {expensesStore}
                        </h6>

{/*
                        <center>
                          <div id='filterTopCriteria'></div>
                        </center>
                        <table
                          id='table'
                          class='table table-striped table-bordered table-hover table-sm nowrap'
                          width='100%'
                        >
                          <thead class='form-header text-uppercase ocean blue-gradient'>
        
                            <tr>
                              <th>S/N</th>
                              <th hidden={secondUserSystemSetupBranchID != ''}>
                                Store
                              </th>
                              <th>Store Expense</th>
                              <th>Amount</th>
                              <th>Time</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>User</th>
                              <th
                                hidden={
                                  secondUserPosition != 'Business Owner' &&
                                  secondUserRole != 'System Administrator'
                                }
                              >
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {DBtableViewExpenses.map((val, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td
                                    hidden={secondUserSystemSetupBranchID != ''}
                                  >
                                    {val.store}
                                  </td>
                                  <td>{val.store_expense}</td>
                                  <td>{val.amount}</td>
                                  <td>{val.time}</td>
                                  <td>{val.date}</td>
                                  <td>{val.transaction_status}</td>
                                  <td>{val.user}</td>
                                  <td
                                    hidden={
                                      secondUserPosition != 'Business Owner' &&
                                      secondUserRole != 'System Administrator'
                                    }
                                  >
                                    <span
                                      onClick={() => {
                                        deleteRecord(val.store_expensesID)
                                      }}
                                      type='button'
                                      class='fa fa-trash red-text'
                                      title='Delete This Record From The Database'

                                      disabled={handleSubmitBtnDelete === 'Loading'}
                                    ></span>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                          <tfoot class='form-header text-center ocean blue-gradient'>
                            <tr>
                              <th></th>
                              <th
                                hidden={secondUserSystemSetupBranchID != ''}
                              ></th>
                              <th>TOTALS</th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th
                              hidden={
                                secondUserPosition != 'Business Owner' &&
                                secondUserRole != 'System Administrator'
                              }
                              ></th>
                            </tr>
                          </tfoot>
                        </table>
                        
                            */}

                      
                      
<Paper>
          <MUIDataTable
  data={DBtableViewExpenses}
  columns={columns}
  options={options}
/>     
  
  </Paper>


                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
            </div>
          </main>
          {/* Main layout */}
        </body>
      </div>
    </>
  )
}

export default StoreExpensesDetailed
