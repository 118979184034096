import { useRef, useState, useEffect, Button } from 'react'
import { useNavigate } from 'react-router-dom'
import $, { nodeName } from 'jquery'
import 'jquery/dist/jquery.min.js'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import Headers from './Headers'
//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

const RegisterBusiness = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const BusinessNameRef = useRef()
  const errRef = useRef()

  //const [logo, setLogo] = useState('');
  //const [pacraCertificate, setPacraCertificate] = useState('');
  const [BusinessName, setBusinessName] = useState('')
  const [BusinessID, setBusinessID] = useState('')
  const [branch, setBranch] = useState('')
  const [pacraID, setPacraID] = useState('')
  const [address, setAddress] = useState('')
  const [ip, setIP] = useState('')
  const [shopType, setShopType] = useState('')
  let [businessCategory, setBusinessCategory] = useState('')
  const [businessCategoryDropDown, setBusinessCategoryDropDown] = useState([])
  const [otherShopCategory, setOtherShopCategory] = useState('')
  const [phone, setPhone] = useState('')
  const [validPhone, setValidPhone] = useState(false)
  const [phoneFocus, setPhoneFocus] = useState(false)

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)
  //const [pwd, setPwd] = useState('');
  //const [validPwd, setValidPwd] = useState(false);
  //const [pwdFocus, setPwdFocus] = useState(false);

  //const [matchPwd, setMatchPwd] = useState("Q2MRmYkf3du4yWZ@");
  ///const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  //const [success, setSuccess ] = useState(false);

  const [logoFile, setLogoFile] = useState()
  const [logoFileName, setLogoFileName] = useState('')
  const [pacraCertificateFile, setPacraCertificateFile] = useState()
  const [pacraCertificateFileName, setPacraCertificateFileName] = useState('')

  useEffect(() => {
    BusinessNameRef.current.focus()
  }, [])

  useEffect(() => {
    const result = PHONE_REGEX.test(phone)
    setValidPhone(result)
  }, [phone])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  /*useEffect( () => {
    const result = PWD_REGEX.test(pwd);
	setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
},
[pwd, matchPwd])*/

  useEffect(() => {
    setErrMsg('')
  }, [phone, email])

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  /*const [logoFile, setLogoFile] = useState();
  const [logoFileName, setLogoFileName] = useState("");

  const [pacraCertificateFile, setPacraCertificateFile] = useState();
  const [pacraCertificateFileName, setPacraCertificateFileName] = useState("");
 
      const saveLogoFile = (e) => {
        setLogoFile(e.target.files[0]);
        setLogoFileName(e.target.files[0].name);
      };
	  
      const savePacraCertificateFile = (e) => {
        setPacraCertificateFile(e.target.files[0]);
        setPacraCertificateFileName(e.target.files[0].name);
      };*/
  const saveLogoFile = e => {
    setLogoFile(e.target.files[0])
    setLogoFileName(e.target.files[0].name)
  }

  const savePacraCertificateFile = e => {
    setPacraCertificateFile(e.target.files[0])
    setPacraCertificateFileName(e.target.files[0].name)
  }

  useEffect(() => {
    var formDataShopCategory = new FormData(this)
    formDataShopCategory.append('apiType', 'getBusinessCategoryDropDown')
    //Axios.get(`${server_app_api}/api/getBusinessCategoryDropDown`).then(
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php',
      data: formDataShopCategory,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessCategoryDropDown(response.data)
      //console.log(response);
    })
  }, [])
  /*const	handleSubmit = (e)=> {
		//const handleSubmit= async(e)=>{
			//e.preventDefault();

			//$(document).ready(function (e) {
				//$('#imageUploadForm').on('submit',(function(e) {
					e.preventDefault();
					var formData = new FormData(this);
			
					$.ajax({
						type:'POST',
						crossDomain: true,
						url: "http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php",
						
						data:formData,
						cache:false,
						contentType: false,
						processData: false,
						success:function(data){
							//console.log("success");
							//alert('success');
							alert(data);
							//$('#divShowMessageFromAjax').html(data);
							$('#ip').val('');
						
						},
						error: function(data){
							alert('error');
							alert(data);
							//console.log("error");
							//console.log(data);
						}
					});*/
  //}));

  //});

  //alert("Uploading");
  const handleSubmit = async e => {
    e.preventDefault()
    var formData = new FormData(this)
    // handle validations
    /*  const logo = e.target.files[0];
  if (logo.size > 1024){
    onLogoFileSelectError({ error: "File size cannot exceed more than 1MB" });
  }else{ onLogoFileSelectSuccess(logo);}

  const pacraCertificate = e.target.files[0];
  if (pacraCertificate.size > 1024){
    onPacraCertificateFileSelectError({ error: "File size cannot exceed more than 1MB" });
  }else{ onPacraCertificateFileSelectSuccess(pacraCertificate);}
*/
    formData.append('logoFile', logoFile)
    formData.append('logoFileName', logoFileName)
    formData.append('pacraCertificateFile', pacraCertificateFile)
    formData.append('pacraCertificateFileName', pacraCertificateFileName)

    formData.append('businessName', BusinessName)
    formData.append('businessID', BusinessID)
    formData.append('pacraID', pacraID)
    formData.append('address', address)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('businessCategory', businessCategory)
    formData.append('shopType', shopType)
    formData.append('otherShopCategory', otherShopCategory)
    formData.append('system_setup_IP', ip)

    /*//Axios.post("http://localhost:3001/api/insert", {
		Axios.post("http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php", {
        lname : user, 
        fname : pwd,
    });*/

    Axios({
      method: 'post',
      url: `${server_app_api_php}/registerBusiness.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
      })
      .catch(function (response) {
        //handle error
        alert('ERROR : Kindly Contact Your Adminitrator')
      })

    /*Axios.post("http://localhost:3001/api/insertRegisterBusiness", formData).then((res) => {

      alert("Result : "+res);

    })

    .catch((err) => alert("ERROR MESSAGE : "+err));	*/

    /* if(businessCategory ==="Others")
   {
	businessCategory = otherShopCategory;
   }*/
    /*Axios.post(`${server_app_api}/api/insertRegisterBusiness`, {
        
		businessName : BusinessName,
		branch : branch,
		pacraID : pacraID,
		address : address,
		phone : phone,
		email : email,
		businessCategory : businessCategory,
		shopType: shopType,
		system_setup_IP : ip,
		
    })*/
    //.then((res) => {	alert(res.data);

    //})
    //.catch((err) => alert("ERROR : Kindly Contact Your Adminitrator"));
    //.catch((err) => alert("Error : " +err));

    //alert ("Successfully Uploaded");
    /*return(
	<div class="alert alert-success"><button type="button" class="close" data-dismiss="alert">&times;</button>
Successfully Uploaded</div>
	)*/

    //console.log(user);
    //console.log(pwd);
    //alert ("Successfully Uploaded : " +pacraCertificateFileName);
    //alert(JSON.stringify(formData));
  }

  /*;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()*/
  return (
    <>
      <title>KANCH INNOVATIVE TECHNOLOGIES - Register A Business</title>
      <>
        <Headers />
      </>
      {/*<!-- Preloader 
      <div id='overlayer'></div>
      <div class='loader'>
        <div class='spinner-border' role='status'>
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div>
      -->*/}
      <main>
        <form method='POST'>
          <div class='container-fluid'>
            <div class='row'>
              <div class='col-md-12 mb-2'>
                <div class='card'>
                  <div class='card-body' id='faults'>
                    <div id='divShowMessageFromAjax'></div>
                    <p
                      ref={errRef}
                      className={errMsg ? 'errmsg' : 'offscreen'}
                      aria-live='assertive'
                    >
                      {' '}
                      {errMsg}{' '}
                    </p>
                    <div class='form-header white-text'>
                      {' '}
                      REGISTER BUSSINESS HERE{' '}
                    </div>
                    <div class='row'>
                      <div class='col-lg-6 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label>Business Logo </label>
                          <br />
                          <br />
                          <input
                            type='file'
                            class='form-control'
                            name='logo'
                            id='logo'
                            onChange={saveLogoFile}
                          />
                        </div>
                      </div>

                      <div class='col-lg-6 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label> PACRA Certificate</label>
                          <br />
                          <br />
                          <input
                            type='file'
                            class='form-control'
                            name='pacraCertificate'
                            id='pacraCertificate'
                            onChange={savePacraCertificateFile}
                          />
                        </div>
                      </div>
                    </div>
                    {/*End Of Row 1*/}
                    <input
                      type='hidden'
                      class='form-control'
                      id='ip'
                      value={ip}
                    />
                    <div class='row'>
                      <div class='col-lg-6 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Business Name <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            name='BusinessName'
                            id='BusinessName'
                            onChange={e => setBusinessName(e.target.value)}
                            ref={BusinessNameRef}
                            autoComplete='off'
                            value={BusinessName}
                            required
                          />
                        </div>
                      </div>

                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Business Identification Number{' '}
                            <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            name='BusinessID'
                            id='BusinessID'
                            onChange={e => setBusinessID(e.target.value)}
                            autoComplete='off'
                            value={BusinessID}
                            required
                          />
                        </div>
                      </div>

                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Phone <i class='red-text'>*</i>
                            <span className={validPhone ? 'valid' : 'hide'}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span
                              className={
                                validPhone || !phone ? 'hide' : 'invalid'
                              }
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </span>
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            name='phone'
                            id='phone'
                            onChange={e => setPhone(e.target.value)}
                            autoComplete='off'
                            value={phone}
                            required
                            aria-invalid={validPhone ? 'false' : 'true'}
                            aria-describedby='phonenote'
                            onFocus={() => setPhoneFocus(true)}
                            onBlur={() => setPhoneFocus(false)}
                          />
                          <p
                            id='phonenote'
                            className={
                              phoneFocus && phone && !validPhone
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            there should be 10 or more, but less than 14
                            numerical values. <br />
                            You can also add + followed by your country or area
                            phone number code.
                          </p>
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 2*/}
                    <div class='row'>
                      <div class='col-lg-6 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label>
                            Email <i class='red-text'>*</i>
                            <span className={validEmail ? 'valid' : 'hide'}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span
                              className={
                                validEmail || !email ? 'hide' : 'invalid'
                              }
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </span>
                          </label>
                          <br />

                          <input
                            type='text'
                            class='form-control'
                            name='email'
                            onChange={e => setEmail(e.target.value)}
                            autoComplete='off'
                            value={email}
                            required
                            aria-invalid={validEmail ? 'false' : 'true'}
                            aria-describedby='emailnote'
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          />
                          <p
                            id='emailnote'
                            className={
                              emailFocus && email && !validEmail
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            4 to 24 characters. <br />
                            Let It Be A Valid Email Address.
                          </p>
                        </div>
                      </div>

                      {/*<div class="col-lg-3 col-md-12 mb-1">
				  <div class="md-form">
						<label for="registerFullName">Branch Name<i class="red-text">*</i></label><br />
						<input  type="text" class="form-control"  id="branch"  onChange={(e) => setBranch(e.target.value)} 
						autoComplete = "off" value={branch} required
						/>
					</div>
					</div>	
									
					<div class="col-lg-3 col-md-12 mb-1">
				  <div class="md-form">
						<label for="registerFullName">PACRA Business ID
						
						</label><br />
						<input  type="text" class="form-control"  id="pacraID" onChange={(e) => setPacraID(e.target.value)}
						autoComplete = "off" value={pacraID}
						/>
						
					</div>
					</div>*/}

                      {/*<div class="col-lg-3 col-md-12 mb-1">
				  <div class="md-form">
						<label>Password <i class="red-text">*</i> 
						<span className={validPwd ? "valid" : "hide"} > 
                    <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validPwd || !pwd ? "hide" : "invalid"} > 
                    <FontAwesomeIcon icon={faTimes} />
                    </span>
						</label><br />
						<input  type="password" class="form-control"  id="password" onChange={(e) => setPwd(e.target.value)}
						required
aria-invalid={validPwd ? "false" : "true"}
        aria-describedby = "pwdnote"
        onFocus={() => setPwdFocus(true)}
        onBlur={() => setPwdFocus(false)}
						/>
						
						  <p
                        id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"} >
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            Password Should be 8 to 24 characters. <br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters : <span aria-label = "exclamation mark">!</span>
                            <span aria-label = "at symbol">@</span>
                            <span aria-label = "hashtag">#</span>
                            <span aria-label = "dollar sign">$</span>
                            <span aria-label = "percent">%</span>
                    </p>
					</div>
					</div>
					
					<div class="col-lg-3 col-md-12 mb-1">
				  <div class="md-form">
						<label for="registerFullName">Repeat Password <i class="red-text">*</i>
						<span className={validMatch && matchPwd ? "valid" : "hide"} > 
                    <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validMatch || !matchPwd ? "hide" : "invalid"} > 
                    <FontAwesomeIcon icon={faTimes} />
                    </span>

						</label><br />
						<input  type="password" class="form-control"  id="repeatPassword" onChange={(e) => setMatchPwd(e.target.value)}
						
						 required
aria-invalid={validMatch ? "false" : "true"}
        aria-describedby = "confirmnote"
        onFocus={() => setMatchFocus(true)}
        onBlur={() => setMatchFocus(false)}

						/>
						  <p
                        id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"} >
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            Must match the first password input field.
                    </p>
					</div>
					</div>*/}

                      <div class='col-lg-2 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label>
                            Shop Type <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <br />
                          <select
                            class='custom-select d-block w-100'
                            name='shopType'
                            onChange={e => setShopType(e.target.value)}
                            autoComplete='off'
                            required
                          >
                            <option value=''>Choose ...</option>
                            <option>Retailer</option>
                            <option>Wholeseller</option>
                            <option>Manufacturer</option>
                          </select>
                        </div>
                      </div>

                      <div class='col-lg-4 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label>
                            Business Category <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <br />
                          <select
                            class='custom-select d-block w-100'
                            name='businessCategory'
                            onChange={e => setBusinessCategory(e.target.value)}
                          >
                            <option value=''>Choose....</option>
                            {businessCategoryDropDown.map(val => {
                              return (
                                <option value={val.shop_categoryID}>
                                  {val.category}{' '}
                                </option>
                              )
                            })}

                            <option>Others</option>
                          </select>
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 3*/}
                    {/*Start Of Row 4*/}
                    <div class='row' hidden={businessCategory != 'Others'}>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label>
                            State Your Shop Category <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            name='otherShopCategory'
                            onChange={e => setOtherShopCategory(e.target.value)}
                            autoComplete='off'
                            value={otherShopCategory}
                            required
                          />
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 4*/}
                    <div class='row'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Address <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <textarea
                            type='text'
                            class='form-control'
                            name='address'
                            onChange={e => setAddress(e.target.value)}
                            autoComplete='off'
                            value={address}
                            required
                          />
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 4*/}
                    <p>
                      <span class='red-text'>* Required</span>{' '}
                      <span>- Do not leave Blank</span>
                    </p>
                    <hr class='mb-1' />
                    <button
                      type='submit'
                      class='btn btn-primary'
                      onClick={handleSubmit}
                      disabled={
                        BusinessName === '' ||
                        address === '' ||
                        !validPhone ||
                        !validEmail
                      }
                    >
                      Submit{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*</form>*/}
        </form>
      </main>
    </>
  )
}

export default RegisterBusiness
