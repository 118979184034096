import { useRef, useState, useEffect } from 'react'
import { useNavigate, redirect } from 'react-router-dom'
import Headers from './Headers'
import { NumericFormat  } from 'react-number-format';
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
/*import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'*/
import $ from 'jquery'

import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";

import {
  TableCell,
  TableFooter,
  TableRow,
  makeStyles,
  Box
} from "@material-ui/core";

const CashReport = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])
  let loggedIn = sessionStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)
  if (loggedIn === 'No' || loggedIn ==='') {
    navigate('/Logout')
  }

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const userRef = useRef()
  const errRef = useRef()

  const [DBtableViewCashReport, setDBtableViewCashReport] = useState([])
  const [businessBranchName, setBusinessBranchName] = useState('All')
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  const [update, setUpdate] = useState('')

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  //alert("id = "+systemSetupIDInitial);
  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getViewCashReport`, {
      businessNameID: systemSetupIDInitial
    })*/
    setHandleSubmitBtn('Loading')
    var formDataCashReport = new FormData(this)
    formDataCashReport.append('apiType', 'getViewCashReport')
    if (
      secondUserPosition === 'Business Owner' ||
      secondUserPosition === 'Auditor'
    ) {
      formDataCashReport.append('businessNameID', systemSetupIDInitial)
    }
    if (secondUserPosition === 'Manager') {
      formDataCashReport.append(
        'businessBranchID',
        secondUserSystemSetupBranchID
      )
    }
    if (secondUserPosition === 'Cashier') {
      formDataCashReport.append(
        'businessBranchIDCashier',
        secondUserSystemSetupBranchID
      )
      formDataCashReport.append('userID', secondUserID)
    }
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataCashReport,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      setDBtableViewCashReport(response.data)

    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  const UpdateRecord = system_setupID => {
    Axios.put(`${server_app_api}/api/update`, {
      id: system_setupID,
      lname: update
    })
    setUpdate('')
  }

  const deleteRecord = system_setupID => {
    Axios.delete(`${server_app_api}/api/delete/${system_setupID}`)
    alert('Successfully Deleted Record')
  }

  var totalProfit = 0


  

  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  
  const columns = [
    { label : "SN",   name:'store_expensesID',  options: {     filter: true,     sort: true, 
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
         <span>
          {tableMeta.rowIndex + 1}
         </span>
        );
      }
    } ,
  },
    {    label: "Store", name: "branch_name",        options: {     filter: true,     sort: true,
    } },
     { label: "Date", name : "pos_date",    options: {     filter: true,     sort: true,    }},
     {  label: "Item", name : "product",    options: {     filter: true,     sort: true,    }},  
    {  label: "Quantity", name : "unit_quantity",    options: {     filter: true,     sort: true,    }},
    { label : "Unit Cost", name : "product_unit_cost",    options: {     filter: true,     sort: true,    }},
    {  label : "unit Price", name : "product_unit_price",    options: {     filter: true,     sort: true,    }},
     {  
      label : "Total Sale", name : "product_total_price",    options: {     filter: true,     sort: true,    }
    }, 
    {  
      label : "Profit", name : "profit",    options: {     filter: true,     sort: true,    }
    },
    { label : "Cashier", name : "cashier",    options: {     filter: true,     sort: true, 
     
    } ,
     },
     

  
    
   
  ];
  
  
  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: '400px',
    selectableRowsHeader : false,
    selectableRows : 'none',
    rowsPerPageOptions : [10, 15, 20, 50, 100, 200, 500, 1000, 5000, 10000, 20000, 50000, 100000],
    //rowsPerPage : 5,
    searchAlwaysOpen : true,
    searchPlaceholder: 'Search Here',
    elevation : 4,
    onChangeRowsPerPage(numberOfRows) {
      setRowsPerPage(numberOfRows);
    },
    onChangePage(page) {
      setPage(page);
    },
    customTableBodyFooterRender: (opts) => {
      const startIndex = page * rowsPerPage;
      const endIndex = (page + 1) * rowsPerPage;
        let sumunit_quantity = opts.data
        .slice(startIndex, endIndex)
        .reduce((accu, item) => {
          return accu + parseFloat(item.data[4]);
        }, 0);
        let sumproduct_unit_cost = opts.data
        .slice(startIndex, endIndex)
        .reduce((accu, item) => {
          return accu + parseFloat(item.data[5]);
        }, 0);
        let sumproduct_unit_price = opts.data
        .slice(startIndex, endIndex)
        .reduce((accu, item) => {
          return accu + parseFloat(item.data[6]);
        }, 0);
        let sumproduct_total_price = opts.data
        .slice(startIndex, endIndex)
        .reduce((accu, item) => {
          return accu + parseFloat(item.data[7]);
        }, 0);
        let sumprofit = opts.data
        .slice(startIndex, endIndex)
        .reduce((accu, item) => {
          return accu + parseFloat(item.data[8]);
        }, 0);
      return (
        <>
          {DBtableViewCashReport.length > 0 && (
            <TableFooter style={{backgroundColor:'gray'}}>
              <TableRow>
             
                {opts.columns.map((col, index) => {
                  if (col.display === "true") {
                                       
                          if (col.name === "store_expensesID") {
                                        return (
                                          <TableCell key={index} > </TableCell>
                                        );                  
                                        }
                          else if (col.name === "branch_name") {
                                          return (
                                            <TableCell key={index} > </TableCell>
                                          );                  
                                          }
  
                                          else if (col.name === "pos_date") {
                                            return (
                                              <TableCell key={index} > </TableCell>
                                            );                  
                                            }
                    else if (col.name === "product") {
                      return (
                        <TableCell key={index} >
                          Total
                        </TableCell>
                      );
                    } 
                      else if (col.name === "unit_quantity") {
                        return (
                          <TableCell key={index} >
                            {sumunit_quantity}
                          </TableCell>
                        );
                        }
                        else if (col.name === "product_unit_cost") {
                          return (
                            <TableCell key={index} >
                              {sumproduct_unit_cost}
                            </TableCell>
                          );
                          }
                          else if (col.name === "product_unit_price") {
                            return (
                              <TableCell key={index} >
                                {sumproduct_unit_price}
                              </TableCell>
                            );
                            }
                            else if (col.name === "product_total_price") {
                              return (
                                <TableCell key={index} >
                                  {sumproduct_total_price}
                                </TableCell>
                              );
                              }
                              else if (col.name === "profit") {
                                return (
                                  <TableCell key={index} >
                                    {sumprofit}
                                  </TableCell>
                                );
                                }
                      else if (col.name === "cashier") {
                        return (
                          <TableCell key={index} > </TableCell>
                        );                  
                        }
                        
                      }
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    }
  };

  return (
    <header>
      <>
        <title>
          KANCH INNOVATIVE TECHNOLOGIES - Sales By Cash Report -{' '}
          {systemSetupBranchIDStoreName}{' '}
        </title>
        <>
          <Headers />
        </>
        {/*<!-- Preloader --> */}
        <div hidden={handleSubmitBtn === ''}>
        <div id='overlayer'></div>
        <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
            <span class='visually-hidden'>Loading...</span>
          </div>
        </div>
        </div>
        
        <section class=''>
          <div class=' d-flex justify-content-center align-items-center'>
            <div class='container col-12'>
              <div class='card mt-5'>
                <div class='card-body'>
                  {/*<div class="text-center">  <p > <a href="Home" class="blue-text"> Home</a> </p> </div> */}

                  {/* Data Table View Starts Here*/}
                  <div class=''>
                    {/*<div class="row">*/}
                    <br />
                    <h6 class='form-header white-text ocean blue-gradient'>
                      CASH REPORT
                    </h6>

                    {/*
                    <center>
                      <div id='filterTopCriteria'></div>
                    </center>
                    <table
                      id='table'
                      class='table table-striped table-bordered table-hover table-sm'
                    >
                      <thead class='form-header text-uppercase ocean blue-gradient'>
         
                        <tr>

                          <th>S/N</th>
                          <th
                            hidden={
                              secondUserPosition === 'Cashier' ||
                              secondUserPosition === 'Manager'
                            }
                          >
                            Branch
                          </th>
                          <th>Date</th>
                          <th>item</th>
                          <th>Quantity</th>
                          <th hidden={secondUserPosition === 'Cashier'}>
                            Unit Cost
                          </th>
                          <th>unit Price</th>
                          <th>Total Sale</th>
                          <th hidden={secondUserPosition === 'Cashier'}>
                            profit
                          </th>
                          <th hidden={secondUserPosition === 'Cashier'}>
                            Cashier
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {DBtableViewCashReport.map((val, index) => {
                          const profit1 =
                            val.unit_quantity * val.product_unit_cost
                          const profit = val.product_total_price - profit1
                          totalProfit =
                            totalProfit + parseInt(val.product_total_price)
                          return (
                            <tr>
                            
                              <td>{index + 1}</td>
                              <td
                                hidden={
                                  secondUserPosition === 'Cashier' ||
                                  secondUserPosition === 'Manager'
                                }
                              >
                                {val.branch_name}
                              </td>
                              <td>{val.pos_date}</td>
                              <td>{val.product}</td>
                              <td>{val.unit_quantity}</td>
                              <td hidden={secondUserPosition === 'Cashier'}>
                                {val.product_unit_cost}
                              </td>
                              <td>{val.product_unit_price}</td>
                              <td>{val.product_total_price}
                              </td>
                              <td hidden={secondUserPosition === 'Cashier'}>
                                {profit}
                              </td>
                              <td hidden={secondUserPosition === 'Cashier'}>
                                {val.Last_name} {val.first_name}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tfoot class='form-header text-center ocean blue-gradient'>
                        <tr>
                          <th></th>
                          <th
                            hidden={
                              secondUserPosition === 'Cashier' ||
                              secondUserPosition === 'Manager'
                            }
                          ></th>
                          <th></th>
                          <th>TOTALS</th>
                          <th></th>
                          <th hidden={secondUserPosition === 'Cashier'}></th>
                          <th></th>
                          <th></th>
                          <th hidden={secondUserPosition === 'Cashier'}></th>
                          <th hidden={secondUserPosition === 'Cashier'}></th>
                        </tr>
                      </tfoot>
                    </table>

                    <table id='table' style={{ float: 'right' }}>
                      <tr>
                        <td colspan='7'></td>{' '}
                        <td>
                          <h3>
                            <u>
                              <b>TOTAL SALES</b>
                            </u>
                          </h3>{' '}
                        </td>{' '}
                        <td> = </td>{' '}
                        <td>
                          <h3>
                            <b>
                            <NumericFormat value={totalProfit} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                            </b>
                          </h3>
                        </td>
                      </tr>
                    </table>

                    */}

<Paper>
          <MUIDataTable
  data={DBtableViewCashReport}
  columns={columns}
  options={options}
/>     
  
  </Paper>

                  </div>
                  {/* Data Table View Ends Here*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </header>
  )
}

export default CashReport
