import {useRef, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import '../App.css';
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from '../api/axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

const IncomeStatement = () => {
  let navigate = useNavigate();
  const firstUserLoggedInSessionExpired = localStorage.getItem("loggedIn");
  let secondUserLoggedInSessionExpired = JSON.parse(firstUserLoggedInSessionExpired);
   useEffect(() => {
  if(secondUserLoggedInSessionExpired === "No")
  {
    navigate('/Logout');
  }

}, []);
const userRef = useRef();
const errRef = useRef();


const [DBtableViewSalesReport, setDBtableViewSalesReport] = useState([]);

const [update, setUpdate] = useState('');

const firstSystemSetupID = localStorage.getItem("systemSetupID");
const systemSetupIDInitial = JSON.parse(firstSystemSetupID);
//alert("id = "+systemSetupIDInitial);
useEffect( () => {
    Axios.post("https://sims.app.kanchinnotech.nl/api/getViewProfitReport", {
              businessNameID : systemSetupIDInitial,		
}).then((response) => { 
    setDBtableViewSalesReport(response.data);
})//.catch((err) => alert("ERROR : Check Your Network Connection"));
      },
      [])

const UpdateRecord = (system_setupID) => {
    Axios.put("https://sims.app.kanchinnotech.nl/api/update",{
        id : system_setupID, 
        lname : update,
    });
    setUpdate('');
}

const deleteRecord = (system_setupID) => {
    Axios.delete(`https://sims.app.kanchinnotech.nl/api/delete/${system_setupID}`);
    alert ("Successfully Deleted Record");
}

if (!$.fn.DataTable.isDataTable("#myTable")) {
            $(document).ready(function () {
              setTimeout(function () {
                $("#table").dataTable().fnDestroy();
                $("#table").DataTable({
                  pagingType: "full_numbers",
                  pageLength: 5,
                  processing: true,
                  dom: "Bt",
                  select: {
                    style: "single",
                  },
                  
                  buttons: [
                                       {
                      extend: "csv",
                      className: "btn btn-primary bg-info",
                    },
                    {
                      extend: "print",
                      customize: function (win) {
                        $(win.document.body).css("font-size", "10pt");
                        $(win.document.body)
                          .find("table")
                          .addClass("compact")
                          .css("font-size", "inherit");
                      },
                      className: "btn btn-primary bg-info",
                    },
					
                  ],
      
                  fnRowCallback: function (
                    nRow,
                    aData,
                    iDisplayIndex,
                    iDisplayIndexFull
                  ) {
                    var index = iDisplayIndexFull + 1;
                    $("td:first", nRow).html(index);
                    return nRow;
                  },
      
                  lengthMenu: [
                    [5, 10, 50, 100, 500, 1000, 5000, 10000, -1],
                    [5, 10, 50, 100, 500, 1000, 5000, 10000, "All"],
                  ],
                  columnDefs: [
                    {
                      targets: 0,
                      render: function (data, type, row, meta) {
                        return type === "export" ? meta.row + 1 : data;
                      },
                    },
                  ],
                });
              }, 1);
            });
          }

          var totalProfit = 0;
          (function () {

            var preloader = function() {
            
              var loader = document.querySelector('.loader');
              var overlay = document.getElementById('overlayer');
            
              function fadeOut(el) {
              el.style.opacity = 1;
              (function fade() {
                if ((el.style.opacity -= .1) < 0) {
                el.style.display = "none";
                } else {
                requestAnimationFrame(fade);
                }
              })();
              };
            
              setTimeout(function() {
              fadeOut(loader);
              fadeOut(overlay);
              }, 200);
            };
            preloader();
            
            })()

    return(
	 <header>
	<>
   {/*<!-- Preloader -->*/}
   <div id="overlayer"></div>
    <div class="loader">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div> 

       <section class="fixed-sn white-skin">
<div class=" d-flex justify-content-center align-items-center">
  <div class="container">
    <div class="card">
    <div class="card-body">
  {/*<div class="text-center">  <p > <a href="Home" class="blue-text"> Home</a> </p> </div> */}
				  
{/* Data Table View Starts Here*/}
<div class="">
{/*<div class="row">*/} 
<br />
<h6 class="form-header white-text bg-info">INCOME STATEMENT</h6>
<table id="" class="table table-striped table-bordered table-hover table-sm">

{/*<thead class="form-header text-uppercase">
    <tr>
    <th>S/N</th>
    <th>Date</th>
    <th>item</th>
    <th>Quantity</th>
    </tr>
</thead>*/}
<tbody>
    <tr><td>SALES</td> <td></td><td></td><td></td> </tr>
    <tr><td>LESS COST OF SALES</td> <td></td><td></td><td></td> </tr>
    <tr><td>GROSS PROFIT</td> <td></td> <td></td> <td></td> </tr>
    <tr> <td colspan='4'> <center> <h3><b>EXPENSES</b></h3> </center></td> </tr>
    
{
    
    DBtableViewSalesReport.map((val, index)=>{
        const profit1 = val.unit_quantity * val.product_unit_cost;
        const profit = val.product_total_price - profit1;
        totalProfit = totalProfit + profit;
return (
  <>
  
<tr>
{/*system_setupID`, `system_setup_branchID`, `Last_name`, `first_name`, `email`, `phone`, 
`image`, `password`, `thumb_print`, `position`, `role`,   */}
    <td>{index +1}</td>
    {/*<td>{val.organisation}</td>*/}
    <td>{val.product_unit_price}</td>
    <td>{val.product}</td>
    <td>{val.unit_quantity}</td>
</tr>
</>
    );
    })
    }
   <tr><td>TOTAL EXPENSES</td> <td></td><td></td><td></td> </tr>
    <tr><td>TOTAL PROFIT</td> <td></td><td></td><td></td> </tr>
    </tbody>
    </table>

</div>
{/* Data Table View Ends Here*/}

</div>
</div>
</div>
</div>
</section>

</>

 </header>
    )
}

export default IncomeStatement;