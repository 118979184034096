import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'

const ViewBusinesses = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const userRef = useRef()
  const errRef = useRef()

  const [DBtableViewBusinesses, setDBtableViewBusinesses] = useState([])

  const [update, setUpdate] = useState('')

  useEffect(() => {
    var formDataViewBusinesses = new FormData(this)
    formDataViewBusinesses.append('apiType', 'getViewBusinesses')
    //Axios.get(`${server_app_api}/api/getBusinessCategoryDropDown`).then(
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php',
      data: formDataViewBusinesses,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      //Axios.get(`${server_app_api}/api/getViewBusinesses`)
      .then(response => {
        setDBtableViewBusinesses(response.data)

        setTimeout(function () {
          $('#table').DataTable()
          addTableButtons()
        }, 1000)
        //console.log(response)
      })
  }, [])

  const UpdateRecord = system_setupID => {
    Axios.put(`${server_app_api}/api/update`, {
      id: system_setupID,
      lname: update
    })
    setUpdate('')
  }

  const deleteRecord = system_setupID => {
    Axios.delete(`${server_app_api}/api/delete/${system_setupID}`)
    alert('Successfully Deleted Record')
  }

  ;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()

  //initialize datatable
  /*$(function () {
    setTimeout(function () {
      $('#table').DataTable()
      addTableButtons()
    }, 10000)
  })*/

  function addTableButtons () {
    var table = $('#table').DataTable()

    new $.fn.dataTable.Buttons(table, {
      buttons: [
        {
          extend: 'copy',
          text: 'Copy <i class="fa fa-files-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
        },
        {
          extend: 'excel',
          text: 'Excel <i class="fa fa-file-excel-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
        },
        //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
        {
          extend: 'pdf',
          text: 'PDF <i class="fa fa-file-pdf-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
        },
        {
          extend: 'print',
          text: 'Print <i class="fa fa-print"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
        }
      ]
    })

    table.buttons(0, null).containers().appendTo('#filterTopCriteria')
  }
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <header>
      <>
        <title>
          KANCH INNOVATIVE TECHNOLOGIES - View Registered Businesses -{' '}
          {systemSetupBranchIDStoreName}
        </title>
        <>
          <Headers />
        </>
        {/*<!-- Preloader 
        <div id='overlayer'></div>
        <div class='loader'>
          <div class='spinner-border' role='status'>
            <span class='visually-hidden'>Loading...</span>
          </div>
        </div>-->*/}
        <section class='fixed-sn white-skin'>
          <div class=' d-flex justify-content-center align-items-center'>
            <div class='container'>
              <div class='card mt-5'>
                <div class='card-body'>
                  {/*<div class="text-center">  <p > <a href="Home" class="blue-text"> Home</a> </p> </div> */}

                  {/* Data Table View Starts Here*/}
                  <div class=''>
                    {/*<div class="row">*/}
                    <br />
                    <h6 class='form-header white-text ocean blue-gradient'>
                      REGISTERED BUSINESSES
                    </h6>
                    <center>
                      <div id='filterTopCriteria'></div>
                    </center>
                    <table
                      id='table'
                      class='table table-striped table-bordered table-hover table-sm'
                    >
                      <thead class='form-header ocean blue-gradient'>
                        {/*<tr>
<td colspan="6"><h1 class="card-header white-text">REGISTERED BUSINESSES</h1></td>
</tr>*/}
                        <tr>
                          <th>SN</th>
                          <th>ORGANISATION</th>
                          <th>ORGANISATION ADDRESS</th>
                          <th>ORGANISATION PHONE</th>
                          <th>ORGANISATION EMAIL</th>
                          <th>UPDATE</th>
                          <th>DELETE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {DBtableViewBusinesses.map((val, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{val.organisation}</td>
                              <td>{val.organisation_address}</td>
                              <td>{val.organisation_phone}</td>
                              <td>{val.organisation_email}</td>
                              <td>
                                {' '}
                                <button
                                  onClick={() => {
                                    UpdateRecord(val.system_setupID)
                                  }}
                                  class='btn btn-light'
                                  title='Update This Record'
                                >
                                  <span class='fa fa-edit blue-text'></span>
                                </button>
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    deleteRecord(val.system_setupID)
                                  }}
                                  class='btn btn-light'
                                  title='Delete This Record From The Database'
                                >
                                  <span class='fa fa-trash red-text'></span>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* Data Table View Ends Here*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </header>
  )
}

export default ViewBusinesses
