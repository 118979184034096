import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'

const TestDataTables = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const userRef = useRef()
  const errRef = useRef()

  const [update, setUpdate] = useState('')

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserRole = localStorage.getItem('userRole')
  let secondUserRole = JSON.parse(firstUserRole)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  ;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()

  /*$(document).ready(function () {
    $('#table').DataTable({
      pageLength: 50,
      drawCallback: function () {
        var api = this.api()
        $(api.column(3).footer()).html(
          api.column(7, { page: 'current' }).data().sum()
        )
      }
    })
  })*/

  $(document).ready(function () {
    var table = $('#example').DataTable({
      initComplete: function (settings, json) {
        // calculate the sum when table is first created:
        doSum()
      }
    })

    $('#example').on('draw.dt', function () {
      // re-calculate the sum whenever the table is re-displayed:
      doSum()
    })

    // This provides the sum of all records:
    function doSum () {
      // regex pattern to remove HTML tags
      var regex = /(<([^>]+)>)/gi

      // Function to remove HTML tags
      var removeHTML = function (h) {
        return h.replace(regex, '')
      }

      // get the DataTables API object:
      var table = $('#example').DataTable()
      // set up the initial (unsummed) data array for the footer row:
      var totals = ['', 'Totals', 0, 0, 0, 0, 0, 0, 0, '']
      // iterate all rows - use table.rows( {search: 'applied'} ).data()
      // if you want to sum only filtered (visible) rows:
      totals = table
        .rows()
        .data()
        // sum the amounts:
        .reduce(function (sum, record) {
          for (let i = 2; i <= 8; i++) {
            // Column(s) with HTML tags
            if (i === 2) {
              record[i] = removeHTML(record[i])
            }
            sum[i] = sum[i] + numberFromString(record[i])
          }
          return sum
        }, totals)
      // place the sum in the relevant footer cell:
      for (let i = 1; i <= 8; i++) {
        var column = table.column(i)
        $(column.footer()).html(formatNumber(totals[i]))
      }
    }

    function numberFromString (s) {
      return typeof s === 'string'
        ? s.replace(/[\$,]/g, '') * 1
        : typeof s === 'number'
        ? s
        : 0
    }

    function formatNumber (n) {
      return n.toLocaleString() // or whatever you prefer here
    }
  })

  return (
    <header>
      <>
        return
        <>
          <Headers />
        </>
        {/*<!-- Preloader -->*/}
        <section class='fixed-sn white-skin'>
          <div class=' d-flex justify-content-center align-items-center'>
            <div class='container col-12'>
              <div class='card'>
                <div class='card-body'>
                  {/*<div class="text-center">  <p > <a href="Home" class="blue-text"> Home</a> </p> </div> */}

                  {/* Data Table View Starts Here*/}
                  <div class=''>
                    {/*<div class="row">*/}
                    <br />
                    <h6 class='form-header white-text bg-info'>
                      Test Data Tables
                    </h6>

                    <center>
                      <div id='filterTopCriteria'></div>
                    </center>

                    <table
                      id='example'
                      class='display'
                      style={{ width: '100%' }}
                    >
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Day</th>
                          <th>Eval Approved 1st Call</th>
                          <th>Eval Approved 1st Call Bonus</th>
                          <th>Eval Approved Call Back</th>
                          <th>Eval Approved Call Back Bonus</th>
                          <th>Invoice Count</th>
                          <th>Grand Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>01/01/20</td>
                          <td>Wednesday</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>01/02/20</td>
                          <td>Thursday</td>
                          <td>14</td>
                          <td>42</td>
                          <td>10</td>
                          <td>20</td>
                          <td>24</td>
                          <td>86</td>
                        </tr>
                        <tr>
                          <td>01/03/20</td>
                          <td>Friday</td>
                          <td>14</td>
                          <td>42</td>
                          <td>19</td>
                          <td>38</td>
                          <td>24</td>
                          <td>104</td>
                        </tr>
                        <tr>
                          <td>01/04/20</td>
                          <td>Saturday</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>01/05/20</td>
                          <td>Sunday</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>01/06/20</td>
                          <td>Monday</td>
                          <td>3</td>
                          <td>9</td>
                          <td>10</td>
                          <td>20</td>
                          <td>5</td>
                          <td>34</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* Data Table View Ends Here*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </header>
  )
}

export default TestDataTables
