import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import { confirmAlert } from 'react-confirm-alert'
import { NumericFormat  } from 'react-number-format';
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
/*import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'*/
import $ from 'jquery'
import { isInteger } from 'formik';

const SalesDelete = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const userRef = useRef()
  const errRef = useRef()

  var [toDate, setToDate] = useState('');
  var [fromDate, setFromDate] = useState('');
const [viewSalesTable, setViewSalesTable] = useState('')
  const [tableRefresh, setTableRefresh] = useState('')
  
  const [DBtableViewSalesReport, setDBtableViewSalesReport] = useState([])
  //const [DBtableViewOneItem, setDBtableViewOneItem] = useState([])

  const [businessBranchName, setBusinessBranchName] = useState('All')
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  const [update, setUpdate] = useState('')

  let [handleSubmitBtnDelete, setHandleSubmitBtnDelete] = useState('')

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserRole = localStorage.getItem('userRole')
  let secondUserRole = JSON.parse(firstUserRole)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  const handleSearchSales = posID => {
    let timer = 100;
    if(isInteger(posID)){
      timer = 3000;
//alert(posID)
      setHandleSubmitBtnDelete('Loading')
      //const deleteSale = posID => {
      var formDataDeleteOneUser = new FormData(this)
      formDataDeleteOneUser.append('apiType', 'getViewSalesReport')
      formDataDeleteOneUser.append('posIDToDelete', posID)
      formDataDeleteOneUser.append('positionToDelete', secondUserPosition)
      formDataDeleteOneUser.append('userIDToDelete', secondUserID)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/index.php`,
        data: formDataDeleteOneUser,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        setHandleSubmitBtnDelete('')
        //setTableRefresh('Refresh')
        alert(response.data)
        //window.location.reload()
      }) 

    }


    if(toDate ==='' || fromDate ==='')
    {
    alert('Empty Dates, Please Choose Period')
  }
  else{
    //alert(toDate+"=  and = "+fromDate)
   
  //useEffect(() => {
   setHandleSubmitBtn('Loading');
    var formDataSalesReport = new FormData(this)
    formDataSalesReport.append('apiType', 'getViewSalesReport')
    if (
      secondUserPosition === 'Business Owner' ||
      secondUserPosition === 'Auditor'
    ) {
      formDataSalesReport.append('businessNameID', systemSetupIDInitial)
    }
    if (secondUserPosition === 'Manager') {
      formDataSalesReport.append(
        'businessBranchID',
        secondUserSystemSetupBranchID
      )
    }
    if (secondUserPosition === 'Cashier') {
      formDataSalesReport.append(
        'businessBranchIDCashier',
        secondUserSystemSetupBranchID
      )
      formDataSalesReport.append('userID', secondUserID)
    }
    formDataSalesReport.append('fromDate', fromDate)
    formDataSalesReport.append('toDate', toDate)
    setTimeout(function () {
    Axios({
      method: 'post',
      url: `${server_app_api_php}/viewSalesReport.php`,
      data: formDataSalesReport,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('');
      setViewSalesTable('Content')
      //alert(response.data)
      setDBtableViewSalesReport(response.data)

      setTimeout(function () {
        $('#table').DataTable({
          fnFooterCallback: function (nRow, aasData, iStart, iEnd, aiDisplay) {
            var columnas = [5, 6, 7, 8, 9, 10] //the columns you wish to add
            for (var j in columnas) {
              var columnaActual = columnas[j]
              var total = 0
              for (var i = iStart; i < iEnd; i++) {
                total = total + parseFloat(aasData[aiDisplay[i]][columnaActual])
              }
              $($(nRow).children().get(columnaActual)).html(total)
            } // end of for loop
          }, // end of fnFootCallback function
          retrieve: true, // this hides the errors
          pageLength: 10,
          lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
        })
        addTableButtons()
      }, 2000)


    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    
  }, timer)
 // }, [])
  }
}

  const deleteRecord = posID => {
    setHandleSubmitBtnDelete('Loading')
    //const deleteSale = posID => {
    var formDataDeleteOneUser = new FormData(this)
    formDataDeleteOneUser.append('apiType', 'getViewSalesReport')
    formDataDeleteOneUser.append('posIDToDelete', posID)
    formDataDeleteOneUser.append('positionToDelete', secondUserPosition)
    formDataDeleteOneUser.append('userIDToDelete', secondUserID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataDeleteOneUser,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setHandleSubmitBtnDelete('')
      setTableRefresh('Refresh')
      alert(response.data)
      //window.location.reload()
    })    
   
     }

    /* useEffect(() => {
//if(fromDate !='' && toDate !=''){
  
    setTableRefresh('')
    var formDataSalesReport = new FormData(this)
    formDataSalesReport.append('apiType', 'getViewSalesReport')
    if (
      secondUserPosition === 'Business Owner' ||
      secondUserPosition === 'Auditor'
    ) {
      formDataSalesReport.append('businessNameID', systemSetupIDInitial)
    }
    if (secondUserPosition === 'Manager') {
      formDataSalesReport.append(
        'businessBranchID',
        secondUserSystemSetupBranchID
      )
    }
    if (secondUserPosition === 'Cashier') {
      formDataSalesReport.append(
        'businessBranchIDCashier',
        secondUserSystemSetupBranchID
      )
      formDataSalesReport.append('userID', secondUserID)
      formDataSalesReport.append('fromDate', fromDate)
      formDataSalesReport.append('toDate', toDate)
    }
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/viewSalesReport.php`,
      data: formDataSalesReport,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBtableViewSalesReport(response.data)
      setHandleSubmitBtnDelete('')
      //alert(response.data)
      setTimeout(function () {
        $('#table').DataTable({
          fnFooterCallback: function (nRow, aasData, iStart, iEnd, aiDisplay) {
            var columnas = [5, 6, 7, 8, 9, 10] //the columns you wish to add
            for (var j in columnas) {
              var columnaActual = columnas[j]
              var total = 0
              for (var i = iStart; i < iEnd; i++) {
                total = total + parseFloat(aasData[aiDisplay[i]][columnaActual])
              }
              $($(nRow).children().get(columnaActual)).html(total)
            } // end of for loop
          }, // end of fnFootCallback function
          retrieve: true, // this hides the errors
          pageLength: 10,
          lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
        })
        addTableButtons()
      }, 2000)

    }) //.catch((err) => alert("ERROR : Check Your Network Connection")); 
      
  //}

  }, [tableRefresh])*/


  var totalProfit = 0
  
function addTableButtons () {
    if (
      secondUserPosition === 'Business Owner' ||
      secondUserRole === 'System Administrator'
    ) {
      var table = $('#table').DataTable()

      new $.fn.dataTable.Buttons(table, {
        buttons: [
          {
            extend: 'copy',
            text: 'Copy <i class="fa fa-files-o"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
            }
          },
          {
            extend: 'excel',
            text: 'Excel <i class="fa fa-file-excel-o"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
            }
          },
          //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
          {
            extend: 'pdf',
            text: 'PDF <i class="fa fa-file-pdf-o"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
            }
          },
          {
            extend: 'print',
            text: 'Print <i class="fa fa-print"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
            }
          }
        ]
      })

      table.buttons(0, null).containers().appendTo('#filterTopCriteria')
    } else if (secondUserPosition === 'Manager') {
      var table = $('#table').DataTable()

      new $.fn.dataTable.Buttons(table, {
        buttons: [
          {
            extend: 'copy',
            text: 'Copy <i class="fa fa-files-o"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
            }
          },
          {
            extend: 'excel',
            text: 'Excel <i class="fa fa-file-excel-o"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
            }
          },
          //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
          {
            extend: 'pdf',
            text: 'PDF <i class="fa fa-file-pdf-o"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
            }
          },
          {
            extend: 'print',
            text: 'Print <i class="fa fa-print"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
            }
          }
        ]
      })

      table.buttons(0, null).containers().appendTo('#filterTopCriteria')
    } else if (secondUserPosition === 'Cashier') {
      var table = $('#table').DataTable()

      new $.fn.dataTable.Buttons(table, {
        buttons: [
          {
            extend: 'copy',
            text: 'Copy <i class="fa fa-files-o"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 2, 3, 4, 5, 6, 8, 9, 12, 13, 14, 15]
            }
          },
          {
            extend: 'excel',
            text: 'Excel <i class="fa fa-file-excel-o"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 2, 3, 4, 5, 6, 8, 9, 12, 13, 14, 15]
            }
          },
          //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
          {
            extend: 'pdf',
            text: 'PDF <i class="fa fa-file-pdf-o"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 2, 3, 4, 5, 6, 8, 9, 12, 13, 14, 15]
            }
          },
          {
            extend: 'print',
            text: 'Print <i class="fa fa-print"></i>',
            className: 'dtButton',
            exportOptions: {
              columns: [0, 2, 3, 4, 5, 6, 8, 9, 12, 13, 14, 15]
            }
          }
        ]
      })

      table.buttons(0, null).containers().appendTo('#filterTopCriteria')
    }
  }
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)

  //alert(fromDate)
  return (
    <header>
      <>
        <title>
          KANCH INNOVATIVE TECHNOLOGIES - View All Sales Report -{' '}
          {systemSetupBranchIDStoreName}{' '}
        </title>
        <>
          <Headers />
        </>
        {/*<!-- Preloader-->*/}
        <div hidden={handleSubmitBtn ===''}>
        <div id='overlayer'></div>
        <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div>
      </div>

      <div hidden={handleSubmitBtnDelete ===''}>
        <div class="loader d-flex justify-content-center">          
          <div class="spinner-border text-danger" role="status" style={{width: '10rem', height: '10rem'}}>
          <span class=''>Loading...</span>
</div>
        </div>
        </div>

        <section class=''>
          <div class=' d-flex justify-content-center align-items-center'>
            <div class='container col-12'>
              <div class='card mt-5'>
                <div class='card-body' style={{ overflowX: 'auto' }}>
                  {/*<div class="text-center">  <p > <a href="Home" class="blue-text"> Home</a> </p> </div> */}

                  {/* Data Table View Starts Here*/}
                  <div class=''>
                    {/*<div class="row">*/}
                    <br />
                    <h6 class='form-header white-text ocean blue-gradient'>
                      SALES REPORT - Search 
                      &nbsp;
                       <span hidden={secondUserPosition != 'Business Owner'}> And Delete </span>
                       Items
                    </h6>

 {/* Grid row */}
 <div class='row'>
                  {/* Grid column */}
                  <div class='col-xl-12 col-md-12 mb-4'>
                    {/* Card */}
                    <div class='card'>
                      <div class='row pb-4 pl-4 pr-4'>

                        <div class='col-md-5 col-12 text-left pl-4 pr-4'>
                          <label>From Date</label>
                          <input
                            type='date'
                            class='form-control'
                            value={fromDate}
                            onChange={e => setFromDate(e.target.value)}
                          />
                        </div>
                        <div class='col-md-5 col-12 text-left pl-4 pr-4'>
                          <label>To Date</label>
                          <input
                            type='date'
                            class='form-control'
                            value={toDate}
                            onChange={e => setToDate(e.target.value)}
                          />
                        </div>
                        <div class='col-md-2 col-12 pl-4 pr-4 mt-5'>
                          <span
                          disabled = {fromDate === '' || toDate === ''}
                            type='button'
                            class='fa fa-search blue-text'
                            onClick={handleSearchSales}
                            style={{fontSize:'250%'}}
                            ></span>
                          
                        </div>
                      </div>
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>
        
                    {/*<caption>Sales Report</caption>*/}
                    <center>
                      <div id='filterTopCriteria'></div>
                    </center>

                    <table
                      id='table'
                      class='table table-striped table-bordered table-hover table-sm nowrap'
                      width='100%'
                      hidden={viewSalesTable === ''}
                    >
                      <thead class='form-header text-uppercase ocean blue-gradient'>
                        {/*<tr>
<td colspan="6"><h1 class="card-header white-text">REGISTERED BUSINESSES</h1></td>
</tr>*/}
                        <tr>
                          {/*
SELECT `stockID`, `system_setupID`, `system_setup_branchID`, `stock_batchID`, `productID`, 
`stock_quantity`, `stock_cost`, `suppliersID`, `product_unit_price`, `stockArrivalDate`, 
`stock_date`, `stock_time`, `stock_entered_by`, `stock_IP` FROM `stock` WHERE 1
*/}
                          <th>S/N</th>
                          <th
                            hidden={
                              secondUserPosition === 'Cashier' ||
                              secondUserPosition === 'Manager'
                            }
                          >
                            Branch
                          </th>
                          <th>Date</th>
                          <th>item</th>
                          <th>Payment Method</th>
                          <th>Available Quantity</th>
                          <th>Quantity Sold</th>
                          <th hidden={secondUserPosition === 'Cashier'}>
                            Unit Cost
                          </th>
                          <th>unit Price</th>
                          <th>Total Sale</th>
                          <th hidden={secondUserPosition === 'Cashier'}>
                            profit
                          </th>
                          <th hidden={secondUserPosition === 'Cashier'}>
                            Cashier
                          </th>
                          <th>Customer Phone</th>
                          <th>Customer</th>
                          <th>Referee Phone</th>
                          <th>Referee Name</th>
                          <th
                            hidden={
                              secondUserPosition != 'Business Owner' &&
                              secondUserRole != 'System Administrator'
                            }
                          >
                            DELETE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {DBtableViewSalesReport.map((val, index) => {
                          const profit1 =
                            val.unit_quantity * val.product_unit_cost
                          const profit = val.product_total_price - profit1
                          totalProfit =
                            totalProfit + parseInt(val.product_total_price)
                          return (
                            <tr>
                              {/*system_setupID`, `system_setup_branchID`, `Last_name`, `first_name`, `email`, `phone`, 
`image`, `password`, `thumb_print`, `position`, `role`,   */}
                              <td>{index + 1}</td>
                              <td
                                hidden={
                                  secondUserPosition === 'Cashier' ||
                                  secondUserPosition === 'Manager'
                                }
                              >
                                {val.branch_name}
                              </td>
                              <td>{val.pos_date}</td>
                              <td>{val.product}</td>
                              <td>{val.sell_type}</td>
                              <td>{val.stock_quantity}</td>
                              <td>{val.unit_quantity}</td>
                              <td hidden={secondUserPosition === 'Cashier'}>
                                {val.product_unit_cost}
                              </td>
                              <td>{val.product_unit_price}</td>
                              <td>{val.product_total_price}</td>
                              <td hidden={secondUserPosition === 'Cashier'}>
                                {profit}
                              </td>
                              <td hidden={secondUserPosition === 'Cashier'}>
                                {val.Last_name} {val.first_name}
                              </td>

                              <td>{val.customerPhone}</td>
                              <td>{val.customer}</td>
                              <td>{val.refererPhone}</td>
                              <td>{val.refererName}</td>

                              <td
                                hidden={
                                  secondUserPosition != 'Business Owner' &&
                                  secondUserRole != 'System Administrator'
                                }
                              >
                                <span
                                  onClick={() => {
                                    handleSearchSales(val.posID)
                                  }}
                                  type='button'
                                  class='fa fa-trash red-text'
                                  title='Delete This Record From The Database'
                                  disabled ={handleSubmitBtnDelete ==='Loading'}
                                ></span>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tfoot class='form-header text-center'>
                        <tr>
                          <th></th>
                          <th
                            hidden={
                              secondUserPosition === 'Cashier' ||
                              secondUserPosition === 'Manager'
                            }
                          ></th>
                          <th></th>
                          <th></th>
                          <th>TOTALS</th>
                          <th></th>
                          <th></th>
                          <th hidden={secondUserPosition === 'Cashier'}></th>
                          <th></th>
                          <th></th>
                          <th hidden={secondUserPosition === 'Cashier'}></th>
                          <th hidden={secondUserPosition === 'Cashier'}></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th
                            hidden={
                              secondUserPosition != 'Business Owner' &&
                              secondUserRole != 'System Administrator'
                            }
                          ></th>
                        </tr>
                      </tfoot>
                    </table>

                    <table id='table' style={{ float: 'right' }} hidden = {tableRefresh ===''}>
                      <tr>
                        <td colspan='7'></td>{' '}
                        <td>
                          <h3>
                            <u>
                              <b>TOTAL SALES</b>
                            </u>
                          </h3>{' '}
                        </td>{' '}
                        <td> = </td>{' '}
                        <td>
                          <h3>
                            <b>
                            <NumericFormat value={totalProfit} displayType={'text'} thousandSeparator={true} prefix={'K'} />
                            </b>
                          </h3>
                        </td>
                      </tr>
                    </table>

                    {/*<table>
                      <tr>
                        <td></td>{' '}
                        <td>
                          <h3>
                            <u>
                              <b>TOTAL SALE</b>
                            </u>
                          </h3>{' '}
                        </td>{' '}
                        <td> = </td>{' '}
                        <td>
                          <h3>
                            <b>K{totalProfit}</b>
                          </h3>
                        </td>
                      </tr>
                    </table> */}

                    {/*<table style={{ float: 'right' }}>
                      <tr>
                        <td colspan='7'></td>{' '}
                        <td>
                          <h3>
                            <u>
                              <b>TOTAL PROFIT</b>
                            </u>
                          </h3>{' '}
                        </td>{' '}
                        <td> = </td>{' '}
                        <td>
                          <h3>
                            <b>K{totalProfit}</b>
                          </h3>
                        </td>
                      </tr>
                    </table>*/}
                  </div>
                  {/* Data Table View Ends Here*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </header>
  )
}

export default SalesDelete
