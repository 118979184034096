import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
  let navigate = useNavigate()
  useEffect(() => {
    localStorage.removeItem('userID')
    localStorage.removeItem('systemSetupID')
    localStorage.removeItem('systemSetupBranchID')
    localStorage.removeItem('userPosition')
    localStorage.removeItem('userRole')
    localStorage.removeItem('POSTransactionID')
    localStorage.removeItem('transactionIDPrintReceipt')
    localStorage.removeItem('transactionID')
    localStorage.removeItem('userOrganisation')
    localStorage.removeItem('userName')
    localStorage.removeItem('organisation_address')
    localStorage.removeItem('organisation_phone')
    localStorage.removeItem('organisation_email')
    localStorage.removeItem('businessCategory')
    localStorage.removeItem('organisation_address')
    localStorage.removeItem('organisation_email')
    localStorage.removeItem('organisation_phone')
    localStorage.removeItem('systemSetupBranchID')
    localStorage.removeItem('systemSetupID')
    localStorage.removeItem('userID')
    localStorage.removeItem('userName')
    localStorage.removeItem('userOrganisation')
    localStorage.removeItem('userPosition')
    localStorage.removeItem('userRole')
    localStorage.removeItem('systemSetupBranchIDStoreName')
    localStorage.removeItem('switchVarName')
    localStorage.removeItem('switchVarBranch')
    localStorage.removeItem('responseReceiptPrintTotal')
    localStorage.removeItem('responseReceiptPrint')
    localStorage.removeItem('posTableVisibility')
    localStorage.removeItem('login')
    localStorage.removeItem('businessOwnerSelectedBranchID')
    localStorage.removeItem('switchVar')
    localStorage.removeItem('arr')
    localStorage.removeItem('arrHome')
    localStorage.setItem('loggedIn', JSON.stringify('No'))
    sessionStorage.setItem('loggedIn', JSON.stringify('No'))
    localStorage.removeItem('barcode')

    sessionStorage.removeItem('reloadCount')
    sessionStorage.removeItem('reloadCountHome')

    //transactionID:""1939491010202210""
    //transactionIDPrintReceipt:""193921010202210""
    const firstLogout = localStorage.getItem('login')
    const secondLogout = JSON.parse(firstLogout)
    if (secondLogout === 'Business') {
      navigate('../LoginAdmin')
    } else {
      //alert("LoggedOut ... ");
      navigate('/')
    }
  }, [])
}

export default Logout
