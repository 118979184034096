import { useRef, useState, useEffect, useContext } from 'react'
import Headers from './Headers'
import { useNavigate, redirect } from 'react-router-dom'
import Axios from '../api/axios'
import $ from 'jquery'
import { NumericFormat  } from 'react-number-format';
import { MDBContainer } from 'mdbreact'
import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter
} from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import Select from 'react-select'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody
} from 'mdb-react-ui-kit';

import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import {
  TableCell,
  TableFooter,
  TableRow,
  makeStyles,
  Box
} from "@material-ui/core";
//import TableFooter from "@mui/material/TableFooter";

export const StoreExpenses = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [
    DBtableViewformDataTransferReport,
    setDBtableViewformDataTransferReport
  ] = useState([])

  const [posProductDropDown, setPosProductDropDown] = useState([])
  const [itemID, setItemID] = useState([{}])
  var [quantity, setQuantity] = useState('')

  const [total, setTotal] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  //if (quantity === '') {
  //quantity = 0
  //}
  //alert(quantity)
  const [quantityDB, setQuantityDB] = useState('')
  const [compareProductsPricesData, setCompareProductsPricesData] = useState([])
  const [compareProductsPricesLabel, setCompareProductsPricesLabel] = useState(
    []
  )
  const [businessBranchName, setBusinessBranchName] = useState('')
  const [businessBranchNameTo, setBusinessBranchNameTo] = useState('')
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )
  const [businessBranchNameDropDownTo, setBusinessBranchNameDropDownTo] =
    useState([])

  const [storeExpensesDropDown, setStoreExpensesDropDown] = useState([])
  const [DBtableViewExpenses, setDBtableViewExpenses] = useState([])
  var [expensesStore, setDBtableViewExpensesStore] = useState('')
  var [expensesStoreMain, setDBtableViewExpensesStoreMain] = useState('')

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  const HandelChangeItemDropDown = obj => {
    var rodeen = []
    obj.map(val => {
      rodeen.push(val.value)
    })
    localStorage.setItem('ItemDropDownValue', JSON.stringify(rodeen))
    setItemID(JSON.stringify(rodeen))
  }

  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const monthToday = `${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const firstUserSystemSetupID = localStorage.getItem('systemSetupID')
  let secondUserSystemSetupID = JSON.parse(firstUserSystemSetupID)

  const firstUserRole = localStorage.getItem('userRole')
  let secondUserRole = JSON.parse(firstUserRole)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  let businessCategory = localStorage.getItem('businessCategory')
  businessCategory = JSON.parse(businessCategory)

  //alert('Branch ID = ' + secondUserSystemSetupBranchID)

 
  useEffect(() => {
    setHandleSubmitBtn('Loading')
    var formDataStoreExpenses = new FormData(this)
    formDataStoreExpenses.append('apiType', 'getStoreExpenses')
    formDataStoreExpenses.append('businessNameID', secondUserSystemSetupID)
    formDataStoreExpenses.append(
      'system_setup_branchID',
      secondUserSystemSetupBranchID
    )
    //alert(secondUserSystemSetupID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataStoreExpenses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      setStoreExpensesDropDown(response.data)
      //alert(response.data)

    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [])

  useEffect(() => {
    var formDataViewExpensesStoreMain = new FormData(this)
    formDataViewExpensesStoreMain.append(
      'apiType',
      'getViewExpensesDayCloseStore'
    )
    formDataViewExpensesStoreMain.append('expenseID', '')
    formDataViewExpensesStoreMain.append(
      'businessBranchNameID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewExpensesStoreMain,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBtableViewExpensesStoreMain(response.data)
      //alert(response.data)
    })
  }, [])

  const handleViewExpenses = expenseID => {
    setHandleSubmitBtn('Loading')
    var formDataViewExpenses = new FormData(this)
    formDataViewExpenses.append('apiType', 'getViewExpensesDayClose')
    formDataViewExpenses.append('expenseID', expenseID)
    formDataViewExpenses.append('businessNameID', secondUserSystemSetupID)
    formDataViewExpenses.append(
      'businessBranchNameID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewExpenses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      setDBtableViewExpenses(response.data)
      //alert(response.data)
    })

    var formDataViewExpensesStore = new FormData(this)
    formDataViewExpensesStore.append('apiType', 'getViewExpensesDayCloseStore')
    formDataViewExpensesStore.append('expenseID', expenseID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewExpensesStore,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBtableViewExpensesStore(response.data)
      //alert(response.data)
    })
  }

 

  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)


  
  
const columns = [
  {    name: "store_expenses_day_closeID",    label: "ID",    options: {     filter: true,     sort: true, display: false,
  } },

  { label : "SN",   name:'store_expenses_day_closeID',  options: {     filter: true,     sort: true, 
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
       <span>
        {tableMeta.rowIndex + 1}
       </span>
      );
    }
  } ,
},
  {    name: "date",    label: "Date",    options: {     filter: true,     sort: true, 
  } ,
  setCellProps: () => ({style: {whiteSpace:'nowrap'}}) },
   { label: "Store", name : "store",    options: {     filter: true,     sort: true,    },
   setCellProps: () => ({style: {whiteSpace:'nowrap'}}) },
   {  label: "NHIMA", name : "nhima",    options: {     filter: true,     sort: true,    },
  setCellProps: () => ({style: {whiteSpace:'nowrap'}})  },  
  {  label: "Yellow", name : "yellow",    options: {     filter: true,     sort: true,    },
  setCellProps: () => ({style: {whiteSpace:'nowrap'}})  },
  { label : "Xtenda", name : "xtenda",    options: {     filter: true,     sort: true,    },
  setCellProps: () => ({style: {whiteSpace:'nowrap'}})  },
  {  label : "Total Credit", name : "totalCredit",    options: {     filter: true,     sort: true,    } ,
  setCellProps: () => ({style: {whiteSpace:'nowrap'}}) },
   {  
    label : "Debit Card", name : "card",    options: {     filter: true,     sort: true,    },
    setCellProps: () => ({style: {whiteSpace:'nowrap'}})
  },
   {  
    label : "Mobile Money", name : "mobileMoney",    options: {     filter: true,     sort: true,    },
    setCellProps: () => ({style: {whiteSpace:'nowrap'}})
    },
  {  
    label : "Total Wire Transfer", name : "totalWireTransfer",    options: {     filter: true,     sort: true,    },
    setCellProps: () => ({style: {whiteSpace:'nowrap'}})
  },
  {  
    label : "Cash", name : "cash",    options: {     filter: true,     sort: true,    },
    setCellProps: () => ({style: {whiteSpace:'nowrap'}})
  },
  {  label : "System Less Credit", name : "systemLessCredit",    options: {     filter: true,     sort: true,    } ,
  setCellProps: () => ({style: {whiteSpace:'nowrap'}}) },
  {  label : "System Value", name : "systemTotal",    options: {     filter: true,     sort: true,    },
  },
  {  label : "Expenses", name : "expenses",    options: {     filter: true,     sort: true, display: false,  },
  },
   { label : "Expenses", name : "expenses",    options: {     filter: true,     sort: true, 
    customBodyRender: (value, tableMeta, updateValue) => {
      return (

        <button 
        onClick={() => {
          handleViewExpenses(
            tableMeta.rowData[0]
          )
        }}

        title='Click Here To View List Of Expenses Items'
        data-toggle='modal'
        data-target='#modalViewExpenses'
        >

{tableMeta.rowData[14]}

        </button>

      );
    }
  } ,
   setCellProps: () => ({style: {whiteSpace:'nowrap'}}) },
   {  label : "Balance After Expenses", name : "balanceAfterExpenses",    options: {     filter: true,     sort: true,    },
   setCellProps: () => ({style: {whiteSpace:'nowrap'}})  },
    { label : "Cash Banked", name : "cashBanked",    options: {     filter: true,     sort: true,    } ,
    setCellProps: () => ({style: {whiteSpace:'nowrap'}}) }, 
    { label : "Cash Handed In", name : "cashHandedIn",    options: {     filter: true,     sort: true,    } ,
    setCellProps: () => ({style: {whiteSpace:'nowrap'}}) }, 
    { label : "-/+", name : "inceaseDecrease",    options: {     filter: true,     sort: true,    } ,
    setCellProps: () => ({style: {whiteSpace:'nowrap'}}) }, 
    { label : "Reason", name : "reason",    options: {     filter: true,     sort: true,    } ,
    setCellProps: () => ({style: {whiteSpace:'nowrap'}}) }, 
    { label : "User", name : "user",    options: {     filter: true,     sort: true,    } ,
    setCellProps: () => ({style: {whiteSpace:'nowrap'}}) }, 

   
 
];

/*
const options = {
  filter: true,
  filterType: 'dropdown',
  responsive: 'standard',
  fixedHeader: true,
  fixedSelectColumn: true,
  tableBodyHeight: '400px',
  selectableRowsHeader : false,
  selectableRows : 'none',
  pagination : true,
  rowsPerPageOptions : [5, 10, 15, 20, 50, 100, 200, 500, 1000, 5000, 10000, 20000, 50000, 100000],
  rowsPerPage : 5,
  searchAlwaysOpen : true,
  searchPlaceholder: 'Search Here',
  elevation : 4,
  setCellProps: () => ({style: {whiteSpace:'nowrap'}}),
  
  setCellHeaderProps: value => ({ style: { backgroundColor: 'red', whiteSpace:'nowrap' } }),
  onTableChange: handleTableChange,
    onTableInit: handleTableChange,
};
*/


const options = {
  customHeadRender: () => null,
  filter: true,
  selectableRows: false,
  filterType: "dropdown",
  responsive: "standard",
  fixedHeader: true,
  viewColumns: true,
  search: true,
  rowsPerPageOptions : [10, 15, 20, 50, 100, 200, 500, 1000, 5000, 10000, 20000, 50000, 100000],
  //rowsPerPage : 5,
  searchAlwaysOpen : true,
  searchPlaceholder: 'Search Here',
  onChangeRowsPerPage(numberOfRows) {
    setRowsPerPage(numberOfRows);
  },
  onChangePage(page) {
    setPage(page);
  },
  customTableBodyFooterRender: (opts) => {
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    let sumnhima = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[4]);
      }, 0);
    let sumyellow = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[5]);
      }, 0);
    let sumxtenda = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[6]);
      }, 0);
      let sumtotalCredit = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[7]);
      }, 0);
      let sumcard = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[8]);
      }, 0);
      let summobileMoney = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[9]);
      }, 0);
      let sumtotalWireTransfer = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[10]);
      }, 0);
      let sumcash = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[11]);
      }, 0);
      let sumsystemLessCredit = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[12]);
      }, 0);
      let sumsystemTotal = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[13]);
      }, 0);
      let sumexpenses = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[14]);
      }, 0);
      let sumbalanceAfterExpenses = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[16]);
      }, 0);
      let sumcashBanked = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[17]);
      }, 0);
      let sumcashHandedIn = opts.data
      .slice(startIndex, endIndex)
      .reduce((accu, item) => {
        return accu + parseFloat(item.data[18]);
      }, 0);
    return (
      <>
        {storeExpensesDropDown.length > 0 && (
          <TableFooter style={{backgroundColor:'gray'}}>
            <TableRow>
           
              {opts.columns.map((col, index) => {
                if (col.display === "true") {
                                     
                        if (col.name === "store_expenses_day_closeID") {
                                      return (
                                        <TableCell key={index} > </TableCell>
                                      );                  
                                      }
                        else if (col.name === "date") {
                                        return (
                                          <TableCell key={index} > </TableCell>
                                        );                  
                                        }

                  else if (col.name === "store") {
                    return (
                      <TableCell key={index} >
                        Total
                      </TableCell>
                    );
                  } else if (col.name === "nhima") {
                    return (
                      <TableCell key={index} >
                        {sumnhima}
                      </TableCell>
                    );
                  } else if (col.name === "yellow") {
                    return (
                      <TableCell key={index} >
                        {sumyellow}
                      </TableCell>
                    );
                  } else if (col.name === "xtenda") {
                    return (
                      <TableCell key={index} >
                        {sumxtenda}
                      </TableCell>
                    );
                  }
                  else if (col.name === "totalCredit") {
                    return (
                      <TableCell key={index} >
                        {sumtotalCredit}
                      </TableCell>
                    );
                  }
                  else if (col.name === "card") {
                    return (
                      <TableCell key={index} >
                        {sumcard}
                      </TableCell>
                    );
                  }
                  else if (col.name === "mobileMoney") {
                    return (
                      <TableCell key={index} >
                        {summobileMoney}
                      </TableCell>
                    );
                  }
                  else if (col.name === "totalWireTransfer") {
                    return (
                      <TableCell key={index} >
                        {sumtotalWireTransfer}
                      </TableCell>
                    );
                  }
                  else if (col.name === "cash") {
                    return (
                      <TableCell key={index} >
                        {sumcash}
                      </TableCell>
                    );
                  }
                  else if (col.name === "systemLessCredit") {
                    return (
                      <TableCell key={index} >
                        {sumsystemLessCredit}
                      </TableCell>
                    );
                  }
                  else if (col.name === "systemTotal") {
                    return (
                      <TableCell key={index} >
                        {sumsystemTotal}
                      </TableCell>
                    );
                  }
                  else if (col.name === "expenses") {
                    return (
                      <TableCell key={index} >
                        {sumexpenses}
                      </TableCell>
                    );
                  }
                  else if (col.name === "balanceAfterExpenses") {
                    return (
                      <TableCell key={index} >
                        {sumbalanceAfterExpenses}
                      </TableCell>
                    );
                  }
                  else if (col.name === "cashBanked") {
                    return (
                      <TableCell key={index} >
                        {sumcashBanked}
                      </TableCell>
                    );
                  }
                  else if (col.name === "cashHandedIn") {
                    return (
                      <TableCell key={index} >
                        {sumcashHandedIn}
                      </TableCell>
                    );
                  }
                }
              })}
            </TableRow>
          </TableFooter>
        )}
      </>
    );
  }
};


  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - View Store Expenses -{' '}
        {systemSetupBranchIDStoreName}{' '}
      </title>
      <>
        <Headers />
      </>
      <title></title>
      {/*<!-- Preloader --> */}
      <div hidden={handleSubmitBtn === ''}>
      <div id="overlayer"></div>
      <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    </div>

      <div>
        <body class='col-12'>
          {/* Main layout */}
          <main>
            <div class='container-fluid'>
              <section class='section mt-5'>
                {/*Grid row */}
                <div class='row'>
                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body' style={{ overflowX: 'auto' }}>
                        <h6 class='form-header white-text ocean blue-gradient mt-0 text-uppercase'>
                          STORE EXPENSES{' '}
                          <span hidden={secondUserSystemSetupBranchID === ''}>
                            {expensesStoreMain}{' '}
                          </span>
                        </h6>

                        {/*<!-- Modal: modalManagerLogin  -->*/}
                        <div
                          class='modal fade col-lg-12'
                          id='modalViewExpenses'
                          tabindex='-1'
                          role='dialog'
                          aria-labelledby='myModalLabel'
                          aria-hidden='true'
                        >
                          <MDBModalDialog size='xl'>
                            {/*<!-- Content -->*/}
                            <div
                              class='modal-content'>
                              {/* <!-- Header -->*/}
                              <div class='modal-header ocean blue-gradient darken-3 white-text mt-0'>
                                <h4 class='text-uppercase'>
                                  STORE EXPENSES : {expensesStore}
                                </h4>
                                <button
                                  type='button'
                                  class='close waves-effect waves-light white-text'
                                  data-dismiss='modal'
                                  aria-label='Close'
                                >
                                  <span aria-hidden='true'>&times;</span>
                                </button>
                              </div>
                              {/*<!-- Body -->*/}
                              <div class='modal-body mb-0 '>
                                <table
                                  class='table table-striped table-bordered table-hover table-sm nowrap'
                                  width='100%'
                                >
                                  <thead class='form-header text-uppercase ocean blue-gradient'>
                                    <tr>
                                      <th>S/N</th>
                                      <th>Store Expense</th>
                                      <th>Amount</th>
                                      <th>Time</th>
                                      <th>Date</th>
                                      <th>Status</th>
                                      <th>User</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {DBtableViewExpenses.map(
                                      (valViewExpense, i) => {
                                        return (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                              {valViewExpense.store_expense}
                                            </td>
                                            <td>{valViewExpense.amount}</td>
                                            <td>{valViewExpense.time}</td>
                                            <td>{valViewExpense.date}</td>
                                            <td>
                                              {
                                                valViewExpense.transaction_status
                                              }
                                            </td>
                                            <td>{valViewExpense.user}</td>
                                          </tr>
                                        )
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {/*Modal Body Ends Here*/}
                            </div>
                            </MDBModalDialog>
                          {/*<!-- Content -->*/}
                        </div>
                        {/* <!-- Modal: modalManager .-->*/}

{/*
                        <center>
                          <div id='filterTopCriteria'></div>
                        </center>
                        <table
                          id='table'
                          class='table table-striped table-bordered table-hover table-sm nowrap'
                          width='100%'
                        >
                          <thead class='form-header text-uppercase ocean blue-gradient'>
        
                          
                            <tr>
                              <th>S/N</th>
                              <th>Date</th>
                              <th hidden={secondUserSystemSetupBranchID != ''}>
                                Store
                              </th>
                              <th hidden={businessCategory != 'Pharmacy Shop'}>
                                NHIMA
                              </th>
                              <th hidden={businessCategory != 'Phones Shop'}>
                                Yellow
                              </th>
                              <th hidden={businessCategory != 'Phones Shop'}>
                                Xtenda
                              </th>
                              <th>Total Credit</th>
                              <th>Debit Card</th>
                              <th>Mobile Money</th>
                              <th>Total Wire Transfer</th>
                              <th>Cash</th>
                              <th>System Less Credit</th>
                              <th>System Value</th>
                              <th>Expenses</th>
                              <th>Balance After Expenses</th>
                              <th>Cash Banked</th>
                              <th>Cash Handed In</th>
                              <th> -/+</th>
                              <th>Reason</th>
                              <th>User</th>
                            </tr>
                          </thead>
                          <tbody>
                            {storeExpensesDropDown.map((val, index) => {
                              return (
                                <tr>
                                  
                                <td>{index + 1}</td>
                                  <td>{val.date}</td>
                                  <td
                                    hidden={secondUserSystemSetupBranchID != ''}
                                  >
                                    {val.store}
                                  </td>
                                  <td
                                    hidden={businessCategory != 'Pharmacy Shop'}
                                  >
                                    {val.nhima}
                                  </td>
                                  <td
                                    hidden={businessCategory != 'Phones Shop'}
                                  >
                                    {val.yellow}
                                  </td>
                                  <td
                                    hidden={businessCategory != 'Phones Shop'}
                                  >
                                    {val.xtenda}
                                  </td>
                                  <td>{val.totalCredit}</td>
                                  <td>{val.card}</td>
                                  <td>{val.mobileMoney}</td>
                                  <td>{val.totalWireTransfer}</td>
                                  <td>{val.cash}</td>
                                  <td>{val.systemLessCredit}</td>
                                  <td>{val.systemTotal}</td>
                                  <td
                                    type='button'
                                    onClick={() => {
                                      handleViewExpenses(
                                        val.store_expenses_day_closeID
                                      )
                                    }}
                                    title='Click Here To View List Of Expenses Items'
                                    data-toggle='modal'
                                    data-target='#modalViewExpenses'
                                  >
                                    {val.expenses}
                                  </td>
                                  <td>{val.balanceAfterExpenses}</td>
                                  <td>{val.cashBanked}</td>
                                  <td>{val.cashHandedIn}</td>
                                  <td>{val.inceaseDecrease}</td>
                                  <td>{val.reason}</td>
                                  <td>{val.user}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                          <tfoot class='form-header text-center ocean blue-gradient'>
                            <tr>
                              <th></th>
                              <th>TOTALS</th>
                              <th
                                hidden={secondUserSystemSetupBranchID != ''}
                              ></th>
                              <th
                                hidden={businessCategory != 'Pharmacy Shop'}
                              ></th>
                              <th
                                hidden={businessCategory != 'Phones Shop'}
                              ></th>
                              <th
                                hidden={businessCategory != 'Phones Shop'}
                              ></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                            </tr>
                          </tfoot>
                        </table>

                      */}


                     {/*   <Paper>
          <MUIDataTable
  data={storeExpensesDropDown}
  columns={columns}
  options={options}
/>     
  
  </Paper>
  */}

  <div >
      <Box>
        <MUIDataTable
          data={storeExpensesDropDown}
          columns={columns}
          options={options}
        />
      </Box>
    </div>

                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
            </div>
          </main>
          {/* Main layout */}
        </body>
      </div>
    </>
  )
}

export default StoreExpenses


const Styles = {
  auditTable: {
    maxWidth: "500px",
    "MuiTableHead-root": {
      background: "grey"
    },
    ".MuiTableCell-footer": {
      fontWeight: 700,
      fontSize: "0.875rem"
    },
    ".MuiButtonBase-root": {
      fontWeight: 700,
      fontSize: "0.875rem"
    }
  }
};
