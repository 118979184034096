import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import { NumericFormat  } from 'react-number-format';
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'

import {
  MDBInput,
  MDBBtn,
  MDBCheckbox,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

const RegisterStoreExpenses = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const businessNameRef = useRef()
  const errRef = useRef()

  //const [logo, setLogo] = useState('');
  //const [pacraCertificate, setPacraCertificate] = useState('');

  const [businessStoreName, setBusinessStoreName] = useState('')
  const [storeExpense, setStoreExpense] = useState('')
  const [expensesAmount, setExpensesAmount] = useState('')
  const [storeExpenseTypeOther, setStoreExpenseTypeOther] = useState('')
  const [ip, setIP] = useState('')
  var [paymentType, setPaymentType] = useState('')
  var [businessStoreNameDropDown, setBusinessStoreNameDropDown] = useState([])
  var [storeExpensesDropDown, setStoreExpensesDropDown] = useState([])

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  //const [success, setSuccess ] = useState(false);

  useEffect(() => {
    businessNameRef.current.focus()
  }, [])

  /*useEffect( () => {
	alert("we are in drops");
},
[businessNameDropDown])*/

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  /*useEffect( () => {
        Axios.post("http://localhost:3001/api/getBusinessNameDropDown").then((response) => {
            setBusinessNameDropDown(response.data);
            //console.log(response);
            });
        },
        [])*/
  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)
  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)
  //alert('id = ' + systemSetupIDInitial)

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getBusinessNameDropDown`, {
      businessNameID: systemSetupIDInitial
    })*/
    var formDataViewBusinesses = new FormData(this)
    formDataViewBusinesses.append('apiType', 'getBusinessBranchNameDropDown')
    if (secondUserSystemSetupBranchID === '') {
      formDataViewBusinesses.append('businessNameID', systemSetupIDInitial)
    }
    if (secondUserSystemSetupBranchID != '') {
      formDataViewBusinesses.append(
        'businessBranchNameID',
        secondUserSystemSetupBranchID
      )
    }
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewBusinesses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessStoreNameDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataViewBusinessesStoreExpenses = new FormData(this)
    formDataViewBusinessesStoreExpenses.append(
      'apiType',
      'getStoreExpensesTypes'
    )
    formDataViewBusinessesStoreExpenses.append(
      'businessNameID',
      systemSetupIDInitial
    )
    formDataViewBusinessesStoreExpenses.append(
      'businessBranchNameID',
      businessStoreName
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataViewBusinessesStoreExpenses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setStoreExpensesDropDown(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [businessStoreName])

  const handleSubmit = async e => {
    //alert("businessName = "+businessName);
    const currentDate = new Date()
    const dateToday = `${currentDate.getDate()}/${
      currentDate.getMonth() + 1
    }/${currentDate.getFullYear()}`
    const timeNow =
      currentDate.getHours() +
      ':' +
      currentDate.getMinutes() +
      ':' +
      currentDate.getSeconds()

      setHandleSubmitBtn('Loading');

    const firstUserID = localStorage.getItem('userID')
    const userIDInitial = JSON.parse(firstUserID)
    var userID = userIDInitial
    var formDataRegisterProducts = new FormData(this)
    formDataRegisterProducts.append('apiType', 'postStoreExpenses')
    formDataRegisterProducts.append('system_setupID', systemSetupIDInitial)
    formDataRegisterProducts.append('system_setup_branchID', businessStoreName)
    formDataRegisterProducts.append('store_expenseID', storeExpense)
    formDataRegisterProducts.append('store_expenses_amount', expensesAmount)
    formDataRegisterProducts.append('payment_type', paymentType)
    formDataRegisterProducts.append('store_user', secondUserSystemSetupBranchID)
    formDataRegisterProducts.append(
      'store_expense_type_other',
      storeExpenseTypeOther
    )
    formDataRegisterProducts.append('store_expenses_enteredBy', userID)
    formDataRegisterProducts.append('system_setup_IP', ip)
    /*
    $store_expenseID = $_POST['store_expenseID'];
    $store_expense_type_other = $_POST['store_expense_type_other'];
    $store_expenses_amount = $_POST['store_expenses_amount'];
    $payment_type = $_POST['payment_type'];
    $store_expenses_enteredBy = $_POST['store_expenses_enteredBy'];
    */
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formDataRegisterProducts,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        setHandleSubmitBtn('')
        if (
          res.data === 'Successfully Uploaded' ||
          res.data ===
            'Successfully Uploaded, But Duplicate Entries where not Registered'
        ) {
          setBusinessStoreName('')
          setStoreExpense('')
          setExpensesAmount('')
          setPaymentType('')
          setStoreExpenseTypeOther('')
          //setBusinessStoreNameDropDown('')
        }
        alert(res.data)
      })
      .catch(err =>{ alert('ERROR : Kindly Contact Your Adminitrator')
      setHandleSubmitBtn('') 
    })
  }

  // date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(); //output= 2020-5-24
  //var time = new Date().toTimeString();

  //const time = `${currentDate.getTime()}`;
  //var today = new Date(),
  //alert("Time Is = "+timeNow+" Today's Date = "+date);

  ;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()

  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)

  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - Register Store Expenses -{' '}
        {systemSetupBranchIDStoreName}{' '}
      </title>
      <>
        <Headers />
      </>

      {/*<!-- Preloader
      <div id='overlayer'></div>
      <div class='loader'>
        <div class='spinner-border' role='status'>
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div> -->*/}

      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row mt-5'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body'>
                  <div class='form-header white-text ocean blue-gradient'>
                    {' '}
                    REGISTER STORE EXPENSES HERE{' '}
                  </div>
                  {/*Start Of Row 1*/}
                  <div class='row'>
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Business Store Name <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <select
                          class='custom-select d-block w-100'
                          id='businessName'
                          onChange={e => setBusinessStoreName(e.target.value)}
                          autoComplete='off'
                          required
                          ref={businessNameRef}
                          value={businessStoreName}
                        >
                          <option value=''>..Choose Business Store ..</option>
                          {businessStoreNameDropDown.map(val => {
                            return (
                              <option value={val.system_setup_branchID}>
                                {val.branch_name}{' '}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>

                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Store Expenses <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <select
                          class='custom-select d-block w-100'
                          onChange={e => setStoreExpense(e.target.value)}
                          autoComplete='off'
                          value={storeExpense}
                          disabled={businessStoreName === ''}
                        >
                          <option value=''>..Choose Store Expense ..</option>
                          {storeExpensesDropDown.map(vals => {
                            return (
                              <option value={vals.store_expense_typesID}>
                                {vals.store_expense_type}{' '}
                              </option>
                            )
                          })}
                          <option value='Other'>Other (Specify) </option>
                        </select>
                      </div>
                    </div>

                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Amount <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          onChange={e => setExpensesAmount(e.target.value)}
                          autoComplete='off'
                          value={expensesAmount}
                          disabled={
                            businessStoreName === '' || storeExpense === ''
                          }
                        />
                      </div>
                    </div>

                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Payment Status <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <select
                          class='custom-select d-block w-100'
                          onChange={e => setPaymentType(e.target.value)}
                          autoComplete='off'
                          value={paymentType}
                          disabled={
                            businessStoreName === '' ||
                            storeExpense === '' ||
                            expensesAmount === ''
                          }
                        >
                          <option value=''>..Choose ..</option>
                          <option>Paid</option>
                          <option>Credit </option>
                        </select>
                      </div>
                    </div>
                  </div>{' '}
                  <div class='row' hidden={storeExpense != 'Other'}>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Other (Please Specify) <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          onChange={e =>
                            setStoreExpenseTypeOther(e.target.value)
                          }
                          autoComplete='off'
                          value={storeExpenseTypeOther}
                          disabled={
                            businessStoreName === '' ||
                            storeExpense === '' ||
                            expensesAmount === '' ||
                            paymentType === ''
                          }
                        />
                      </div>
                    </div>
                  </div>{' '}
                  {/*End Of Row 1*/}
                  <p>
                    <span class='red-text'>* Required</span>{' '}
                    <span>- Do not leave Blank</span>
                  </p>
                  <hr class='mb-1' />
                  <button
                    onClick={handleSubmit}
                    class='btn btn-primary'
                    disabled={
                      handleSubmitBtn === 'Loading' ||
                      businessStoreName === '' ||
                      expensesAmount === '' ||
                      paymentType === '' ||
                      (storeExpense === 'Other' && storeExpenseTypeOther === '')
                    }
                  >
            <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
                    Submit
                  </button>
                  <br /> <br /> <br />

                
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default RegisterStoreExpenses
