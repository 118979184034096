import { useRef, useState, useEffect, useContext } from 'react'
//import Headers from './Headers'
import { useNavigate, redirect } from 'react-router-dom'
import Axios from '.././api/axios'
import { MDBContainer } from 'mdbreact'
import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter
} from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

export const DailyTotalProfits = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [totalSalesReport, setTotalSalesReport] = useState([])
  const [daySalesReport, setDaySalesReport] = useState([])
  const [totalInventoryReport, setTotalInventoryReport] = useState('')
  const [totalInventoryReportInitial, setTotalInventoryReportInitial] =
    useState('')
  const [totalProfitReport, setTotalProfitReport] = useState('')
  const [totalProfitReportDay, setTotalProfitReportDay] = useState('')

  const [costTotalBusinessValue, setCostTotalBusinessValue] = useState('')
  const [sellingPriceTotalBusinessValue, setSellingPriceTotalBusinessValue] =
    useState('')
  const [dailySalesTrends, setDailySalesTrends] = useState('')
  let [dailyTotalProfitsLabels, setDailyTotalProfitsLabels] = useState([])
  let [dailyTotalProfitsData, setDailyTotalProfitsData] = useState([])

  //const [dailyTotalProfitsLabelsInitial, setDailyTotalProfitsLabelsInitial] =
  //useState([])
  //const [dailyTotalProfitsDataInitial, setDailyTotalProfitsDataInitial] =
  //useState([])

  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const monthToday = `${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const firstUserSystemSetupID = localStorage.getItem('systemSetupID')
  let secondUserSystemSetupID = JSON.parse(firstUserSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  var businessOwnerSelectedBranchID = localStorage.getItem(
    'businessOwnerSelectedBranchID'
  )
  businessOwnerSelectedBranchID = JSON.parse(businessOwnerSelectedBranchID)
  if (businessOwnerSelectedBranchID != '') {
    secondUserSystemSetupBranchID = businessOwnerSelectedBranchID
    secondUserPosition = 'Manager'
  }
  /*let [count, setCount] = useState(0)
  let counts = 0
  let countz = 0
  let delay = 3600
  setInterval(() => {
    setCount(count + 1)
  }, delay)

  if (
    JSON.stringify(dailyTotalProfitsData) !=
    JSON.stringify(dailyTotalProfitsDataInitial)
  ) {
    dailyTotalProfitsData = dailyTotalProfitsDataInitial
  } else {
    counts++
  }
  if (
    JSON.stringify(dailyTotalProfitsLabels) !=
    JSON.stringify(dailyTotalProfitsLabelsInitial)
  ) {
    dailyTotalProfitsLabels = dailyTotalProfitsLabelsInitial
  } else {
    counts++
  }
*/
  //countz = counts
  useEffect(() => {
    var formDataThirdRowData = new FormData(this)
    formDataThirdRowData.append('systemSetupID', secondUserSystemSetupID)
    formDataThirdRowData.append('reportType', 'thirdRowData')
    formDataThirdRowData.append('dateToday', dateToday)
    formDataThirdRowData.append('monthToday', monthToday)
    formDataThirdRowData.append('userPosition', secondUserPosition)
    formDataThirdRowData.append(
      'systemSetupBranchID',
      secondUserSystemSetupBranchID
    )
    formDataThirdRowData.append('userID', secondUserID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataThirdRowData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setDailyTotalProfitsData(response.data)
      //alert(response.data[0])
      //alert(response.data)
    })
  }, [secondUserSystemSetupBranchID])

  /*useEffect(() => {
    var formDataThirdRowDataInitial = new FormData(this)
    formDataThirdRowDataInitial.append('systemSetupID', secondUserSystemSetupID)
    formDataThirdRowDataInitial.append('reportType', 'thirdRowData')
    formDataThirdRowDataInitial.append('dateToday', dateToday)
    formDataThirdRowDataInitial.append('monthToday', monthToday)
    formDataThirdRowDataInitial.append('userPosition', secondUserPosition)
    formDataThirdRowDataInitial.append(
      'systemSetupBranchID',
      secondUserSystemSetupBranchID
    )
    formDataThirdRowDataInitial.append('userID', secondUserID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataThirdRowDataInitial,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setDailyTotalProfitsDataInitial(response.data)
      //alert(response.data[0])
    })
  }, [count])*/

  useEffect(() => {
    var formDataThirdRowLabels = new FormData(this)
    formDataThirdRowLabels.append('systemSetupID', secondUserSystemSetupID)
    formDataThirdRowLabels.append('reportType', 'thirdRowLabels')
    formDataThirdRowLabels.append('dateToday', dateToday)
    formDataThirdRowLabels.append('monthToday', monthToday)
    formDataThirdRowLabels.append(
      'systemSetupBranchID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataThirdRowLabels,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setDailyTotalProfitsLabels(response.data)
      //alert(response.data[0])
    })
  }, [secondUserSystemSetupBranchID])

  /*useEffect(() => {
    var formDataThirdRowLabelsInitial = new FormData(this)
    formDataThirdRowLabelsInitial.append(
      'systemSetupID',
      secondUserSystemSetupID
    )
    formDataThirdRowLabelsInitial.append('reportType', 'thirdRowLabels')
    formDataThirdRowLabelsInitial.append('dateToday', dateToday)
    formDataThirdRowLabelsInitial.append('monthToday', monthToday)
    formDataThirdRowLabelsInitial.append(
      'systemSetupBranchID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataThirdRowLabelsInitial,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setDailyTotalProfitsLabelsInitial(response.data)
      //alert(response.data[0])
    })
  }, [count])*/

  const data = {
    labels: dailyTotalProfitsLabels,
    datasets: [
      {
        label: 'DAY TOTAL PROFIT',
        data: dailyTotalProfitsData,
        fill: true,
        backgroundColor: 'rgba(6, 156,51, .3)',
        borderColor: '#02b844'
      }
    ]
  }

  return (
    <>
      <Line data={data} />
    </>
  )
}

export default DailyTotalProfits
